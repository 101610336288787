function HomeRedirectState($urlRouterProvider) {
  $urlRouterProvider.when(/^\/delete-account\/?$/i, ['$window', 'AnalyticsService', 'UrlLanguage', function($window, AnalyticsService, UrlLanguage) {
    AnalyticsService.page('/delete-account');

    const deleteAccountLocation = {
      en: 'https://boomerang.zendesk.com/hc/en-us/requests/new',
      es: 'https://boomerang.zendesk.com/hc/es/requests/new'
    };

    const redirectUserTo = deleteAccountLocation[UrlLanguage.activeLanguage] || deleteAccountLocation.en;

    $window.location.replace(redirectUserTo);
  }]);

}

HomeRedirectState.$inject = ['$urlRouterProvider'];

export default HomeRedirectState;
