/**
 *  @ngdoc controller
 *  @name Seed.Controllers:PaymentForm
 *  @module Seed
 *  @requires $scope
 *  @requires platformjs.paymentmethods
 *  @requires BraintreeService
 *  @description
 *    Responsible for creating a new payment method.
 */
class PaymentFormController {
  static get $inject() {
    return ['$scope', 'platformjs.paymentmethods', 'BraintreeService'];
  }
  constructor($scope, PaymentMethodsService, BraintreeService) {
    this.$scope = $scope;
    this.PaymentMethodsService = PaymentMethodsService;
    this.BraintreeService = BraintreeService;

    /**
    *  @ngdoc property
    *  @name fields
    *  @propertyOf Seed.Controllers:PaymentForm
    *  @returns {object} Object containing information about each field.
    *
    *  @description
    *  An object used to store the state of each field in the payment form.
    *
    *  @example
    *  ```js
    *    {
    *      number: {
    *        isEmpty: true,
    *        isFocused: false,
    *        isPotentiallyValid: true,
    *        isValid: false
    *      },
    *      expirationMonth: {
    *        isEmpty: true,
    *        isFocused: false,
    *        isPotentiallyValid: true,
    *        isValid: false
    *      },
    *      expirationYear: {
    *        isEmpty: true,
    *        isFocused: false,
    *        isPotentiallyValid: true,
    *        isValid: false
    *      },
    *      cvv: {
    *        isEmpty: true,
    *        isFocused: false,
    *        isPotentiallyValid: true,
    *        isValid: false
    *      }
    *    }
    *  ```
    **/
    this.fields = {
      number: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      },
      expirationMonth: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      },
      expirationYear: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      },
      cvv: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      }
    };

    /**
    *  @ngdoc property
    *  @name settings
    *  @propertyOf Seed.Controllers:PaymentForm
    *  @returns {object} Settings object.
    *  @description
    *   Settings for Braintree setup.
    *
    *  @example
    *  ```js
    *    {
    *      id: 'payment-form',
    *      hostedFields: {
    *        number: {
    *          selector: '[data-field="card-number"]'
    *        },
    *        expirationMonth: {
    *          selector: '[data-field="card-expirationMonth"]'
    *        },
    *        expirationYear: {
    *          selector: '[data-field="card-expirationYear"]'
    *        },
    *        cvv: {
    *          selector: '[data-field="card-cvv"]'
    *        },
    *        styles: {
    *          input: {
    *            'font-size': '14px'
    *          }
    *        }
    *      }
    *    }
    *  ```
    **/
    this.settings = {
      id: 'payment-form',
      hostedFields: {
        number: {
          selector: '[data-field="card-number"]'
        },
        expirationMonth: {
          selector: '[data-field="card-expirationMonth"]'
        },
        expirationYear: {
          selector: '[data-field="card-expirationYear"]'
        },
        cvv: {
          selector: '[data-field="card-cvv"]'
        },
        styles: {
          input: {
            'font-size': '14px'
          }
        }
      }
    };

    /**
    *  @ngdoc property
    *  @name submitting
    *  @propertyOf Seed.Controllers:PaymentForm
    *  @returns {boolean} Form is currently submitting.
    *  @description
    *   Reflects current submission status for use in updating template UI.
    **/
    this.submitting = false;

    /**
    *  @ngdoc property
    *  @name paymentMethod
    *  @propertyOf Seed.Controllers:PaymentForm
    *  @returns {object} paymentMethod - information about the received payment method
    *  @description
    *   To store payment method detail information from payment gateways
    *  @see {@link https://developers.braintreepayments.com/reference/client-reference/javascript/v2/configuration#onpaymentmethodreceived-details-object | onPaymentMethodReceived}
    **/
    this.paymentMethod = {};

    this.onError = this.onError || angular.noop;
    this.onFailure = this.onFailure || angular.noop;
    this.onReady = this.onReady || angular.noop;
    this.onSubmit = this.onSubmit || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
     *  @ngdoc method
     *  @name Seed.Controllers:PaymentForm#$onInit
     *  @methodOf Seed.Controllers:PaymentForm
     *  @description
     *   Requests payment config.
    **/
  $onInit() {

    this.PaymentMethodsService.getConfig()
      .then(config => this.createForm(config), errors => this.showFailure(errors));

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentForm#createForm
   *  @methodOf Seed.Controllers:PaymentForm
   *  @param {object} config Config object.
   *  @description
   *    Attempts to initialize braintree form with passed config object.
  **/
  createForm(config) {
    angular.merge({}, this.settings.styles, this.styles);

    this.settings.onReady = () => this.onBraintreeReady();
    this.settings.onError = error => this.onBraintreeError(error);
    this.settings.onPaymentMethodReceived = paymentMethod => this.onBraintreePaymentMethodReceived(paymentMethod);
    this.settings.hostedFields.onFieldEvent = event => this.onBraintreeFieldEvent(event);

    try {
      this.BraintreeService.setup(config.clientToken, 'custom', this.settings);
    } catch (error) {
      // TO DO: error factory for braintree errors
      this.showFailure(error);
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentForm#showFailure
   *  @methodOf Seed.Controllers:PaymentForm
   *  @param {array} errors Error list.
   *  @description
   *    Triggers onFailure and passes errors.
  **/
  showFailure(errors) {

    this.onFailure({
      errors: errors
    });

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#createPaymentMethod
  *  @methodOf Seed.Controllers:PaymentForm
  *  @param {string} nonce Payment nonce
  *  @description
  *   Attempts to create a new payment method with passed nonce.
  **/
  createPaymentMethod(nonce) {

    this.submitting = true;

    let params = {
      merchantNonce: nonce
    };

    // NOTE:
    // If we also need to pass a country code for 'CreditCard' payment type,
    // we will need to collect country code from users. The decision has not
    // been made yet.

    if (this.paymentMethod.type === 'PayPalAccount' && this.paymentMethod.details.billingAddress) {
      params.postalCode = this.paymentMethod.details.billingAddress.postalCode;
      params.countryCode = this.paymentMethod.details.billingAddress.countryCodeAlpha2;
    }

    this.PaymentMethodsService.create(params)
      .then(response => this.createPaymentMethodSuccess(response))
      .catch(errors => this.createPaymentMethodFailure(errors));

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#createPaymentMethodFailure
  *  @methodOf Seed.Controllers:PaymentForm
  *  @param {array} errors Error list
  *  @description
  *    Calls {@link Seed.Controllers:PaymentForm#methods_onError onError} method and passes error array.
  **/
  createPaymentMethodFailure(errors) {

    this.onError({
      errors: errors
    });
    this.showSubmitComplete();

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#createPaymentMethodSuccess
  *  @methodOf Seed.Controllers:PaymentForm
  *  @param {object} response Success response
  *  @description
  *    Calls {@link Seed.Controllers:PaymentForm#methods_onSuccess onSuccess} method and passes `success.paymentMethodGuid`
  **/
  createPaymentMethodSuccess(response) {

    var onSuccessResponse = this.onSuccess({
      paymentMethod: response.paymentMethod
    });

    // in the case that the containing controller executes a promise with
    // the passed paymentMethod, wait for that promise to be resolved/rejected
    // before ending the submission process.

    if (onSuccessResponse && onSuccessResponse.finally) {
      onSuccessResponse.finally(() => this.showSubmitComplete());
    } else {
      this.showSubmitComplete();
    }

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#fieldClasses
  *  @methodOf Seed.Controllers:PaymentForm
  *  @param {string} key Field key
  *  @description
  *    Returns classes according to the property of {@link Seed.Controllers:PaymentForm#properties_fields fields}
  *    that matches the passed key.
  **/
  fieldClasses(key) {

    return {
      'is-focused': this.fields[key].isFocused,
      'is-not-empty': !this.fields[key].isEmpty,
      'is-invalid': !this.fields[key].isPotentiallyValid
    };

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#isBraintreeFormValid
  *  @methodOf Seed.Controllers:PaymentForm
  *  @returns {boolean} Valid
  *  @description
  *    Returns `true` if every object in {@link Seed.Controllers:PaymentForm#properties_fields fields} has an `isValid` property set to `true`.
  **/
  isBraintreeFormValid() {

    for (var k in this.fields) {
      if (!this.fields[k].isValid) {
        return false;
      }
    }

    return true;

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#onBraintreeError
  *  @methodOf Seed.Controllers:PaymentForm
  *  @param {object} error Error object
  *  @description
  *    Called on Braintree Hosted Fields error.
  *
  *  Calls {@link Seed.Controllers:PaymentForm#methods_onError onError} method and passes the error object.
  **/
  onBraintreeError(error) {

    // TO DO: error factory for braintree errors

    this.$scope.$apply(this.onError({
      errors: error
    }));

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#onBraintreeFieldEvent
  *  @methodOf Seed.Controllers:PaymentForm
  *  @param {object} event Field event object
  *  @description
  *   Called on Braintree Hosted Fields event.
  *
  *   Events are used to keep UI in sync with current Braintree field status.
  **/
  onBraintreeFieldEvent(event) {

    var targetField = this.fields[event.target.fieldKey];

    targetField.isEmpty = event.isEmpty;
    targetField.isFocused = event.isFocused;
    targetField.isPotentiallyValid = event.isPotentiallyValid;
    targetField.isValid = event.isValid;

    this.$scope.$apply();

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#onBraintreeReady
  *  @methodOf Seed.Controllers:PaymentForm
  *  @description
  *   Called on successful initialization of Braintree Hosted Fields.
  *
  *   Calls {@link Seed.Controllers:PaymentForm#methods_onReady onReady} method.
  **/
  onBraintreeReady() {

    this.$scope.$apply(this.onReady());

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#onBraintreePaymentMethodReceived
  *  @methodOf Seed.Controllers:PaymentForm
  *  @param {object} success Success object
  *  @description
  *   Called on Braintree Hosted Fields success.
  *
  *  Calls {@link Seed.Controllers:PaymentForm#methods_createPaymentMethod createPaymentMethod} method and passes the generated payment nonce.
  **/
  onBraintreePaymentMethodReceived(paymentMethod) {

    // Store the paymentMethod from Braintree
    this.paymentMethod = paymentMethod;

    this.$scope.$apply(this.createPaymentMethod(paymentMethod.nonce));

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#showSubmitComplete
  *  @methodOf Seed.Controllers:PaymentForm
  *  @description
  *   Sets {@link Seed.Controllers:PaymentForm#properties_submitting submitting} to `false`.
  *
  **/
  showSubmitComplete() {

    this.submitting = false;

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:PaymentForm#onBraintreeReady
  *  @methodOf Seed.Controllers:PaymentForm
  *  @description
  *   Sets {@link Seed.Controllers:PaymentForm#properties_submitting submitting} to `true`.
  **/
  submit() {

    this.submitting = true;

  }
}

export default PaymentFormController;
