import LogoutButtonController from './boom-logout-button-controller';

const LogoutButtonComponent = {
  controller: LogoutButtonController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-logout-button/logout-button.html',
  transclude: true,
  bindings: {
    redirectState: '@'
  }
};

export default LogoutButtonComponent;
