const BoomFormHelpComponent = {
  bindings: {
    text: '<',
    image: '<'
  },
  controllerAs: 'vm',
  templateUrl: 'components/boom-form-help/form-help.html'
};

export default BoomFormHelpComponent;
