/**
 *  @ngdoc service
 *  @name Seed.Service:UserAgentService
 *  @description
 *    Service for getting User Agent Data
 */
class UserAgentService {
  static get $inject() {
    return ['$window', 'detectjs'];
  }

  constructor($window, detectjs) {

    this.$window = $window;
    this.detectjs = detectjs;
  }

  getUserAgent() {
    return this.detectjs.parse(this.$window.navigator.userAgent);
  }

  getModel() {
    return {
      model: this.getUserAgent().browser.name,
      os: this.getUserAgent().os.name
    };
  }

  isiOS() {
    if (this.getUserAgent().os.name.indexOf('iOS') > -1) {
      return true;
    }
    return false;
  }

  isAndroid() {
    if (this.getUserAgent().os.name.indexOf('Android') > -1) {
      return true;
    }
    return false;
  }

  isMobile() {
    if (this.getUserAgent().device.type === 'Mobile' ||
      this.getUserAgent().device.type === 'Tablet' ||
      this.isAndroid() ||
      this.isiOS()) {
      return true;
    }
    return false;
  }

}

export default UserAgentService;
