function RegisterState($stateProvider) {

  $stateProvider.state('register', {
    parent: 'language',
    abstract: true,
    url: '?{pid:int}&code',
    template: '<div ui-view autoscroll="false"></div>'
  });

}

RegisterState.$inject = ['$stateProvider'];

export default RegisterState;
