/**
*  @ngdoc controller
*  @name Seed.Controllers:SignupForm
*  @module Seed
*  @requires platformjs.user.login
*  @requires platformjs.user.preferences.email
*  @description
*    Responsible for signing up a user.
*/
class SignupFormController {
  static get $inject() {
    return ['platformjs.user.login', 'platformjs.user.preferences.email'];
  }

  constructor(LoginService, PreferencesEmailService) {
    this.LoginService = LoginService;
    this.PreferencesEmailService = PreferencesEmailService;

    /**
     *  @ngdoc property
     *  @name email
     *  @propertyOf Seed.Controllers:SignupForm
     *  @returns {string} Email.
     *  @description
     *    User email input value.
     */
    this.email = '';

    /**
     *  @ngdoc property
     *  @name password
     *  @propertyOf Seed.Controllers:SignupForm
     *  @returns {string} Password.
     *  @description
     *    User password input value.
     */
    this.password = '';

    /**
     *  @ngdoc property
     *  @name submitting
     *  @propertyOf Seed.Controllers:SignupForm
     *  @returns {boolean} Status.
     *  @description
     *    Current submission status.
     */
    this.submitting = false;

    /**
     *  @ngdoc property
     *  @name username
     *  @propertyOf Seed.Controllers:SignupForm
     *  @returns {string} Username.
     *  @description
     *    User username input value.
     */
    this.username = '';

    /**
     *  @ngdoc property
     *  @name settings
     *  @propertyOf Seed.Controllers:SignupForm
     *  @returns {object} Settings
     *  @description
     *    Settings state for notification
     */
    this.settings = {};

    /**
     *  @ngdoc property
     *  @name settings marketing
     *  @propertyOf Seed.Controllers:SignupForm
     *  @returns {boolean} Status.
     *  @description
     *    Current email preference status.
     */
    this.settings.marketing = true;

    this.onError = this.onError || angular.noop;
    this.onSubmit = this.onSubmit || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SignupForm#showSignupFailure
   *  @methodOf Seed.Controllers:SignupForm
   *  @param {array} errors Errors
   *  @description
   *    Called on failure of signup request.
   */
  showSignupFailure(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SignupForm#showSignupFinally
   *  @methodOf Seed.Controllers:SignupForm
   *  @description
   *    Called at the end of signup request.
   */
  showSignupFinally() {

    this.submitting = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SignupForm#showSignupSuccess
   *  @methodOf Seed.Controllers:SignupForm
   *  @param {object} user User
   *  @description
   *    Called on success of signup request.
   */
  showSignupSuccess(user) {

    this.onSuccess({
      user: user
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SignupForm#updateSettings
   *  @methodOf Seed.Controllers:SignupForm
   *  @param {array} value Value
   *  @description
   *    Updates notification settings
   */
  updateSettings() {

    var param = {
      marketing: this.settings.marketing
    };

    return this.PreferencesEmailService.save(param);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SignupForm#submit
   *  @methodOf Seed.Controllers:SignupForm
   *  @description
   *    Submits form data to signup request.
   */
  submit() {

    this.submitting = true;
    this.LoginService.create(this.username, this.email, this.password)

      .then(() => this.updateSettings())
      .then(user => this.showSignupSuccess(user))
      .catch(errors => this.showSignupFailure(errors))
      .finally(() => this.showSignupFinally());

  }
}

export default SignupFormController;