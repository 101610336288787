import LanguageSelectorController from './language-selector-controller';

const LanguageSelectorComponent = {
  controller: LanguageSelectorController,
  controllerAs: 'vm',
  bindings: {
    onChangeLanguage: '&',
    selectorIsOpen: '<'
  },
  templateUrl: 'components/language-selector/language-selector.html'
};

export default LanguageSelectorComponent;
