import ProfileViewController from 'views/profile/profile-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomProfileViewController
 *  @module Boom
 *  @requires platformjs.user.profile
 *  @requires ProgressBarService
 *  @requires SubscriptionsService
 *  @requires PaymentMethodsService
 *  @description
 *    Responsible for managing the profile information for a user. Extension from seed.
 */
class BoomProfileViewController extends ProfileViewController {
  static get $inject() {
    return [
      '$state',
      'platformjs.user.profile',
      'ProgressBarService',
      'platformjs.subscriptions',
      'platformjs.subscriptionsV2',
      'platformjs.paymentmethodsV2',
    ];
  }

  /* istanbul ignore next */
  constructor(
    $state,
    UserProfileService,
    ProgressBarService,
    SubscriptionsService,
    SubscriptionsServiceV2,
    PaymentMethodsService,
  ) {

    super(UserProfileService, ProgressBarService, PaymentMethodsService);

    this.$state = $state;
    this.SubscriptionsService = SubscriptionsService;
    this.SubscriptionsServiceV2 = SubscriptionsServiceV2;
    this.PaymentMethodsService = PaymentMethodsService;
    this.UserProfileService = UserProfileService;

    this.SubscriptionsServiceV2.list()
      .then(this.showActiveSubscription.bind(this))
      .catch(this.showProfileFailure.bind(this));

    /**
     *  @ngdoc property
     *  @name loading
     *  @propertyOf Boom.Controllers:BoomProfileViewController
     *  @returns {boolean} Current loading state
     *  @description
     *    Current loading state
     */
    this.loading = true;

    /**
     *  @ngdoc property
     *  @name allowCancel
     *  @propertyOf Boom.Controllers:BoomProfileViewController
     *  @returns {boolean} to hide or not to hide cancel button
     *  @description
     *    To hide or not to hide cancel button, that is the question
     */
    this.allowCancel = false;

    /**
     *  @ngdoc property
     *  @name loginErrors
     *  @propertyOf Boom.Controllers:BoomProfileViewController
     *  @returns {array} errors from login
     *  @description
     *    Errors from attempting to login
     */
    this.loginErrors = [];

    this.thirdPartySubscriptionProviders = [
      'Roku',
      'Amazon',
      'Apple',
      'Google',
      'MVPD'
    ];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#$onInit
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @description
   *    Makes initial requests
   */
  $onInit() {
    this.ProgressBarService.start();

    this.loadProfile()
      .then(profile => this.showProfile(profile))
      .catch(errors => this.showProfileFailure(errors))
      .finally(() => {
        this.canCancelV2();

        this.ProgressBarService.complete();
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#getPaymentInfo
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @description
   *    Calls PaymentMethodsService to get payment method card type and last four digits
   */
  getPaymentInfo() {
    this.PaymentMethodsService.list().then((result) => {
      if (result.length) {
        let currentPayment;
        if (this.$state.params.newPaymentGuid) {
          currentPayment = result.find(paymentMethod => paymentMethod.paymentMethodGuid === this.$state.params.newPaymentGuid);
        }
        if (!currentPayment) {
          currentPayment = result.find(paymentMethod => paymentMethod.paymentMethodGuid === this.activeSubscription.paymentMethodGuid);
        }

        const lastFour = currentPayment.ccLast4 || currentPayment.description.slice(-4);
        const type = currentPayment.ccType || currentPayment.description.split(' ')[0];
        const card = (
        type === "American" ||
        type === "Visa" ||
        type === "PayPal" ||
        type === "MasterCard"
          ) ? type : "SmallCompanies";
        this.paymentInfo = {
          card,
          lastFour
        };
      }
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#canCancelV2
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @description
   *    Calls api v2 to see if user can cancel subscription
   */
  canCancelV2() {
    const canCancelV2Success = (response) => {
      const activeSub = response && response.find(sub => sub.active);

      if (activeSub) {
        const premiumStateString = activeSub.premium && activeSub.premium.state.toLowerCase();
        // Test state string for potential states
        // https://github.com/wbdl/www/blob/master/dramafever/premium/models.py#L261-L286
        this.hasBeenCanceled = premiumStateString.includes('canceled');

        const thirdPartyCancellation = this.thirdPartySubscriptionProviders.includes(activeSub.merchantType);

        this.allowCancel = !thirdPartyCancellation && !this.hasBeenCanceled;
      }
    };

    this.SubscriptionsServiceV2.list()
      .then(canCancelV2Success)
      .catch(errors => this.showProfileFailure(errors))
      .finally(() => this.loading = false);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#showProfileFailure
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @param {array} errors Error list
   *  @description
   *    Called on the failure of the initial request for a user's profile and subscription service
   */
  showProfileFailure(errors) {
    this.errors.push(errors);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#showLoginFailure
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @param {array} errors An array of errors.
   *  @description
   *    Sets {@link Boom.Controllers:BoomProfileViewController#properties_errors errors} to the passed loginErrors parameter.
   */
  showLoginFailure(errors) {

    this.loginErrors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#showLoginSuccess
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @description
   *    Redirects to the value of `$state.params.destinationState` with the value
   *    of `$state.params.destinationParams`.
   *
   */
  showLoginSuccess() {

    this.$state.go(this.$state.params.destinationState, this.$state.params.destinationParams, {
      reload: true
    });

  }

  premiumPrice() {
    return this.profile.subscriptionInfo.premiumPrice || this.profile.subscriptionInfo.premiumNextPrice || this.profile.subscriptionInfo.productPrice;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#onChange
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @description
   *    Should be called by the component text field change event, reset the scope errors and success messages.
   *
   */
  onChange() {
    this.errors = [];
    this.success = undefined;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProfileViewController#showActiveSubscription
   *  @methodOf Boom.Controllers:BoomProfileViewController
   *  @param {array} allSubscriptions All of the users subscriptions
   *  @description
   *    Called with an array of all the user's subscriptions
   *    Searches for the active subscription and stores it on the instance
   *
   */
  showActiveSubscription(allSubscriptions) {
    allSubscriptions.forEach(subscription => {
      if (subscription.active) {
        this.activeSubscription = subscription;
        this.getPaymentInfo();
      }
    });
  }

}

export default BoomProfileViewController;
