import DeviceOfflineViewController from 'views/device-offline/device-offline-view-controller';

function DeviceOfflineState($stateProvider) {
  $stateProvider.state('offline', {
    url: '/offline',
    parent: 'language',
    templateUrl: 'views/device-offline/device-offline-view.html',
    userRequired: false,
    ignoreMobileWall: true,
    controller: DeviceOfflineViewController,
    controllerAs: 'vm',
    params: {
      onlineRedirectStateName: null
    }
  });
}

DeviceOfflineState.$inject = ['$stateProvider'];

export default DeviceOfflineState;
