import ProfileFormController from 'components/profile-form/profile-form-controller';

const AccountProfileComponent = {
  bindings: {
    profile: '<'
  },
  controller: ProfileFormController,
  controllerAs: 'vm',
  templateUrl: 'components/account-profile/account-profile.html'
};

export default AccountProfileComponent;
