import TosPPBannerController from './tos-pp-banner-controller';

export default {
  controller: TosPPBannerController,
  bindings: {
    onAgreedTo: '=?'
  },
  controllerAs: 'vm',
  templateUrl: 'components/tos-pp-banner/tos-pp-banner.html'
}
