import MainFooterController from './main-footer-controller';

const MainFooterComponent = {
  controller: MainFooterController,
  controllerAs: 'vm',
  bindings: {},
  templateUrl: 'components/main-footer/main-footer.html'
};

export default MainFooterComponent;
