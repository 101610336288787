import PaymentFormController from '../boom-payment-form/boom-payment-form-controller.module';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomUpdatePaymentFormController
*  @module Boom
*  @requires $scope
*  @requires platformjs.user.profile
*  @requires platformjs.premiumProfile
*  @requires moment
*  @requires platformjs.subscriptionsV2
*  @requires platformjs.error
*  @requires $state
*  @requires $cookies
*  @description
*    Responsible for updating a user's payment method
*/
class BoomUpdatePaymentFormController extends PaymentFormController {
  static get $inject() {
    return [
      '$scope',
      'platformjs.user.profile',
      'platformjs.premiumProfile',
      'moment',
      'platformjs.subscriptionsV2',
      'platformjs.error',
      '$state',
      '$cookies',
      '$translate'
    ];
  }

  /* istanbul ignore next */
  constructor(
    $scope,
    UserProfileService,
    PremiumProfileService,
    moment,
    SubscriptionServiceV2,
    Errors,
    $state,
    $cookies,
    $translate
  ) {
    super($scope, UserProfileService, $cookies, PremiumProfileService, moment, $translate);

    this.SubscriptionServiceV2 = SubscriptionServiceV2;
    this.Errors = Errors;
    this.$state = $state;
  }

  createPaymentMethodSuccess(response) {
    const params = {
      guid: this.userPremiumProfile.subscriptionGuid,
      paymentMethodGuid: response.guid || response.paymentMethodGuid
    };

    this.SubscriptionServiceV2.changePaymentMethod(params)
      .then(response => this.changePaymentSuccess(response))
      .catch(errors => {
        this.errors = this.processErrors(errors);
      })
      .finally(() => this.showSubmitComplete());
  }

  processErrors(responseErrors) {
    const errors = new this.Errors();

    if (responseErrors && responseErrors.length) {
      responseErrors.forEach(error => {
        switch (error.raw && error.raw.code) {
          case 'INVALID_PAYMENTMETHOD':
            errors.log(3044);
            break;
          case 'MERCHANT_PROCESSING_FAILED':
            errors.log(3055);
            break;
        }
      });
    }

    if (!errors.hasErrors()) {
      errors.log(9999);
    }

    return errors.resolve(errors);
  }

  changePaymentSuccess(response) {
    this.$state.go('account.profile', {
      newPaymentGuid: response.paymentMethodGuid
    }, {
      reload: true
    });
  }
}

export default BoomUpdatePaymentFormController;
