import GiftSuccessViewController from './gift-success-view-controller';

function GiftSuccessState($stateProvider) {

  $stateProvider.state('purchaseconfirmation', {
    url: '/purchaseconfirmation',
    controller: GiftSuccessViewController,
    controllerAs: 'vm',
    templateUrl: '<div></div>',
    ignoreMobileWall: true,
    params: {
      email: null,
      date: null,
      price: null
    }
  });

}

GiftSuccessState.$inject = ['$stateProvider'];

export default GiftSuccessState;

