import AgeGateFormController from './age-gate-form-controller';

const AgeGateFormComponent = {
  bindings: {
    visible: '<',
    header: '<',
    answerModel: '=',
    check: '&',
    close: '&'
  },
  controller: AgeGateFormController,
  controllerAs: 'vm',
  templateUrl: 'modules/gates/components/age-gate-form/age-gate-form.html'
};

export default AgeGateFormComponent;
