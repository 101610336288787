import SubscribeViewController from 'views/subscribe/subscribe-view-controller';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomSubscribeViewController
*  @module Boom
*  @description
*    Responsible for managing the subscribe view.
*/
class BoomSubscribeViewController extends SubscribeViewController {

  static get $inject() {
    /* istanbul ignore next */
    return ['$state', 'ProgressBarService', 'platformjs.user.profile', 'detectjs', '$location', 'platformjs.storage'];
  }

  /* istanbul ignore next */
  constructor($state, ProgressBarService, UserProfileService, detectjs, $location, StorageService) {
    super($state, ProgressBarService);
    this.UserProfileService = UserProfileService;
    this.detectjs = detectjs;
    this.$location = $location;
    this.mobile = false;
    this.useMobileLogoLink = true;
    this.StorageService = StorageService;
  }

  $onInit() {
    this.checkMobile(navigator.userAgent);
    if (!this.$location.search().pid) {
      this.StorageService.remove('promotional-catalog');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#checkMobile
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Uses detectjs to check for "Mobile" device type
   */
  checkMobile(userAgent) {
    let ua = this.detectjs.parse(userAgent);
    this.mobile = ['mobile', 'tablet'].includes(ua.device.type.toLowerCase()) ||
    ['ios', 'android'].includes(ua.os.family.toLowerCase());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#showSubscribeErrors
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @param {array} errors A list of errors.
   *  @description
   *    Sets {@link Boom.Controllers:BoomSubscribeViewController#properties_errors errors} property.
   */
  showSubscribeErrors(errors) {
    if (errors[0] && errors[0].raw && errors[0].raw.code === 'INVALID_PAYMENTMETHOD') {
      errors[0].message = 'Payment method rejected';
    }
    this.errors = (errors.message) ? [errors] : errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#showSubscribeSuccess
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Redirects to the welcome page.
   */
  showSubscribeSuccess() {
    this.loadProfile()
      .then(profile => this.showProfile(profile))
      .catch(errors => this.showProfileFailure(errors))
      .finally(() => this.ProgressBarService.complete());
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#loadProfile
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Loads user profile.
   */
  loadProfile() {
    return this.UserProfileService.get();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#loadProfile
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Checks profile for user.subscriptionInfo.premiumFreeTrialEndDate to determine route
   */
  showProfile(profile) {
    this.$state.go('welcome');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#showProfileFailure
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Redirects user to login state if profile gets error
   */
  showProfileFailure() {
    this.$state.go('login');
  }

}

export default BoomSubscribeViewController;
