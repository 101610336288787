import { checkForAnnualPlan, capturePlanPrices } from 'utilities/utilities';

/**
*  @ngdoc controller
*  @name Boom.Controllers:MobileViewController
*  @module Boom
*  @requires $anchorScroll
*  @requires AnalyticsService
*  @requires platformjs.storage
*  @requires platformjs.metadata
*  @requires detectjs
*  @requires $state
*  @description
*    Mobile landing page
*/
class MobileViewController {

  static get $inject() {
    return [
      '$anchorScroll',
      'AnalyticsService',
      'platformjs.storage',
      'platformjs.metadata',
      'detectjs',
      '$state',
      'platformjs.catalog'
    ];
  }

  constructor($anchorScroll, AnalyticsService, StorageService, MetadataService, detectjs, $state, CatalogService) {
    this.$anchorScroll = $anchorScroll;
    this.AnalyticsService = AnalyticsService;
    this.StorageService = StorageService;
    this.detectjs = detectjs;
    this.MetadataService = MetadataService;
    this.$state = $state;
    this.CatalogService = CatalogService;

    this.characters = [
      {
        img: 'Scooby',
        content: []
      },

      {
        img: 'bugs',
        content: []
      },

      {
        img: 'jerry',
        content: []
      }
    ];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MobileViewController#$onInit
   *  @methodOf Boom.Controllers:MobileViewController
   *  @description
   *    Sets Analytics page to 'Mobile Landing' and clears the promotional catalog cookie
   */
  $onInit() {
    this.CatalogService.list()
      .then(catalog => this.formatPagePerCatalog(catalog));
    this.AnalyticsService.page('Mobile Landing');
    this.updateMetadata();
    this.StorageService.remove('promotional-catalog');
  }

  formatPagePerCatalog(catalog) {
    this.hasDefaultAnnualPlan = checkForAnnualPlan(catalog);
    this.prices = capturePlanPrices(catalog);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MobileViewController#scrollToTop
   *  @methodOf Boom.Controllers:MobileViewController
   *  @description
   *    Scrolls to the top using $anchorScroll
   */
  scrollToTop() {
    this.$anchorScroll();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MobileViewController#getDeviceType
   *  @methodOf Boom.Controllers:MobileViewController
   *  @description
   *    Returns a device type based on user agent string data
   */
  getDeviceType() {
    const ua = this.detectjs.parse(navigator.userAgent);
    const deviceType = ua.device.type.toLowerCase();

    if (deviceType === 'desktop' && ['ios', 'android'].includes(ua.os.family.toLowerCase())) {
      return 'tablet';
    }
    return deviceType;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MobileViewController#router
   *  @methodOf Boom.Controllers:MobileViewController
   *  @description
   *    Route to specified state
   */
  router(template, state) {
    let flowParams = [{
      'destination': template,
      'options': {
        'location': 'replace'
      }
    }];

    this.$state.go(state, {
      flowParams
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MobileViewController#updateMetadata
   *  @methodOf Boom.Controllers:MobileViewController
   *  @description
   *    Populates metadata
   */
  updateMetadata() {
    const title = 'Boomerang | Full Episodes of Your Family’s Favorite Cartoons';
    const description = 'Boomerang has full episodes of all your favorite cartoons all in one place! Your family will love watching classic cartoon shows like Looney Tunes, Tom and Jerry, The Flintstones, Yogi Bear, and so many more.';
    const image = 'images/social/default_share.png';

    this.MetadataService.setData({
      image,
      title,
      description
    });
  }
}

export default MobileViewController;
