import BoomRedemptionViewController from './boom-redemption-view-controller';

function BoomRedemptionState($stateProvider) {

  $stateProvider.state('startgift', {
    parent: 'language',
    url: '/startgift',
    templateUrl: 'views/redemption/redemption-view.html',
    controller: BoomRedemptionViewController,
    controllerAs: 'vm',
    ignoreMobileWall: true
  });

}

BoomRedemptionState.$inject = ['$stateProvider'];

export default BoomRedemptionState;
