/**
*  @ngdoc controller
*  @name Seed.Controllers:ForgotPasswordView
*  @module Seed
*  @description
*    Responsible for managing the forgot password view
*/
class ForgotPasswordViewController {
  constructor() {
    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:ForgotPasswordView
     *  @description
     *  @returns {array} Current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name success
     *  @propertyOf Seed.Controllers:ForgotPasswordView
     *  @description
     *  @returns {boolean} Current success state.
     */
    this.success = false;
  }
  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ForgotPasswordView#showSuccess
   *  @methodOf Seed.Controllers:ForgotPasswordView
   *  @description
   *    Sets `success` to true.
   */
  showSuccess() {

    this.success = true;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ForgotPasswordView#showErrors
   *  @methodOf Seed.Controllers:ForgotPasswordView
   *  @param {array} errors Errors list
   *  @description
   *    Shows passed errors.
   */
  showErrors(errors) {

    this.errors = errors;
    this.success = true;

  }
}

export default ForgotPasswordViewController;
