function ComponentsState($stateProvider) {

  $stateProvider.state('guide.components', {
    parent: 'language',
    url: '/components',
    templateUrl: 'views/guide/components/components.html'
  });

}

ComponentsState.$inject = ['$stateProvider'];

export default ComponentsState;
