import SubscribeViewController from 'views/subscribe/boom-subscribe-view-controller';

function SubscribeState($stateProvider) {

  $stateProvider.state('subscribe', {
    url: '/subscribe',
    controller: SubscribeViewController,
    controllerAs: 'vm',
    templateUrl: 'views/subscribe/subscribe-view.html',
    parent: 'register',
    userRequired: false,
    segment: {
      name: 'Payment Submission Page'
    },
    params: {
      product: null
    },
    ageGate: {
      fullscreen: false,
      failState: 'home-upsell'
    },
    ignoreMobileWall: true,
    dontBreakGateFlow: true
  });

}

SubscribeState.$inject = ['$stateProvider'];

export default SubscribeState;
