import DeviceRegistrationViewController from 'views/device-registration/device-registration-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomDeviceRegistrationViewController
 *  @module Boom
 *  @requires angular.$state
 *  @requires platformjs.device
 *  @description
 *    Responsible for making OTT sign in easier
 */
class BoomDeviceRegistrationViewController extends DeviceRegistrationViewController {
  static get $inject() {
    return ['$state', 'platformjs.device'];
  }

  /* istanbul ignore next */
  constructor($state, DeviceService) {
    super($state, DeviceService);

    this.$state = $state;
    this.DeviceService = DeviceService;

    this.submitting = false;

    if ($state.params.code) {
      this.code = $state.params.code;
    }
  }

  linkDevice() {
    // remove any previous errors
    this.errors = [];

    this.submitting = true;

    this.DeviceService.linkDevice(this.code.toUpperCase())
      .then(() => this.success())
      .catch((response) => this.error(response))
      .finally(() => this.done());
  }
}

export default BoomDeviceRegistrationViewController;