/**
*  @ngdoc service
*  @name Seed.Services:ProgressBar
*  @module Seed
*  @description
*    Wrapping service for controlling global progress bar.
*/
class ProgressBarService {
  static get $inject() {
    return ['ngProgressFactory'];
  }
  constructor(ngProgressFactory) {
    this.instance = ngProgressFactory.createInstance();
  }
  /**
   *  @ngdoc method
   *  @name Seed.Services:ProgressBar#complete
   *  @methodOf Seed.Services:ProgressBar
   *  @description
   *    Completes progress bar animation.
   */

  complete() {
    this.instance.complete();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:ProgressBar#getInstance
   *  @methodOf Seed.Services:ProgressBar
   *  @returns {object} Current instance of progress bar.
   *  @description
   *    Gets instance of progress bar.
   */

  getInstance() {
    return this.instance;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:ProgressBar#reset
   *  @methodOf Seed.Services:ProgressBar
   *  @description
   *    Resets progress bar animation.
   */

  reset() {
    this.instance.reset();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:ProgressBar#set
   *  @methodOf Seed.Services:ProgressBar
   *  @param {number} value Value of completion / 100 
   *  @description
   *    Sets value for completion of progress bar animation.
   */

  set(value) {
    this.instance.set(value);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:ProgressBar#setColor
   *  @methodOf Seed.Services:ProgressBar
   *  @param {string} value Hex value.
   *  @description
   *    Sets hex value for progres bar fill color.
   */

  setColor(value) {
    this.instance.setColor(value);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:ProgressBar#start
   *  @methodOf Seed.Services:ProgressBar
   *  @description
   *    Starts progress bar animation.
   */

  start() {
    this.instance.start();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:ProgressBar#stop
   *  @methodOf Seed.Services:ProgressBar
   *  @description
   *    Stops progress bar animation.
   */

  stop() {
    this.instance.stop();
  }
}

export default ProgressBarService;
