const BuildPathFilter = function(platform, $window) {
  {
    return function(path, absolute) {
      var build;
      if (platform.build && platform.build.id) {
        build = platform.build.id;
      }

      if (path && build) {
        if (noConversionNeeded(build, path)) {
          return path;
        }

        path = `builds/${build}/${path}`;
        if (absolute) {

          // get the current url
          var origin = $window.location.href

            // split the url apart on forward-slashes
            .split('/')

            // drop anything after the port
            .slice(0, 3)

            // bring them back together properly formatted
            .join('/');

          path = origin + '/' + path;
        }
      }
      return path;
    };
  }
};

function noConversionNeeded(build, path) {
  let noConversion = false;
  if (build.indexOf('@@') === 0) {
    noConversion = true;
  } else {
    const absolutePathPrefixes = ['http:', 'https:', '/'];
    absolutePathPrefixes.forEach((prefix) => {
      if (path.indexOf(prefix) === 0) {
        noConversion = true;
      }
    });
  }
  return noConversion;
}

BuildPathFilter.$inject = ['platform', '$window'];

export default BuildPathFilter;
