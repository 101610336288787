/**
*  @ngdoc controller
*  @name Boom.Controllers:TosPPBannerController
*  @module Boom
*  @description
*    Responsible for showing/hiding the tos/pp banner.
*/
export default class TosPPBannerController {
  static get $inject() {
    return [
      'AgreementModalService',
      'platformjs.user.account',
      '$state',
      '$window'
    ];
  }

  constructor(AgreementModalService, UserAccountService, $state, $window) {
    this.AgreementModalService = AgreementModalService;
    this.UserAccountService = UserAccountService;
    this.$state = $state;
    this.$window = $window;
    this.safeRoutes = [
      'terms',
      'privacy',
      'login',
      'subscribe',
      'welcome',
      'sale'
    ];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:TosPPBannerController#shouldShow
   *  @methodOf Boom.Controllers:TosPPBannerController
   *  @description
   *    Shows if terms are not agreed
   */
  shouldShow() {
    if (this.UserAccountService.isAnonymous() ||
      this.safeRoutes.includes(this.$state.current.name)) {
      return false;
    }

    const hasAgreed = this.AgreementModalService.isAgreedTerms();
    this.onAgreedTo = !hasAgreed && hasAgreed !== null;
    if (this.onAgreedTo) {
      this.lockBody();
    }
    return this.onAgreedTo;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:TosPPBannerController#lockBody
  *  @methodOf Boom.Controllers:TosPPBannerController
  *  @description
  *    Locks the body, prevents scrolling
  */
  lockBody() {
    this.$window.document.body.style.height = '100vh';
    this.$window.document.body.style.overflowY = 'hidden';
    this.$window.document.body.style.overflow = 'hidden';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:TosPPBannerController#unlockBody
   *  @methodOf Boom.Controllers:TosPPBannerController
   *  @description
   *    Unlocks the body, allows scrolling
   */
  unlockBody() {
    this.$window.document.body.style.height = '';
    this.$window.document.body.style.overflowY = '';
    this.$window.document.body.style.overflow = '';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:TosPPBannerController#agreeTerms
   *  @methodOf Boom.Controllers:TosPPBannerController
   *  @description
   *    Agrees to terms, unlocks web app
   */
  agreeTerms($event) {
    if ($event) {
      $event.preventDefault();
    }

    this.AgreementModalService.agreeTerms();
    this.unlockBody();
  }
}
