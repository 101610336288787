/**
*  @ngdoc controller
*  @name Boom.Modules:analytics
*  @module Boom
*  @requires AnalyticsService
*  @description
*    Responsible managing the analytics calls
*/
import AnalyticsService from './services/analytics-service';
import AnalyticsTrackDirective from './directives/analytics-track';

const AnalyticsModule = angular.module('boom.analytics', ['ui.router'])
  .service('AnalyticsService', AnalyticsService)
  .directive('analyticsTrack', AnalyticsTrackDirective)
  .run(['AnalyticsService', function(AnalyticsService) {
    AnalyticsService.addTransitionHook();
  }])

  //identify users on page load
  .run(['AnalyticsService', function(AnalyticsService) {
    AnalyticsService.identify();
  }]);

export default AnalyticsModule;
