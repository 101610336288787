import ApplicationController from 'controllers/application-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:AppApplicationController
 *  @module Boom
 *  @requires platformjs.user
 *  @requires platformjs.user.profile
 *  @requires platformjs.metadata
 *  @requires platformjs.storage
 *  @requires angular.$timeout
 *  @requires angular.$state
 *  @requires angular.$transitions
 *  @requires angular.$stateParams
 *  @requires angular.$translate
 *  @requires angular.$window
 *  @requires UrlLanguage
 *  @requires gates.ageGate
 *  @requires AnalyticsService
 *  @requires AgreementModalService
 *  @description
 *    Responsible for displaying watch information.
 */
class AppApplicationController extends ApplicationController {

  static get $inject() {
    /* istanbul ignore next */
    return [
      'platformjs.user',
      'platformjs.user.profile',
      'platformjs.metadata',
      'platformjs.storage',
      '$timeout',
      '$state',
      '$transitions',
      '$stateParams',
      '$translate',
      '$window',
      'UrlLanguage',
      'gates.ageGate',
      'gates.babyGate',
      'AnalyticsService',
      'AgreementModalService'
    ];
  }

  /* istanbul ignore next */
  constructor(
    UserService,
    UserProfileService,
    MetaDataService,
    StorageService,
    $timeout,
    $state,
    $transitions,
    $stateParams,
    $translate,
    $window,
    UrlLanguage,
    AgeGateService,
    BabyGateService,
    AnalyticsService,
    AgreementModalService
  ) {

    super(UserService, MetaDataService);
    this.UserService = UserService;
    this.UserProfileService = UserProfileService;
    this.MetaDataService = MetaDataService;
    this.AgeGateService = AgeGateService;
    this.BabyGateService = BabyGateService;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$transitions = $transitions;
    this.$translate = $translate;
    this.$window = $window;
    this.UrlLanguage = UrlLanguage;
    this.StorageService = StorageService;
    this.AnalyticsService = AnalyticsService;
    this.AgreementModalService = AgreementModalService;
  }

  $onInit() {
    this.UserProfileService.get()
      .then((profile) => {
        this.changeLanguage(profile.language);
      })
      .catch(() => {
        this.changeLanguage();
      });

    this.languageSelectorIsOpen = false;

    // Initialize agreement flow.
    this.AgreementModalService.loadAgreements();

    // Set up listener for device service disconnection.
    window.addEventListener('offline', this.onConnectionLost.bind(this));
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AppApplicationController#onConnectionLost
   *  @methodOf Boom.Controllers:AppApplicationController
   *  @param {Object} e Event object
   *  @description
   *    Initiates a state change to the device-offline state.
   */
  onConnectionLost() {
    this.$state.go('offline', {
      onlineRedirectStateName: this.$state.current.name
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AppApplicationController#isHidden
   *  @methodOf Boom.Controllers:AppApplicationController
   *  @returns {boolean} if the main content body should be hidden
   *  @description
   *    Determine if the main content body should be hidden
   */
  isHidden() {
    return false;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AppApplicationController#getUrl
   *  @methodOf Boom.Controllers:AppApplicationController
   *  @returns {string} the url of the page
   *  @description
   *    Return the url of the page.
   */
  getUrl() {
    return this.$window.location.href;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AppApplicationController#onLanguageSelectorChanged
   *  @methodOf Boom.Controllers:AppApplicationController
   *  @param {string} language the language code to change to eg: 'en', 'es'
   *  @description
   *    Callback from the language selector being toggled
   *    Reloads the state after setting the language
   */
  onLanguageSelectorChanged(language) {
    this.changeLanguage(language);
    this.$state.reload();
    this.AgreementModalService.loadAgreements();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AppApplicationController#changeLanguage
   *  @methodOf Boom.Controllers:AppApplicationController
   *  @param {string} language the language code to change to eg: 'en', 'es'
   *  @description
   *    Changes the users language for the app and stores it on their profile
   */
  changeLanguage(language) {
    if (!language) {
      language = this.UrlLanguage.activeLanguage;
    }

    // load the translation json file
    this.$translate.use(language);

    // set active language
    this.UrlLanguage.setActiveLanguage(language);

    //set a cookie with the language
    //we use the user- prefix so that platform doesnt blow this away
    this.StorageService.set('user-language', language);

    // platform.js looks at either this or profile.deviceLanguage
    // either way we have to set something on the user profile, even if there is no user
    this.UserService.profile.language = language;

    this.UserProfileService.setDeviceLanguage(language);

    //neither of these properties exist on a boom profile, so we
    //need to get rid of them for it to pass the validator
    delete this.UserService.profile.gender;
    delete this.UserService.profile.dob;

    //we fire and forget this
    this.UserProfileService.setLanguage(language)
      .catch(angular.noop);

    //set the video player language settings
    const currentSubLang = this.StorageService.get('vp-defaultSubtitleLanguage');
    if (currentSubLang && currentSubLang !== 'off') {
      this.StorageService.set('vp-defaultSubtitleLanguage', language);
    }
    this.StorageService.set('vp-defaultAudioLanguage', language);
    this.AnalyticsService.identify();
  }

}


export default AppApplicationController;
