/**
 *  @ngdoc controller
 *  @name Gates.Controllers:AgeGateController
 *  @module Boom
 *  @description
 *    Responsible for Showing the age gate
 *  @fires agegate-close
 */
class AgeGateController {
  static get $inject() {
    return [
      'gates.ageGate',
      'gates.babyGate',
      '$rootScope',
      '$element',
      '$timeout'
    ];
  }

  constructor(AgeGateService, BabyGateService, $rootScope, $element, $timeout) {
    this.AgeGateService = AgeGateService;
    this.BabyGateService = BabyGateService;
    this.showAlertCopy = false;
    this.loading = false;

    this.$rootScope = $rootScope;
    this.$element = $element;
    this.$timeout = $timeout;
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateController#isFullscreenVisible
   *  @methodOf Gates.Controllers:AgeGateController
   *  @returns {boolean} isVisible If we are in fullscreen mode
   *  @description
   *    Checks the internal age gate service state and configuration to
   *    determine if the fullscreen version of the age gate should be visible
   */
  isFullscreenVisible() {
    return !!(
      this.AgeGateService.state.open &&
      this.AgeGateService.state.config &&
      this.AgeGateService.state.config.fullscreen
    );
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateController#isModalVisible
   *  @methodOf Gates.Controllers:AgeGateController
   *  @returns {boolean} isVisible If we are in modal mode
   *  @description
   *    Checks the internal age gate service state and configuration to
   *    determine if the modal version of the age gate should be visible
   */
  isModalVisible() {
    return (
      this.AgeGateService.state.open &&
      (!this.AgeGateService.state.config ||
        (this.AgeGateService.state.config &&
          !this.AgeGateService.state.config.fullscreen))
    );
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateController#check
   *  @methodOf Gates.Controllers:AgeGateController
   *  @description
   *    Proxies our answer to the age gate service for checking.  If successful,
   *    updates the service's state and calls the resolve method.
   *    Otherwise sets our flag to show the "you need an adult" text
   *    Always clears our answer in case another attempt needs to be made
   */
  check() {
    if (this.AgeGateService.check(this.answer)) {
      this.loading = true;
      this.$timeout(() => {
        this.AgeGateService.state.open = false;
        this.AgeGateService.state.resolve({
          age: this.answer
        });
        this.loading = false;
      }, 1500);
    } else {
      this.$element.find('input')[0].blur();
      this.showAlertCopy = true;
    }
    this.answer = '';
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateController#dismissAlert
   *  @methodOf Gates.Controllers:AgeGateController
   *  @description
   *    Closes the "you need an adult" text.
   *    If now locked, closes the age gate and rejects the state's promise.
   *    Otherwise opens the baby gate and sets up the callback to reopen the age gate
   *    after they have passed the baby gate.
   */
  dismissAlert() {
    this.AgeGateService.checkLocked();
    this.showAlertCopy = false;
    if (this.AgeGateService.state.locked) {
      this.AgeGateService.state.open = true;
      this.AgeGateService.state.reject();
    } else if (this.AgeGateService.state.config.babyGateOnFail) {
      this.AgeGateService.state.open = false;

      this.BabyGateService.open(
        {
          fullscreen: this.AgeGateService.state.config.fullscreen
        },
        this.AgeGateService.params
      ).then(() => {
        this.AgeGateService.state.open = true;
      });
    }
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateController#close
   *  @methodOf Gates.Controllers:AgeGateController
   *  @description
   *    Updates the service's state and calls the reject method passing a config param
   *    to indicate the the user closed the modal, it wasn't failed.
   */
  close() {
    if (
      this.AgeGateService.state.config.babyGateOnFail &&
      !this.AgeGateService.state.locked
    ) {
      this.AgeGateService.showBabyGateOnNextOpen = true;
    }

    this.showAlertCopy = false;
    this.AgeGateService.state.open = false;
    this.AgeGateService.state.reject({
      closed: true
    });
    this.$rootScope.$broadcast('agegate-close');
  }
}

export default AgeGateController;
