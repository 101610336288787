/**
 *  @ngdoc controller
 *  @name Seed.Controllers:PaymentMethodsForm
 *  @module Seed
 *  @requires platformjs.subscriptions
 *  @description
 *    Responsible for subscribing a user.
 */
class PaymentMethodsFormController {
  constructor() {
    /**
     *  @ngdoc property
     *  @name components
     *  @propertyOf Seed.Controllers:PaymentMethodsForm
     *  @returns {array} A list of component names.
     *  @description
     *    An array of all the components that are required to have initialized before
     *    showReady is called.
     */
    this.components = ['paymentMethods', 'paymentForm'];

    /**
     *  @ngdoc property
     *  @name paymentMethod
     *  @propertyOf Seed.Controllers:PaymentMethodsForm
     *  @returns {object} A paymentMethod object.
     *  @description
     *    Currently selected paymentMethod.
     */
    this.paymentMethod = null;

    /**
     *  @ngdoc property
     *  @name submitting
     *  @propertyOf Seed.Controllers:PaymentMethodsForm
     *  @returns {boolean} Status.
     *  @description
     *    Current submission status.
     */
    this.submitting = false;

    this.onError = this.onError || angular.noop;
    this.onFailure = this.onFailure || angular.noop;
    this.onReady = this.onReady || angular.noop;
    this.onSubmit = this.onSubmit || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentMethodsForm#selectPaymentMethod
   *  @methodOf Seed.Controllers:PaymentMethodsForm
   *  @param {object} paymentMethod A payment method object. 
   *  @description
   *    Sets {@link Seed.Controllers:PaymentMethodsForm#properties_paymentMethod paymentMethod} to passed paymentMethod.
   */
  selectPaymentMethod(paymentMethod) {

    this.paymentMethod = paymentMethod;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentMethodsForm#showComponentReady
   *  @methodOf Seed.Controllers:PaymentMethodsForm
   *  @param {string} name A component name.
   *  @description
   *    
   */
  showComponentReady(name) {

    var index = this.components.indexOf(name);

    if (index > -1) {
      this.components.splice(index, 1);
    }

    if (this.components.length === 0) {
      this.showReady();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentMethodsForm#showErrors
   *  @methodOf Seed.Controllers:PaymentMethodsForm
   *  @param {errors} errors A list of errors.
   *  @description
   *    Triggers onError and passes error list.
   */
  showErrors(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentMethodsForm#showFailure
   *  @methodOf Seed.Controllers:PaymentMethodsForm
   *  @description
   *    Triggers onFailure.
   */
  showFailure() {

    this.onFailure();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentMethodsForm#showReady
   *  @methodOf Seed.Controllers:PaymentMethodsForm
   *  @description
   *    Triggers onReady.
   */
  showReady() {

    this.onReady();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentMethodsForm#showSuccess
   *  @methodOf Seed.Controllers:PaymentMethodsForm
   *  @description
   *    Triggers onSuccess.
   */
  showSuccess() {

    this.onSuccess();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentMethodsForm#submit
   *  @methodOf Seed.Controllers:PaymentMethodsForm
   *  @param {object} paymentMethod A payment method.
   *  @description
   *    Attempts to update a payment method using passed payment method.
   */
  submit(paymentMethod) {

    this.onSubmit();

    var params = {
      paymentMethodGuid: paymentMethod.guid || paymentMethod.paymentMethodGuid
    };

    /**
     * TODO: Call endpoint to update payment option.
     * This is pending the Premium API's ability to update your payment method.
     *
     * return SomePaymentService.update(params)
     *   .then(() => this.showSuccess())
     *   .catch((errors) => this.showErrors(errors));
     *
     */

  }
}

export default PaymentMethodsFormController;
