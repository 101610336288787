/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomRedirectController
 *  @module Boom
 *  @requires $location
 *  @requires $window
 *  @requires platform
 *  @requires platformjs.metadata
 *  @requires $http
 *  @requires $timeout
 *  @requires UserAgentService
 *  @description
 *    Responsible for managing the redirects to the watch site
 */
class BoomRedirectController {
  static get $inject() {
    return ['$location', '$window', 'platform', 'platformjs.metadata', '$http', '$timeout', 'UserAgentService'];
  }

  constructor($location, $window, platform, MetaDataService, $http, $timeout, UserAgentService) {
    this.$location = $location;
    this.$window = $window;
    this.MetaDataService = MetaDataService;
    this.$http = $http;
    this.$timeout = $timeout;
    this.UserAgentService = UserAgentService;

    const relativePath = $location.$$path;
    this.path = `${$location.protocol()}://${platform.watchDomain.url}${relativePath}`;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomRedirectController#$onInit
   *  @methodOf Boom.Controllers:BoomRedirectController
   *  @description
   *    Sets Prerender headers and redirects non-bot users
   */
  $onInit() {
    this.MetaDataService.setData({
      prerenderStatusCode: 301,
      prerenderHeader: `Location: ${this.path}`
    });
    if (!this.isBot()) {
      this.go();
    }
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomRedirectController#go
  *  @methodOf Boom.Controllers:BoomRedirectController
  *  @description
  *    Redirects users while suppressing default link behavior if this redirect is the result of clicking a link
  */
  go($event) {
    //if the link on the page was clicked, prevent the default link
    //behavior so we can do the redirect manually
    if ($event) {
      $event.preventDefault();
    }

    this.checkToRedirect();
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomRedirectController#checkToRedirect
  *  @methodOf Boom.Controllers:BoomRedirectController
  *  @description
  *    Redirects user after ensuring that all AJAX requests have completed
  */
  checkToRedirect() {
    //make sure that we don't redirect until all pending ajax requests have finished
    if (!this.$http.pendingRequests.length) {
      //manually redirect the user because we don't want this page
      //to end up in the browser's history
      this.$window.location.replace(this.path);
      return;
    }
    this.$timeout(this.checkToRedirect.bind(this), 200);
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomRedirectController#isBot
  *  @methodOf Boom.Controllers:BoomRedirectController
  *  @description
  *    Returns true if the user is a bot, else returns false
  */
  isBot() {
    const userAgentString = this.UserAgentService.getUserAgent().source;

    const botRegExp = new RegExp('googlebot|bingbot|yandex|baiduspider|twitterbot|facebookexternalhit|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest|discordbot|slackbot|vkShare|W3C_Validator|prerender', 'i');

    return botRegExp.test(userAgentString);
  }
}

export default BoomRedirectController;
