/**
*  @ngdoc controller
*  @name Seed.Controllers:ChangePasswordForm
*  @module Seed
*  @requires platformjs.user.login
*  @description
*    Responsible for changing user password with token.
*/
class ChangePasswordFormController {
  static get $inject() {
    return ['platformjs.user.login'];
  }

  constructor(LoginService) {
    this.LoginService = LoginService;
    /**
    *  @ngdoc property
    *  @name password
    *  @propertyOf Seed.Controllers:ChangePasswordForm
    *  @returns {string} Email.
    *  @description
    *    User password input value.
    */
    this.password = '';

    /**
     *  @ngdoc property
     *  @name password2
     *  @propertyOf Seed.Controllers:ChangePasswordForm
     *  @returns {string} Email.
     *  @description
     *    User password confirmation input value.
     */
    this.password2 = '';

    /**
     *  @ngdoc property
     *  @name submitting
     *  @propertyOf Seed.Controllers:ChangePasswordForm
     *  @returns {boolean} Status.
     *  @description
     *    Current submission status.
     */
    this.submitting = false;

    this.onError = this.onError || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ChangePasswordForm#showSubmitErrors
   *  @methodOf Seed.Controllers:ChangePasswordForm
   *  @param {array} errors Errors 
   *  @description
   *    Called on failure of request for change password.
   */
  showSubmitErrors(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ChangePasswordForm#showSubmitComplete
   *  @methodOf Seed.Controllers:ChangePasswordForm
   *  @description
   *    Called on complete of request for change password.
   */
  showSubmitComplete() {

    this.submitting = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ChangePasswordForm#showSubmitSuccess
   *  @methodOf Seed.Controllers:ChangePasswordForm
   *  @description
   *    Called on success of request for change password.
   */
  showSubmitSuccess() {

    this.onSuccess();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ChangePasswordForm#submit
   *  @methodOf Seed.Controllers:ChangePasswordForm
   *  @description
   *    Submit form data to make request for change password.
   */
  submit() {

    this.submitting = true;

    this.LoginService.changePasswordWithToken(this.token, this.password, this.password2)
      .then(() => this.showSubmitSuccess())
      .catch(errors => this.showSubmitErrors(errors))
      .finally(() => this.showSubmitComplete());

  }

}

export default ChangePasswordFormController;
