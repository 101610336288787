/**
 *  @ngdoc controller
 *  @name Seed.Controllers:PaymentSelection
 *  @module Seed
 *  @requires platformjs.paymentmethods
 *  @requires platformjs.user.account
 *  @description
 *    Responsible for handling the selection of a payment method during subscription flow. 
 */
class PaymentSelectionController {
  static get $inject() {
    return ['platformjs.paymentmethods', 'platformjs.user.account'];
  }

  constructor(PaymentMethodsService, UserAccountService) {
    this.PaymentMethodsService = PaymentMethodsService;
    this.UserAccountService = UserAccountService;

    /**
     *  @ngdoc property
     *  @name paymentMethod
     *  @propertyOf Seed.Controllers:PaymentSelection
     *  @returns {object} Payment method object.
     *  @description
     *    Currently selected payment method.
     */
    this.paymentMethod = null;

    /**
     *  @ngdoc property
     *  @name paymentMethods
     *  @propertyOf Seed.Controllers:PaymentSelection
     *  @returns {array} Payment methods list.
     *  @description
     *    List of payment methods.
     */
    this.paymentMethods = null;

    this.onPaymentMethodSelect = this.onPaymentMethodSelect || angular.noop;
    this.onFailure = this.onFailure || angular.noop;
    this.onReady = this.onReady || angular.noop;
  }

  $onInit() {

    this.PaymentMethodsService.list()
      .then(paymentMethods => this.showPaymentMethods(paymentMethods), errors => this.showFailure(errors));

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentSelection#selectPaymentMethod
   *  @methodOf Seed.Controllers:PaymentSelection
   *  @param {object} paymentMethod A payment method
   *  @description
   *    Sets {@link Seed.Controllers:PaymentSelection#properties_paymentMethod paymentMethod} to the passed paymentMethod.
   */
  selectPaymentMethod(paymentMethod) {

    this.paymentMethod = paymentMethod;
    this.onPaymentMethodSelect({
      paymentMethod: paymentMethod
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentSelection#showFailure
   *  @methodOf Seed.Controllers:PaymentSelection
   *  @param {array} errors A list of errors.
   *  @description
   *    Triggers onFailure.
   */
  showFailure(errors) {

    this.onFailure({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:PaymentSelection#showPaymentMethods
   *  @methodOf Seed.Controllers:PaymentSelection
   *  @param {array} paymentMethods A list of payment methods.
   *  @description
   *    Displays a list of paymentMethods. Triggers onReady.
   */
  showPaymentMethods(paymentMethods) {

    this.paymentMethods = paymentMethods;
    this.selectPaymentMethod(this.paymentMethods[0]);

    this.onReady();

  }
}

export default PaymentSelectionController;
