/**
 *  @ngdoc controller
 *  @name Boom.Controllers:SuccessViewController
 *  @module Boom
 *  @description
 *    Responsible redirecting Six Flags promo users through an age gate before subscribe
 */
class SuccessViewController {

  static get $inject() {
    /* istanbul ignore next */
    return [
      '$state',
      'platformjs.user.profile',
      'ProgressBarService'
    ];
  }

  /* istanbul ignore next */
  constructor($state, UserProfileService, ProgressBarService) {
    this.$state = $state;
    this.UserProfileService = UserProfileService;
    this.ProgressBarService = ProgressBarService;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Boom.Controllers:SuccessViewController
     *  @returns {array} Error list
     *  @description
     *    List of current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name profile
     *  @propertyOf Boom.Controllers:SuccessViewController
     *  @returns {object} User profile
     *  @description
     *    Current user profile object.
     */
    this.profile = null;

    /**
     *  @ngdoc property
     *  @name success
     *  @propertyOf Boom.Controllers:SuccessViewController
     *  @returns {string} Update success message
     *  @description
     *    Success message.
     */
    this.success = '';

    /**
     *  @ngdoc property
     *  @name trialDays
     *  @propertyOf Boom.Controllers:SuccessViewController
     *  @description
     *    Number of days for trial on promotion
     */
    this.trialDays = null;

    this.useMobileLogoLink = true;
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SuccessViewController#$onInit
   *  @methodOf Boom.Controllers:SuccessViewController
   *  @description
   *    Loads user profile.
   */
  $onInit() {
    this.ProgressBarService.start();

    this.loadProfile()
      .then(profile => this.showProfile(profile))
      .catch(errors => this.showProfileFailure(errors))
      .finally(() => this.ProgressBarService.complete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SuccessViewController#loadProfile
   *  @methodOf Boom.Controllers:SuccessViewController
   *  @description
   *    Loads user profile.
   */
  loadProfile() {

    return this.UserProfileService.get();

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SuccessViewController#showProfile
   *  @methodOf Boom.Controllers:SuccessViewController
   *  @param {object} profile User profile
   *  @description
   *    Called on the success of the initial request for a user's profile.
   */
  showProfile(profile) {
    if (profile && profile.subscriptionInfo.premiumFreeTrialEndDate) {
      let signupDate = new Date(profile.signupDate);
      let premiumFreeTrialEndDate = new Date(profile.subscriptionInfo.premiumFreeTrialEndDate);

      let timeDiff = Math.abs(premiumFreeTrialEndDate.getTime() - signupDate.getTime());
      let trialDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      this.profile = profile;
      this.trialDays = trialDays;

    } else {
      this.$state.go('subscribe');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SuccessViewController#showProfileFailure
   *  @methodOf Boom.Controllers:SuccessViewController
   *  @param {array} errors Error list
   *  @description
   *    Called on the failure of the initial request for a user's profile.
   */
  showProfileFailure(errors) {

    this.errors = errors;

  }

}

export default SuccessViewController;
