import BoomWelcomeViewController from './boom-welcome-view-controller';

function BoomWelcomeState($stateProvider) {

  $stateProvider.state('welcome', {
    url: '/welcome',
    controller: BoomWelcomeViewController,
    controllerAs: 'vm',
    templateUrl: 'views/welcome/welcome-view.html',
    parent: 'register',
    ignoreMobileWall: true,
    userRequired: true
  });

}

BoomWelcomeState.$inject = ['$stateProvider'];

export default BoomWelcomeState;
