import ActiveSubscriptionController from './active-subscription-controller';

const BoomHistoryItemComponent = {
  bindings: {
    subscription: '<',
    allowCancel: '<',
    hasBeenCanceled: '<',
    nextBillingDate: '<',
    paymentInfo: '<'
  },
  controller: ActiveSubscriptionController,
  controllerAs: 'vm',
  templateUrl: 'components/active-subscription/active-subscription.html'
};

export default BoomHistoryItemComponent;
