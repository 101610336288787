import GiftPurchaseViewController from 'views/gift/gift-purchase-view-controller';

function GiftState($stateProvider) {

  $stateProvider.state('giftpurchase', {
    url: '/giftpurchase',
    controller: GiftPurchaseViewController,
    controllerAs: 'vm',
    template: '<div></div>',
    params: {
      giftProduct: null
    },
    userRequired: false,
    ignoreMobileWall: true
  });

}

GiftState.$inject = ['$stateProvider'];

export default GiftState;
