/**
 *  @ngdoc controller
 *  @name Boom.Controllers:CancelConfirmationViewController
 *  @module Boom
 *  @description
 *    Responsible for displaying Cancel View.
 */
class CancelConfirmationViewController {
  static get $inject() {
    return [
      'platformjs.subscriptions',
      'platformjs.user.profile',
      'AnalyticsService'
    ];
  }

  constructor(SubscriptionsService, UserProfileService, AnalyticsService) {
    this.SubscriptionsService = SubscriptionsService;
    this.UserProfileService = UserProfileService;
    this.AnalyticsService = AnalyticsService;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Boom.Controllers:CancelConfirmationViewController
     *  @returns {array} Error list
     *  @description
     *    List of current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name cancelConfirmed
     *  @propertyOf Boom.Controllers:CancelConfirmationViewController
     *  @returns {boolean} Toggle cancellation message
     *  @description
     *    Toggles between cancellation prompt and cancellation confirmation message
     */
    this.cancelConfirmed = false;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelConfirmationViewController#$onInit
   *  @methodOf Boom.Controllers:CancelConfirmationViewController
   *  @description
   *    Loads billing end date.
   */
  $onInit() {
    this.watchThrough();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelConfirmationViewController#watchThrough
   *  @methodOf Boom.Controllers:CancelConfirmationViewController
   *  @description
   *    Gets end of cycle date
   */
  watchThrough() {
    this.UserProfileService.get().then(response => {
      this.cancelDate = response.subscriptionInfo.nextBillingDate;
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelConfirmationViewController#processCancel
   *  @methodOf Boom.Controllers:CancelConfirmationViewController
   *  @description
   *    Sends cancellation request.
   */
  processCancel() {

    this.SubscriptionsService.cancel()
      .then(response => this.cancelSuccess(response))
      .catch(errors => this.cancelFailure(errors))
      .finally(() => this.cancelComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelConfirmationViewController#cancelSuccess
   *  @methodOf Boom.Controllers:CancelConfirmationViewController
   *  @description
   *    Called on cancellation success
   */
  cancelSuccess(response) {
    this.UserProfileService.get();
    this.AnalyticsService.page('Cancellation Complete');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelConfirmationViewController#cancelFailure
   *  @methodOf Boom.Controllers:CancelConfirmationViewController
   *  @description
   *    Called on cancellation failure
   */
  cancelFailure(errors) {
    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelConfirmationViewController#cancelComplete
   *  @methodOf Boom.Controllers:CancelConfirmationViewController
   *  @description
   *    Called on cancellation complete
   */
  cancelComplete() {
    this.cancelConfirmed = true;
  }

}

export default CancelConfirmationViewController;
