import BoomProductSelectionController from '../product-selection/boom-product-selection-controller';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomProductSelectionPromoController
*  @module Boom
*  @requires $location
*  @requires $state
*  @requires $cookies
*  @requires platformjs.catalog'
*  @requires $attrs
*  @requires AnalyticsService
*  @requires platformjs.premiumProfile
*  @requires $timeout
*  @requires platformjs.user.profile
*  @requires moment
*  @requires platformjs.storage
*  @description
*    Responsible for subscribing a user.
*/
class BoomProductSelectionPromoController extends BoomProductSelectionController {
  static get $inject() {
    return [
      '$location',
      '$state',
      '$cookies',
      'platformjs.catalog',
      '$attrs',
      'AnalyticsService',
      'platformjs.premiumProfile',
      '$timeout',
      'platformjs.user.profile',
      'moment',
      'platformjs.storage',
      'gates.ageGate'
    ];
  }

  /* istanbul ignore next */
  constructor($location, $state, $cookies, CatalogService, $attrs, AnalyticsService, PremiumProfileService, $timeout, UserProfileService, moment, StorageService, AgeGateService) {
    super($location, $state, $cookies, CatalogService, $attrs, AnalyticsService, PremiumProfileService, $timeout, UserProfileService, moment, StorageService, AgeGateService);
  }
}

export default BoomProductSelectionPromoController;
