import LoginViewController from 'views/login/boom-login-view-controller';

function LoginState($stateProvider) {

  $stateProvider.state('login', {
    url: '/login',
    controller: LoginViewController,
    controllerAs: 'vm',
    templateUrl: 'views/login/login-view.html',
    parent: 'register',
    ignoreMobileWall: true,
    params: {
      destinationState: 'home-upsell',
      destinationParams: null
    }
  });

}

LoginState.$inject = ['$stateProvider'];

export default LoginState;
