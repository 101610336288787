/**
*  @ngdoc controller
*  @name Seed.Controllers:SubscribeForm
*  @module Seed
*  @requires platformjs.subscriptions
*  @requires platformjs.paymentmethods
*  @description
*    Responsible for subscribing a user.
*/
class SubscribeFormController {
  static get $inject() {
    return ['platformjs.subscriptions', 'platformjs.paymentmethods'];
  }

  constructor(SubscriptionsService, PaymentMethodsService) {
    this.SubscriptionsService = SubscriptionsService;
    this.PaymentMethodsService = PaymentMethodsService;

    /**
     *  @ngdoc property
     *  @name components
     *  @propertyOf Seed.Controllers:SubscribeForm
     *  @returns {array} A list of component names.
     *  @description
     *    An array of all the components that are required to have initialized before
     *    showReady is called.
     */
    this.components = ['products', 'paymentForm'];

    /**
     *  @ngdoc property
     *  @name paymentMethod
     *  @propertyOf Seed.Controllers:SubscribeForm
     *  @returns {object} A paymentMethod object.
     *  @description
     *    Currently selected paymentMethod.
     */
    this.paymentMethod = null;

    /**
     *  @ngdoc property
     *  @name product
     *  @propertyOf Seed.Controllers:SubscribeForm
     *  @returns {object} A product object.
     *  @description
     *    Currently selected product.
     */
    this.product = null;

    this.promocode = null;

    /**
     *  @ngdoc property
     *  @name submitting
     *  @propertyOf Seed.Controllers:SubscribeForm
     *  @returns {boolean} Status.
     *  @description
     *    Current submission status.
     */
    this.submitting = false;

    this.onError = this.onError || angular.noop;
    this.onFailure = this.onFailure || angular.noop;
    this.onReady = this.onReady || angular.noop;
    this.onSubmit = this.onSubmit || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#$onInit
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @description
   *    Makes initial request for a payment method to display.
   */
  $onInit() {
    this.PaymentMethodsService.list()
      .then(paymentMethods => this.setPaymentMethod(paymentMethods), () => this.showFailure());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#selectProduct
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @param {object} product A product object.
   *  @description
   *    Sets {@link Seed.Controllers:SubscribeForm#properties_product product} to passed product.
   */
  selectProduct(product) {

    this.product = product;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#showComponentReady
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @param {string} name A component name.
   *  @description
   *
   */
  showComponentReady(name) {

    var index = this.components.indexOf(name);

    if (index > -1) {
      this.components.splice(index, 1);
    }

    if (this.components.length === 0) {
      this.showReady();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#showErrors
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @param {errors} errors A list of errors.
   *  @description
   *    Triggers onError and passes error list.
   */
  showErrors(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#showFailure
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @description
   *    Triggers onFailure.
   */
  showFailure() {

    this.onFailure();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#showReady
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @description
   *    Triggers onReady.
   */
  showReady() {

    this.onReady();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#showSuccess
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @description
   *    Triggers onSuccess.
   */
  showSuccess() {

    this.onSuccess();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#submit
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @param {object} paymentMethod A payment method.
   *  @description
   *    Attempts to subscribe using passed payment method and current product ID.
   */
  submit(paymentMethod) {

    this.onSubmit();

    var params = {
      productId: this.product.id,
      paymentMethodGuid: paymentMethod.guid || paymentMethod.paymentMethodGuid
    };

    if (this.promotion) {
      params.promoCode = this.promotion.code;
    }

    return this.SubscriptionsService.create(params)
      .then(() => this.showSuccess())
      .catch(errors => this.showErrors(errors));
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#setPaymentMethod
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @param {array} paymentMethods A list of payment methods.
   *  @description
   *    Determines if the user has a payment method. Triggers onReady.
   */
  setPaymentMethod(paymentMethods) {
    this.paymentMethod = paymentMethods && paymentMethods.length > 0;

    this.onReady();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#applyPromocodeSuccess
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @param {object} promotion Promotion information from a valid promocode
   *  @description
   *    Assign the promotion information to the current scope.
   */
  applyPromocodeSuccess(promotion) {
    this.promotion = promotion;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#applyPromocodeFailure
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @param {array} errors Error messages from invaild promocode
   *  @description
   *    Assign promocode error messages to the current scope.
   */
  applyPromocodeFailure(errors) {
    this.promocodeErrors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeForm#removePromocode
   *  @methodOf Seed.Controllers:SubscribeForm
   *  @description
   *    Callback function for removing promocode from promocode component.
   */
  removePromocode() {
    this.promotion = undefined;
    this.promocodeErrors = undefined;
  }

}

export default SubscribeFormController;
