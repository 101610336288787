import BoomSubscribeFormController from './boom-subscribe-form-controller';

const BoomSubscribeFormComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    onSubmit: '&',
    onSuccess: '&',
    onSubscribeError: '&',
    errors: '<'
  },
  controller: BoomSubscribeFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-subscribe-form/subscribe-form.html'
};

export default BoomSubscribeFormComponent;
