/**
 *  @ngdoc service
 *  @name Seed.Services:StateAuthentication
 *  @module Seed
 *  @requires angular.$transitions
 *  @requires angular.$state
 *  @requires platformjs.user.account
 *  @description
 *    Handles state change callbacks authenticating the current user against the requirement of the target state.
 */
class StateAuthentication {
  static get $inject() {
    return ['$transitions', '$state', 'platformjs.user.account'];
  }
  constructor($transitions, $state, UserAccountService, StateAuthenticationProvider) {
    this.$transitions = $transitions;
    this.$state = $state;
    this.UserAccountService = UserAccountService;
    this.StateAuthenticationProvider = StateAuthenticationProvider;
  }


  /**
   *  @ngdoc method
   *  @name Seed.Services:StateAuthentication#authenticateStateChange
   *  @methodOf Seed.Services:StateAuthentication
   *  @param {object} $transition$ Current transition
   *  @description
   *    Uses the injected $transition$ object to determine the current transition. Compares the targets authenticationParameter
   *    with the current user account and determines if a redirect is necessary.
   */
  authenticateStateChange($transition$) {

    if (typeof $transition$.to()[this.StateAuthenticationProvider.getAuthenticationParameter()] === 'string') {
      if ($transition$.to()[this.StateAuthenticationProvider.getAuthenticationParameter()] !== this.UserAccountService.getType()) {
        return this.$state.target(this.StateAuthenticationProvider.getAuthenticationState());
      }
    } else {
      if (this.UserAccountService.isAnonymous()) {
        return this.$state.target(this.StateAuthenticationProvider.getAuthenticationState(), {
          destinationState: $transition$.to().name,
          destinationParams: $transition$.params()
        });

      }
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:StateAuthentication#requiresAuthentication
   *  @methodOf Seed.Services:StateAuthentication
   *  @param {object} state State object
   *  @description
   *    Returns true if a state requires authentication.
   */
  requiresAuthentication(state) {
    return !!state[this.StateAuthenticationProvider.getAuthenticationParameter()];
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:StateAuthentication#activate
   *  @methodOf Seed.Services:StateAuthentication
   *  @description
   *    Adds the {@link Seed.Services:StateAuthentication#methods_authenticateStateChange authenticateStateChange} callback to 
   *    routes containing the parameter returned by {@link Seed.Services:StateAuthenticationProvider#methods_getAuthenticationParameter getAuthenticationParameter}.
   */
  activate() {

    var criteria = {
      to: (state) => this.requiresAuthentication(state)
    };

    this.$transitions.onStart(criteria, ($transition$) => this.authenticateStateChange($transition$));

  }
}

export default StateAuthentication;
