import ChangePasswordFormController from './change-password-form-controller';

const ChangePasswordFormComponent = {
  bindings: {
    token: '<',
    onError: '&',
    onSubmit: '&',
    onSuccess: '&'
  },
  controller: ChangePasswordFormController,
  controllerAs: 'vm',
  templateUrl: 'components/change-password-form/change-password-form.html'
};

export default ChangePasswordFormComponent;
