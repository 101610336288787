import { formatDateString } from 'utilities/utilities';
/**
 *  @ngdoc controller
 *  @name Boom.Controllers:GiftSuccessView
 *  @module Boom
 *  @requires AnalyticsService
 *  @requires platformjs.storage
 *  @description
 *    Responsible fetching the profile user data after successful signup flow.
 */
class GiftSuccessViewController {
  static get $inject() {
    return ['$state', 'AnalyticsService', 'platformjs.storage'];
  }

  constructor($state, AnalyticsService, StorageService) {
    this.$state = $state;
    this.AnalyticsService = AnalyticsService;
    this.StorageService = StorageService;

    this.email = null;
    this.date = null;
    this.price = null;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftSuccessView#$onInit
   *  @methodOf Boom.Controllers:GiftSuccessView
   *  @description
   *    Logs to Segment on load.
   */
  $onInit() {
    this.$state.go('gifting', {});
    this.AnalyticsService.track('Gift Purchase Confirmation');
    this.hideMobileNav = true;
    this.getGiftPurchaseDetails();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftSuccessView#getGiftPurchaseDetails
   *  @methodOf Boom.Controllers:GiftSuccessView
   *  @description
   *    Looks up LocalStorage for gift purchase
   */
  getGiftPurchaseDetails() {
    let dataForSuccessPage = this.StorageService.get('boom-gift-purchase-details');
    if (dataForSuccessPage) {
      this.email = dataForSuccessPage.email;
      this.date = dataForSuccessPage.date;
      this.price = dataForSuccessPage.price;
    }
  }
}

export default GiftSuccessViewController;

