/**
 *  @name getRange
 *  @params {interger} Start of range
 *  @params {interger} End of range
 *  @returns {array} range of digits in an array
 *  @description
 *    Helper method to get a range of digits in an array
 *  @example
 *    getRange(3, 6)    // [3, 4, 5, 6]
**/
const getRange = (start, end) => {
  let output = [];

  if (!start || !end) {
    return output;
  }

  for (let i = start; i <= end; i++) {
    output.push(i);
  }

  return output;
};

const formatDateString = (date) => {
  if (date) {
    let dates = date.split('-');
    const year = dates.shift();
    dates.push(year);
    return dates.join('/');
  }
  return '';
};

const checkForAnnualPlan = (catalog) => {
  if (catalog && catalog.plans) {
    const basicPlan = catalog.plans.find(plan => plan.name === 'basic');

    if (basicPlan && basicPlan.products) {
      return basicPlan.products.some(product => {
        return product.premiumLevel === '12' &&
          product.descriptors &&
          product.descriptors.hidden !== 'true';
      });
    }
  }

  return false;
};

const capturePlanPrices = (catalog) => {
  const prices = {};

  if (catalog && catalog.plans) {
    const basicPlan = catalog.plans.find(plan => plan.name === 'basic');

    if (basicPlan && basicPlan.products) {
      basicPlan.products.forEach(product => {
        if (product.descriptors && product.descriptors.hidden !== 'true') {
          if (product.premiumLevel === '12') {
            prices.annualPrice = product.price;
            prices.annualMonthlyPrice = (parseFloat(product.price) / 12).toFixed(2);
          } else if (product.premiumLevel === '1') {
            prices.monthlyPrice = product.price;
          }
        }
      });
    }
  }

  return prices;
};

export { getRange, formatDateString, checkForAnnualPlan, capturePlanPrices };
