import SunsetViewController from 'views/sunset/sunset-view-controller';

function SunsetState($stateProvider) {

  $stateProvider.state('sunset', {
    url: '/sunset',
    controller: SunsetViewController,
    controllerAs: 'vm',
    templateUrl: 'views/sunset/sunset-view.html',
    userRequired: false,
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });

}

SunsetState.$inject = ['$stateProvider'];

export default SunsetState;
