/**
  *  @ngdoc filter
  *  @name Boom.Filter:OnlyDecimal
  *  @param {Number} amount currency amount. It takes integer or floating number
  *  @returns {string} decimal digits
  *  @description
  *     Gets two decimal digits from integer / floating number without rounding.
  *     If not number type, it will return empty string by default.
  *
  *  @example
  *     <p>{{ vm.price | onlyDecimal }}</p>
  */

const OnlyDecimalFilter = () => {
  {
    return (amount) => {

      if (typeof amount === 'number') {

        if (amount % 1 === 0 || (amount * 10) % 1 === 0) {
          // if amount is a round integer, add two zeros as decimal digits
          // or if amount has one decimal, add one zero to it
          amount = amount.toFixed(2);
        } else {
          amount = amount.toString();
        }

        // returns only two decimal digits as string
        return amount.substr(amount.indexOf('.') + 1, 2);
      }

      return '';

    };
  }
};

export default OnlyDecimalFilter;
