/**
 *  @ngdoc controller
 *  @name Seed.Controllers:SignupView
 *  @module Seed
 *  @requires angular.$state
 *  @description
 *    Responsible for managing the signup view.
 */
class SignupViewController {
  static get $inject() {
    return ['$state'];
  }
  constructor($state) {
    this.$state = $state;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:SignupView
     *  @returns {array} Error list
     *  @description
     *    List of current login errors.
     */
    this.errors = [];
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SignupView#showSignupFailure
   *  @methodOf Seed.Controllers:SignupView
   *  @param {array} errors Error list
   *  @description
   *    Sets {@link Seed.Controllers:SignupView#properties_errors errors} property.
   */
  showSignupFailure(errors) {

    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SignupView#showSignupSuccess
   *  @methodOf Seed.Controllers:SignupView
   *  @description
   *    Changes state to `subscribe`.
   *
   */
  showSignupSuccess() {

    this.$state.go('subscribe', this.$state.params.destinationParams);

  }
}

export default SignupViewController;
