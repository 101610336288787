/**
 *  @ngdoc controller
 *  @name Boom.Controllers:OpenAppButtonController
 *  @module Boom
 *  @requires platform
 *  @description
 *    Manages button that logs a user out.
 */
class OpenAppButtonController {
  static get $inject() {
    return ['platform'];
  }

  /* istanbul ignore next */
  constructor(platform) {
    this.openAppUrl = platform.kochava.openAppUrl;
  }
}

export default OpenAppButtonController;
