import TermsController from './terms-controller';

function TermsState($stateProvider) {
  $stateProvider.state('terms', {
    parent: 'language',
    url: '/terms',
    templateUrl: 'views/terms/terms-view.html',
    controller: TermsController,
    controllerAs: 'vm',
    ignoreMobileWall: true
  });

}

TermsState.$inject = ['$stateProvider'];

export default TermsState;
