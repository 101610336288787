import ProductsViewController from 'views/products/products-view-controller';

function ProductsState($stateProvider) {

  $stateProvider.state('products', {
    url: '/products',
    controller: ProductsViewController,
    controllerAs: 'vm',
    templateUrl: 'views/products/products-view.html',
    userRequired: false,
    segment: {
      name: 'Products Selection Page'
    },
    params: {
      flowParams: null
    },
    ignoreMobileWall: true
  });

}

ProductsState.$inject = ['$stateProvider'];

export default ProductsState;
