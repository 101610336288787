import SignupButtonController from './signup-button-controller';

const SignupButtonComponent = {
  bindings: {
    destinationState: '<',
    destinationParams: '<'
  },
  controller: SignupButtonController,
  controllerAs: 'vm',
  templateUrl: 'components/signup-button/signup-button.html',
  transclude: true
};

export default SignupButtonComponent;
