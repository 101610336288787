/**
 *  @ngdoc controller
 *  @name Boom.Controllers:CancelViewController
 *  @module Boom
 *  @description
 *    Responsible for displaying Cancel View.
 */
class CancelViewController {
  static get $inject() {
    return [
      'platform',
      'platformjs.user',
      '$sce',
      '$anchorScroll',
      'platformjs.claims',
      '$state'
    ];
  }

  constructor(platform, UserService, $sce, $anchorScroll, ClaimsService, $state) {
    this.platform = platform;
    this.UserService = UserService;
    this.$sce = $sce;
    this.$anchorScroll = $anchorScroll;
    this.ClaimsService = ClaimsService;
    this.$state = $state;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Boom.Controllers:CancelViewController
     *  @returns {array} Error list
     *  @description
     *    List of current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name loading
     *  @propertyOf Boom.Controllers:CancelViewController
     *  @returns {boolean} Current loading state
     *  @description
     *    Current loading state
     */
    this.loading = false;

    /**
     *  @ngdoc property
     *  @name cancelConfirmed
     *  @propertyOf Boom.Controllers:CancelViewController
     *  @returns {boolean} Toggle cancellation message
     *  @description
     *    Toggles between cancellation prompt and cancellation confirmation message
     */
    this.cancelConfirmed = false;

    /**
     *  @ngdoc property
     *  @name surveyURL
     *  @propertyOf Boom.Controllers:CancelViewController
     *  @returns {string} URL of embedded survey in cancel flow
     *  @description
     *    URL to be passed in to the CancelSurvey component
     */
    this.surveyURL = '';

    /**
     *  @ngdoc property
     *  @name redirectURL
     *  @propertyOf Boom.Controllers:CancelViewController
     *  @returns {string} URL of the cancel confirmation page
     *  @description
     *    URL of page to which the user is redirected after they hit the submit button on the cancel survey
     */
    this.redirectURL = '/account/cancel-confirmation';

    this.defaultBaseSurveyURL = platform.surveyLegend.defaultBaseSurveyURL;
    this.spanishBaseSurveyURL = platform.surveyLegend.spanishBaseSurveyURL;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelViewController#$onInit
   *  @methodOf Boom.Controllers:CancelViewController
   *  @description
   *    Constructs the URL of the survey
   */
  $onInit() {
    if (this.speedyCancel()) {
      this.$state.go('account.cancel-confirmation');
    }

    // determine the base survey URL from the user's language
    let baseUrl = this.defaultBaseSurveyURL;
    if (this.UserService.profile && this.UserService.profile.language) {
      if (this.UserService.profile.language === 'es') {
        baseUrl = this.spanishBaseSurveyURL;
      }
    }
    this.surveyURL = this.$sce.trustAsResourceUrl(baseUrl);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelViewController#processCancel
   *  @methodOf Boom.Controllers:CancelViewController
   *  @description
   *    Sends cancellation request.
   */
  processCancel() {
    this.$anchorScroll();
    this.cancelConfirmed = true;
  }

  speedyCancel() {
    const premiumClaim = this.ClaimsService.getClaim('premium');

    if (!premiumClaim._rawProperties || !premiumClaim._rawProperties.operation_nightfall) {
      return false;
    }
    return parseInt(premiumClaim._rawProperties.operation_nightfall) === 1;
  }

}

export default CancelViewController;
