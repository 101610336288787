/**
 *  @ngdoc controller
 *  @name Gates.Controllers:AgeGateFormController
 *  @module Boom
 *  @description
 *    Responsible for Showing the age gate form
 */
class AgeGateFormController {
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateFormController#$onInit
   *  @methodOf Gates.Controllers:AgeGateFormController
   *  @description
   *    Resets answer on init
   */
  $onInit() {
    this.answerModel = '';
  }
}

export default AgeGateFormController;
