/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomPromoCodeController
 *  @module Boom
 *  @requires angular.$state
 *  @description
 *    Responsible for managing the promo view, check promocode and redirect the user.
 */
class BoomPromoCodeController {
  static get $inject() {
    return ['$state', 'platformjs.premiumProfile', '$anchorScroll', 'platformjs.user.account'];
  }

  constructor($state, PremiumProfileService, $anchorScroll, UserAccountService) {

    this.$state = $state;
    this.PremiumProfileService = PremiumProfileService;
    this.promocode = undefined;
    this.submitting = false;
    this.$anchorScroll = $anchorScroll;
    this.UserAccountService = UserAccountService;

    this.onError = this.onError || angular.noop;
    this.onFailure = this.onFailure || angular.noop;
    this.onReady = this.onReady || angular.noop;
    this.onSubmit = this.onSubmit || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  $onInit() {
    this.loggedIn = this.UserAccountService.isRegistered();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPromoCodeController#submit
   *  @methodOf Boom.Controllers:BoomPromoCodeController
   *  @description
   *    Submit a promocode to check if it is valid
   */
  submit() {

    this.submitting = true;

    let config = {
      promoCode: this.promocode
    };

    this.PremiumProfileService.checkPromo(config)
      .then(() => this.checkSuccess())
      .catch((errors) => this.checkFailure(errors))
      .finally(() => this.checkComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPromoCodeController#checkSuccess
   *  @methodOf Boom.Controllers:BoomPromoCodeController
   *  @description
   *    Redirect and pass the vaild promocode to the signup view
   */
  checkSuccess() {
    this.$state.go('signup', {
      code: this.promocode
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPromoCodeController#checkFailure
   *  @methodOf Boom.Controllers:BoomPromoCodeController
   *  @param {array} errors error messages
   *  @description
   *    Display the error response
   */
  checkFailure(errors) {
    this.errors = this.setErrors(errors);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPromoCodeController#checkComplete
   *  @methodOf Boom.Controllers:BoomPromoCodeController
   *  @description
   *    Reset the promocode and the submitting state.
   */
  checkComplete() {
    this.submitting = false;
    this.promocode = '';
    this.$anchorScroll('scroll-top');
  }

  // TODO: remove once https://github.com/DramaFever/seed-www/issues/660 has been merged and seed has been updated in Boom
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPromoCodeController#setErrors
   *  @methodOf Boom.Controllers:BoomPromoCodeController
   *  @param {array} errors error messages
   *  @description
   *    Filters errors and sets appropriate view property to display custom message.
   */
  setErrors(errors) {
    let filteredErrors = [];
    this.previouslyRedeemed = false;
    this.expired = false;
    this.doesNotExist = false;

    errors.forEach((error) => {
      if (error.code === '3013') {
        this.previouslyRedeemed = true;
      } else if (error.code === '3014') {
        this.expired = true;
      } else if (error.code === '3012') {
        this.onError(errors);
        this.doesNotExist = true;
      } else {
        filteredErrors.push(error);
      }
    });

    return filteredErrors;
  }

}

export default BoomPromoCodeController;

