import { loadImage, FallbackConfig } from './fallback-config';

/**
  *  @ngdoc directive
  *  @name Seed.Directive:fallbackBackground
  *  @description
  *    allows us to use fallback and loading images on backgrounds.  This directive supports 3 attributes:
  *
  *    fallback-background:  The image that you want to display
  *    fallback-loader:  A loading image to display when trying to the load the image
  *    fallback-error:  An error image to display if the image fails to load
  *
  *  @example
  *   <div
  *     fallback-background="https://asdf.com/asdf.jpg"
  *     fallback-loader="https://loadingimage.jpg"
  *     fallback-error="https://error-image.jpg"
  *    ></div>
  */
//This is declared outside the method b/c there were some weird scoping issues that only seem to impact the unit tests, not in usage
function fallbackBackground() {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      scope.$watch(() => attrs.fallbackBackground, () => {
        if (FallbackConfig.showLoader) {
          element.addClass('fallback-loading');
          const loaderBackground = (attrs.fallbackLoader) ? `url('${attrs.fallbackLoader}')` : `url('${FallbackConfig.getLoadingImage()}')`;
          element[0].style.backgroundImage = loaderBackground;
        }
        loadImage(attrs.fallbackBackground,
          () => {
            element[0].style.backgroundImage = `url('${attrs.fallbackBackground}')`;
            element.removeClass('fallback-loading');
          },
          () => {
            element.addClass('fallback-error');
            element.removeClass('fallback-loading');
            const errorBackground = (attrs.fallbackError) ? `url('${attrs.fallbackError}')` : `url('${FallbackConfig.getFallbackImage()}')`;
            element[0].style.backgroundImage = errorBackground;
          });
      });
    }
  }
}

export default fallbackBackground;
