import ForgotPasswordFormController from './forgot-password-form-controller';

const ForgotPasswordFormComponent = {
  bindings: {
    onError: '&',
    onSubmit: '&',
    onSuccess: '&'
  },
  controller: ForgotPasswordFormController,
  controllerAs: 'vm',
  templateUrl: 'components/forgot-password-form/forgot-password-form.html'
};

export default ForgotPasswordFormComponent;
