import UpdateProfileViewController from './update-profile-controller';

function AccountPasswordState($stateProvider) {

  $stateProvider.state('account.password', {
    parent: 'account.profile',
    url: '/password',
    controller: UpdateProfileViewController,
    controllerAs: 'vm',
    templateUrl: 'views/profile/update-password-view.html',
    ignoreMobileWall: true,
    dontBreakGateFlow: true
  });

}

AccountPasswordState.$inject = ['$stateProvider'];

export default AccountPasswordState;
