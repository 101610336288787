/**
   *  @ngdoc service
   *  @name Seed.Services:BraintreeService
   *  @module Seed
   *  @description
   *    Exposes the global braintree object as an angular service
   */

class BraintreeService {
  static get $inject() {
    return ['$window'];
  }

  constructor($window) {
    return $window.braintree;
  }
}

export default BraintreeService;
