import MainHeaderController from './main-header-controller';

const MainHeaderComponent = {
  controller: MainHeaderController,
  controllerAs: 'vm',
  bindings: {
    languageKey: '&',
    languageSelectorIsOpen: '='
  },
  templateUrl: 'components/main-header/main-header.html'
};

export default MainHeaderComponent;
