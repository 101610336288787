/**
 *  @ngdoc controller
 *  @name Boom.Controllers:DeviceOfflineViewController
 *  @module Boom
 *  @requires $state
 *  @requires $stateParams
 *  @description
 *    Handles listeners for device online event.
 */
class DeviceOfflineViewController {
  static get $inject() {
    return [
      '$state',
      '$stateParams'
    ];
  }

  constructor($state, $stateParams) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this._detectOnline = this.detectOnline.bind(this);
  }

  $onInit() {
    window.addEventListener('online', this._detectOnline);
  }

  $onDestroy() {
    window.removeEventListener('online', this._detectOnline);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:DeviceOfflineViewController#detectOnline
   *  @methodOf Boom.Controllers:DeviceOfflineViewController
   *  @description
   *    Initiates a $state.go event.
   */
  detectOnline() {
    this.$state.go(this.$stateParams.onlineRedirectStateName || '/');
  }
}

export default DeviceOfflineViewController;
