/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomUpsellPromoController
*  @module Boom
*  @description
*  @requires $location
*  @requires $cookies
*    Responsible for subscribing a user.
*/
class BoomUpsellPromoController {
  static get $inject() {
    return [
      '$location',
      '$cookies',
      'platformjs.catalog',
      'platformjs.premiumProfile',
      'moment',
      'platformjs.storage'
    ];
  }

  /* istanbul ignore next */
  constructor($location, $cookies, CatalogService, PremiumProfileService, moment, StorageService) {
    this.$location = $location;
    this.$cookies = $cookies;
    this.CatalogService = CatalogService;
    this.PremiumProfileService = PremiumProfileService;
    this.moment = moment;
    this.StorageService = StorageService;

    this.expire = null;
    this.hasPlans = false;
    this.loaded = false;
    this.promo = false;
    this.analytics = 'Gift Plan Option Submitted';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellPromoController#$onInit
   *  @methodOf Boom.Controllers:BoomUpsellPromoController
   *  @description
   *    Loads catalog.
   */
  $onInit() {
    const promotionalCatalog = this.StorageService.get('promotional-catalog');

    let catalogConfig = {};

    if (promotionalCatalog) {
      catalogConfig.promotion = promotionalCatalog;
    }

    this.checkPromo();
    this.CatalogService.list(catalogConfig)
      .then(catalog => this.processCatalog(catalog))
      .then(() => this.checkForPromoCode())
      .finally(() => {
        this.loaded = true;
      });
  }

  processCatalog(catalog) {
    this.catalog = catalog;
    this.hasPlans = catalog.plans.length > 0;
    this.tryPrice = this.getPromoPrice() || 'Free';
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomUpsellPromoController#$getPromoPrice
  *  @methodOf Boom.Controllers:BoomUpsellPromoController
  *  @description
  *    Gets a promo price for the catalog
  */
  getPromoPrice() {
    let promoPrice;
    if (this.hasPlans) {
      const promoProduct = this.catalog.plans[0].products.find(element => !!element.descriptors.promo_price);
      promoPrice = promoProduct && promoProduct.descriptors.promo_price;
    }
    return promoPrice;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomUpsellPromoController#checkPromo
  *  @methodOf Boom.Controllers:BoomUpsellPromoController
  *  @description
  *    Checks for promo query and sets cookie
  */
  checkPromo() {
    let promo = this.$location.search().promo;

    let date = new Date();
    date.setTime(date.getTime() + (5 * 60 * 1000));

    this.$cookies.put('subscribe-promo', promo, {
      expires: date
    });

  }
  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomUpsellPromoController#checkForPromoCode
  *  @methodOf Boom.Controllers:BoomUpsellPromoController
  *  @description
  *    Checks for query string or cookie for promo code
  */
  checkForPromoCode() {
    let cookie = this.$cookies.get('subscribe-promo');

    if (cookie) {
      this.isPromoValid(cookie)
        .then((response) => this.checkSuccess(response));
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellPromoController#isPromoValid
   *  @methodOf Boom.Controllers:BoomUpsellPromoController
   *  @description
   *    Returns a check for promo code validity
   */
  isPromoValid(promo) {
    let config = {
      promoCode: promo
    };

    return this.PremiumProfileService.checkPromo(config);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellPromoController#checkSuccess
   *  @methodOf Boom.Controllers:BoomUpsellPromoController
   *  @description
   *    Sets promoId property and turns it into a string 
   */
  checkSuccess(promo) {
    this.promo = promo;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellPromoController#getExpiration
   *  @methodOf Boom.Controllers:BoomUpsellPromoController
   *  @description
   *    Returns 7 days expiration for upsell screen promo product
   */
  getExpiration() {
    return this.moment(new Date()).add(7, 'days').format('MM/DD/YYYY');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellPromoController#isPremiumView

   *  @methodOf Boom.Controllers:BoomUpsellPromoController
   *  @param {object} product Product object
   *  @description
   *    Checks for premium view
   */
  isPremiumView() {
    return this.$location.path() === '/premium';
  }

}

export default BoomUpsellPromoController;
