/**
 *  @ngdoc service
 *  @name Seed.Service:GoogleAnalyticsService
 *  @description
 *    Service for communicating with Google Analytics
 */
class GoogleAnalyticsService {
  static get $inject() {
    return ['$analytics', 'platformjs.user'];
  }

  constructor($analytics, UserService) {

    this.$analytics = $analytics;
    this.UserService = UserService;
  }

  setUserID() {

    if (this.UserService.profile && this.UserService.profile.guid) {
      this.$analytics.setUsername(this.UserService.profile.guid);
    }
  }
}

export default GoogleAnalyticsService;
