/**
 *  @ngdoc controller
 *  @name Boom.Controllers:UpdateProfileForm
 *  @module Boom
 *  @requires platformjs.user.profile
 *  @requires platformjs.user.login
 *  @requires angular.$state 
 *  @description
 *    Responsible for update up a users email or password.
 */
class UpdateProfileFormController {
  static get $inject() {
    return ['platformjs.user.profile', 'platformjs.user.login', 'platformjs.user'];
  }

  /* istanbul ignore next */
  constructor(UserProfileService, UserLoginService, UserService) {
    this.UserProfileService = UserProfileService;
    this.UserLoginService = UserLoginService;
    this.UserService = UserService;
    this.screenProfile = {};
    this.passwordVisibility = {
      new: false,
      old: false
    };
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:UpdateProfileForm#onUpdateError
   *  @methodOf Boom.Controllers:UpdateProfileForm
   *  @param {array} errors Errors
   *  @description
   *   Called on failure of profile or password update request.
   */
  onUpdateError(errors) {
    let filteredErrors = [];
    this.invalidPasswordError = false;
    this.invalidMinLength = false;
    this.emailAlreadyExists = false;

    errors.forEach((error) => {
      switch (error.code) {
        case '1009':
          this.invalidPasswordError = true;
          break;
        case '1008':
          this.emailAlreadyExists = true;
          break;
        case '1001':
          this.invalidMinLength = true;
          break;
        default:
          filteredErrors.push(error);
      }
      this.errors = filteredErrors;
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:UpdateProfileForm#onUpdateFinally
   *  @methodOf Boom.Controllers:UpdateProfileForm
   *  @description
   *   Called at the end of profile or password update request.
   */
  onUpdateFinally() {

    this.submitting = false;

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:UpdateProfileForm#togglePasswordVisibility
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Toggle to show password as text instead of password.
   */
  togglePasswordVisibility(version) {
    this.passwordVisibility[version] = !this.passwordVisibility[version];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:UpdateProfileForm#onUpdateSuccess
   *  @methodOf Boom.Controllers:UpdateProfileForm
   *  @description
   *   Called on success of profile or password update request.
   */
  onUpdateSuccess() {

    this.success = true;

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ProfileForm#submit
   *  @methodOf Boom.Controllers:UpdateProfileForm
   *  @description
   *   Submits form data to update profile or password.
   */
  submit() {

    this.errors = [];
    this.submitting = true;

    const config = {
      email: this.screenProfile.newEmail
    };
    const newEmail2 = this.screenProfile.newEmail2;

    const oldPassword = this.screenProfile.password;
    const newPassword = this.screenProfile.newPassword;

    if (config.email) {

      this.UserProfileService.update(config)
        .then((config) => {
          this.onUpdateSuccess();
          this.emailSuccess = config.email;
        })
        .catch(errors => this.onUpdateError(errors))
        .finally(() => this.onUpdateFinally());

    } else if (this.screenProfile.newPassword) {
      this.UserLoginService.updatePassword(newPassword, oldPassword)
        .then(() => this.onUpdateSuccess())
        .catch(errors => this.onUpdateError(errors))
        .finally(() => this.onUpdateFinally());
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ProfileForm#validateMatch
   *  @methodOf Boom.Controllers:UpdateProfileForm
   *  @param {string} new input value
   *  @param {string} new input value confirmation
   *  @param {string} type of input value
   *  @description
   *   Validates matching input fields.
   */
  validateMatch(newValue, newValue2, type) {

    if (newValue !== newValue2) {

      this.errors = [{
        message: `New ${type}s do not match.`
      }];

      this.submitting = false;
      return false;

    } else {

      return true;

    }
  }
}

export default UpdateProfileFormController;
