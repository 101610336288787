import BoomPromoCodeController from './boom-promocode-controller';

const PromoCodeComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    onSubmit: '&',
    onSuccess: '&',
    placeholder: '<'
  },
  controller: BoomPromoCodeController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-promocode/boom-promocode.html'
};

export default PromoCodeComponent;
