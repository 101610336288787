export default class BracketController {
  static get $inject() {
    return ['$window', '$state', 'platformjs.storage', 'platformjs.application', 'platformjs.metadata'];
  }
  constructor($window, $state, StorageService, AppConfig, MetadataService) {
    this.$window = $window;
    this.$state = $state;
    this.StorageService = StorageService;
    this.AppConfig = AppConfig;
    this.MetadataService = MetadataService;
    this.supportedCountryCodes = ['PR', 'UM', 'US', 'AS', 'GU', 'VI'];
  }

  $onInit() {
    this.updateMetadata();
    this.AppConfig.get(true).finally((config) => {
      if (!this.inSupportedCountry()) {
        this.$state.go('upsell', null, {
          location: 'replace'
        });
        return;
      }
      this.createThirdPartyScript();
    });
  }

  inSupportedCountry() {
    const config = this.StorageService.get('application');
    return this.supportedCountryCodes.indexOf(config.countryCode) > -1;
  }

  createThirdPartyScript() {
    const s = this.$window.document.createElement('script');
    s.src = `//bracketpro.omnigonprosuite.com/js/app/omnigon-brackets-embed.js`;
    s.onload = this.initBracket.bind(this);
    this.$window.document.body.appendChild(s);
  }

  initBracket() {
    this.$window.embedOmnigonBrackets({
      debug: false,
      fixtures: false,
      container: 'bracket-15592-20131250',
      bracketId: 20131250,
      clientId: '15592',
      langCode: 'en',
      url: '//bracketpro.omnigonprosuite.com/'
    });
  }

  updateMetadata() {
    const title = 'Boomerang | Best Cartoon Bracket';
    const description = 'Vote for your favorite cartoon in the Boomerang Best Cartoon Bracket!';
    const keywords = 'Cartoon,Vote,bracket,championship,final,semi-final,semifinal,tournament,animation,cartoons,Hanna Barbera,Chuck Jones,Boomerang,Cartoon Network,Smurfs,Wacky Races,Wizard of Oz,Looney Tunes,Acme,Bugs Bunny,Elmer Fudd,Magilla Gorilla,Atom Ant,Daffy Duck,Taz,Barney Bear,Yogi Bear,Spooky Space Kook,Dick Dastardly,Penelope Pitstop,Wile E Coyote,Road Runner,Flint Lockwood,Dr. Quest,Tom,Popeye,Papa Smurf,Dorothy,Wilhelmina,Dynamite,Acme Anvil,Lizardman,Jonny Quest,Courage the Cowardly Dog,Droopy,Camp Lazlo,The Flintstones,The Jetsons,Scooby-Doo,Scooby,Scoobydoo';
    const image = 'images/views/bracket-fb-thumbnail.jpg';

    const socialTitle = 'I voted for my favorite cartoon in the Boomerang Best Cartoon Bracket!';
    const socialDescription = 'Bugs Bunny? Tom and Jerry? Scooby? My vote is in for the best cartoon. What\'s yours?';

    this.MetadataService.setData({
      image,
      title,
      description,
      keywords,
      socialTitle,
      socialDescription
    });
  }
}
