/**
  *  @ngdoc directive
  *  @name Boom.Directive:stateClasses
  *  @param {object} transition the ui router transition object
  *  @param {object} state the state object  
  *  @description
  *    Adds a -container class with the state name prepended to the site-container div.
  *
  *  @example
  *   <body
       state-classes>
      </body>  
  */

function stateClasses($transitions, $state) {
  return {
    link: function(scope, element) {
      let unbind;
      scope.$on('$destroy', () => {
        unbind();
      });

      unbind = $transitions.onFinish({}, (transition) => {
        updateStateClass(transition.to().name, element);
      });

      updateStateClass($state.current.name, element);
    }
  };
}

let currentStateClass = null;
function updateStateClass(newClass, element, when) {
  if (currentStateClass) {
    element.removeClass(currentStateClass);
  }
  element.addClass(`${newClass}-container`);
  currentStateClass = `${newClass}-container`;
}

stateClasses.$inject = ['$transitions', '$state'];

export default stateClasses;
