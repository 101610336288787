import BoomPaymentFormController from './boom-payment-form-controller';

const BoomPaymentFormComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    onSubmit: '&',
    onSuccess: '&',
    options: '<',
    token: '<',
    billingAgreementDescription: '<',
    product: '<',
    next: '<',
    errors: '<',
    paypalErrors: '<',
    arlConsentText: '='
  },
  controller: BoomPaymentFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-payment-form/boom-payment-form.html',
  transclude: {
    paymentFormButtonText: '?paymentFormButtonText'
  }
};

export default BoomPaymentFormComponent;
