import PaymentSelectionController from './payment-selection-controller';

const PaymentSelectionComponent = {
  bindings: {
    onReady: '&',
    onFailure: '&',
    onPaymentMethodSelect: '&'
  },
  controller: PaymentSelectionController,
  controllerAs: 'vm',
  templateUrl: 'components/payment-selection/payment-selection.html'
};

export default PaymentSelectionComponent;
