import CancelSurveyController from './cancel-survey-controller';

const CancelSurveyComponent = {
  bindings: {
    surveyURL: '<surveyUrl',
    redirectURL: '<redirectUrl'
  },
  controller: CancelSurveyController,
  controllerAs: 'vm',
  templateUrl: 'components/cancel-survey/cancel-survey.html'
};

export default CancelSurveyComponent;
