export default class TermsController {
  static get $inject() {
    return ['platformjs.agreements', '$sce', 'platformjs.user', 'UrlLanguage', '$location', '$translate'];
  }

  constructor(AgreementsService, $sce, User, UrlLanguage, $location, $translate) {
    this.AgreementsService = AgreementsService;
    this.$sce = $sce;
    this.User = User;
    this.UrlLanguage = UrlLanguage;
    this.$location = $location;
    this.$translate = $translate;

    this.loading = false;
  }

  $onInit() {
    if (!this.User.profile) {
      this.User.setProfile({});
    }
    // Use the lang param from the language state to determine if we want to load a localized version
    //platform will check the user profile, so we want to set it before making the call
    const originalLanguage = this.UrlLanguage.activeLanguage;
    const overrideLanguage = this.$location.search().lang;

    this.loading = true;

    if (overrideLanguage) {
      this.$translate.use(overrideLanguage);
      this.User.setProfile({
        deviceLanguage: overrideLanguage
      });
    }

    this.AgreementsService.getTerms({
      flavor: 'www'
    })
      .then(this.showTerms.bind(this))
      .catch(this.showError.bind(this))
      .finally(this.onLoaded.bind(this))
      .finally(() => {
        //set the language back after making the call so we dont mess with any other requests
        this.User.setProfile({
          deviceLanguage: originalLanguage
        });
      });
  }

  showTerms(terms) {
    if (terms.contentType === 'plain') {
      terms.text = terms.text.replace(new RegExp('\r?\n', 'g'), '<br />');
    }
    this.terms = this.$sce.trustAsHtml(terms.text);
  }

  showError(err) {
    this.errors = err;
  }

  onLoaded() {
    this.loading = false;
  }
}
