import BoomSignupViewController from 'views/signup/boom-signup-view-controller';

function SignupState($stateProvider) {

  $stateProvider.state('signup', {
    url: '/signup',
    controller: BoomSignupViewController,
    controllerAs: 'vm',
    templateUrl: 'views/signup/signup-view.html',
    parent: 'register',
    params: {
      premiumLevel: null,
      product: null
    },
    ageGate: {
      fullscreen: false,
      failState: 'home-upsell'
    },
    segment: {
      name: 'Account Creation Page'
    },
    ignoreMobileWall: true
  });

}

SignupState.$inject = ['$stateProvider'];

export default SignupState;
