class ApplicationController {
  static get $inject() {
    return ['platformjs.user', 'platformjs.metadata'];
  }

  constructor(UserService, MetaDataService) {
    this.UserService = UserService;
    this.MetaDataService = MetaDataService;
  }

  meta() {
    return this.MetaDataService.getData();
  }

  getProfile() {
    if (this.UserService.profile) {
      return this.UserService.profile;
    }

  }
}

export default ApplicationController;