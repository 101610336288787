import LogoutButtonController from 'components/logout-button/logout-button-controller';
import { setPlatform3AuthToken } from '../../platform3';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomLogoutButtonController
 *  @module Boom
 *  @requires angular.$state
 *  @require $analytics
 *  @requires platformjs.user.login
 *  @requires platformjs.claims
 *  @description
 *    Manages button that logs a user out.
 */
class BoomLogoutButtonController extends LogoutButtonController {
  static get $inject() {
    return ['$state', '$analytics', 'platformjs.user.login', 'platformjs.claims', '$rootScope', 'platformjs.storage'];
  }

  /* istanbul ignore next */
  constructor($state, $analytics, LoginService, ClaimsService, $rootScope, StorageService) {
    super($state, $analytics, LoginService);
    this.ClaimsService = ClaimsService;
    this.StorageService = StorageService;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomLogoutButtonController#onLogoutSuccess
  *  @methodOf Boom.Controllers:BoomLogoutButtonController
  *  @description
  *    Fires a logout analytics event then refreshes the users claims, and finally redirects to home page.
  */
  onLogoutSuccess() {
    setPlatform3AuthToken(null);

    const _this = this;
    const redirectState = _this.redirectState || 'home';

    _this.$analytics.eventTrack('Logout', {
      category: 'User'
    });

    const redirect = () => {
      _this.$state.go(redirectState);
    };

    //remove the language cookie
    this.StorageService.remove('user-language');

    _this.ClaimsService.get().then(redirect, redirect);
  }
}

export default BoomLogoutButtonController;
