import BoomForgotPasswordFormController from './forgot-password-form-controller';

const BoomForgotPasswordFormComponent = {
  bindings: {
    onError: '&',
    onSubmit: '&',
    onSuccess: '&'
  },
  controller: BoomForgotPasswordFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-forgot-password-form/forgot-password-form.html'
};

export default BoomForgotPasswordFormComponent;
