import ThatsAllFolksViewController from 'views/thatsallfolks/thatsallfolks-view-controller';

function ThatsAllFolksState($stateProvider) {

  $stateProvider.state('thatsallfolks', {
    url: '/thatsallfolks',
    controller: ThatsAllFolksViewController,
    controllerAs: 'vm',
    templateUrl: 'views/thatsallfolks/thatsallfolks-view.html',
    userRequired: false,
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });

}

ThatsAllFolksState.$inject = ['$stateProvider'];

export default ThatsAllFolksState;
