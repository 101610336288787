import BoomPromoCodeController from './boom-promocode-standalone-controller';

const PromoCodeStandaloneComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    onSubmit: '&',
    onSuccess: '&',
    placeholder: '<'
  },
  controller: BoomPromoCodeController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-promocode-standalone/boom-promocode-standalone.html'
};

export default PromoCodeStandaloneComponent;
