/**
 *  @ngdoc controller
 *  @name Boom.Controllers:GiftPurchaseView
 *  @module Boom
 *  @requires platformjs.catalogV2
 *  @requires platformjs.user.login
 *  @requires platformjs.gifting
 *  @requires platformjs.storage
 *  @requires $state
 *  @requires $timeout
 *  @requires platformjs.paymentmethodsV2
 *  @requires $q
 *  @description
 *    Responsible for managing the gift purchase view.
 */
class GiftPurchaseViewController {
  static get $inject() {
    return [
      'platformjs.catalogV2',
      'platformjs.user.login',
      'platformjs.gifting',
      'platformjs.storage',
      '$state',
      '$timeout',
      'platformjs.paymentmethodsV2',
      '$q'
    ];
  }

  constructor(
    CatalogServiceV2,
    LoginService,
    GiftingService,
    StorageService,
    $state,
    $timeout,
    PaymentMethodsV2Service,
    $q) {

    this.CatalogServiceV2 = CatalogServiceV2;
    this.LoginService = LoginService;
    this.GiftingService = GiftingService;
    this.StorageService = StorageService;
    this.PaymentMethodsV2Service = PaymentMethodsV2Service;
    this.$q = $q;

    this.$state = $state;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.$state.go('gifting');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftPurchaseView#showGiftingCatalog
   *  @methodOf Boom.Controllers:GiftPurchaseView
   *  @description
   *    Finds the gift product in a set of plans/products returned from the Catalog service
   *    and exposes it on the instance
   */
  showGiftingCatalog(catalog) {
    catalog.plans.forEach((products) => {
      products.products.forEach((product) => {
        if (product.isGift) {
          this.giftProduct = product;
        }
      });
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftPurchaseView#createGiftWithPaymentMethod
   *  @methodOf Boom.Controllers:GiftPurchaseView
   *  @description
   *    takes data from the payment method form and creates a gift
   */
  createGiftWithPaymentMethod(giftAndPaymentData) {
    this.creatingGift = true;
    const createGiftParams = giftAndPaymentData.giftInfo;

    createGiftParams.paymentmethodGuid = giftAndPaymentData.paymentMethod.guid;
    createGiftParams.productId = this.giftProduct.id;

    this.pollTillPaymentMethodIsReady(giftAndPaymentData.paymentMethod.guid)
      .then(() => {
        this.GiftingService.create(createGiftParams)
          .then(this.createGiftedAccount.bind(this))
          .catch(this.showErrors.bind(this));
      })
      .catch(this.showErrors.bind(this));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftPurchaseView#pollTillPaymentMethodIsReady
   *  @methodOf Boom.Controllers:GiftPurchaseView
   *  @description
   *    Makes a request to get a payment method and generates a promise or passes through an already
   *    existing promise to the method for checking if the payment method is ready to use
   */
  pollTillPaymentMethodIsReady(paymentMethodGuid, deferred) {
    if (!deferred) {
      deferred = this.$q.defer();
    }

    this.PaymentMethodsV2Service.get(paymentMethodGuid)
      .then((paymentMethod) => {
        this.checkPaymentMethodReadyToUse(paymentMethod, deferred);
      })
      .catch((err) => deferred.reject(err));

    return deferred.promise;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftPurchaseView#checkPaymentMethodReadyToUse
   *  @methodOf Boom.Controllers:GiftPurchaseView
   *  @description
   *    Checks if a payment method has entered "accepted" status.  If it has not, continues
   *    to poll the endpoint
   */
  checkPaymentMethodReadyToUse(paymentMethod, deferred) {
    if (paymentMethod.status === 'accepted') {
      deferred.resolve(paymentMethod);
    } else if (paymentMethod.status === 'rejected' || paymentMethod.status === 'disabled') {
      deferred.reject([{
        code: '3044',
        message: 'Invalid payment method'
      }]);
    } else {
      //the payment method is not ready yet, wait a second then lets try again
      this.$timeout(() => {
        this.pollTillPaymentMethodIsReady(paymentMethod.paymentMethodGuid, deferred);
      }, 1000);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftPurchaseView#createGiftedAccount
   *  @methodOf Boom.Controllers:GiftPurchaseView
   *  @description
   *    takes a gift and creates a corresponding account
   */
  createGiftedAccount(gift) {
    this.LoginService.register({
      email: gift.receiverEmail,
      requiresPasswordReset: true
    })
      .then((giftedAccount) => {
        this.redirectToGiftSuccess({
          email: gift.receiverEmail,
          date: gift.premiumStartDate,
          price: this.giftProduct.price
        });
      })
      .catch(this.showErrors.bind(this));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftPurchaseView#redirectToGiftSuccess
   *  @methodOf Boom.Controllers:GiftPurchaseView
   *  @description
   *    passes data to the gift success page for showing the confirmation and sets storage for gift purchase.
   */
  redirectToGiftSuccess(dataForSuccessPage) {
    this.creatingGift = false;
    this.StorageService.set('boom-gift-purchase-details', dataForSuccessPage);
    this.$state.go('purchaseconfirmation', dataForSuccessPage);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftPurchaseView#showErrors
   *  @methodOf Boom.Controllers:GiftPurchaseView
   *  @description
   *    exposes errors on the instance
   */
  showErrors(err) {
    this.creatingGift = false;
    this.errors = err;
  }
}

export default GiftPurchaseViewController;
