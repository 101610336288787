/**
*  @ngdoc controller
*  @name Boom.Controllers:GiftController
*  @module Boom
*  @requires platformjs.catalog
*  @description
*    Responsible for showing list of catalog.
*/
class GiftController {
  static get $inject() {
    return ['$location', '$state', '$window', 'platformjs.catalog', 'platform'];
  }

  constructor($location, $state, $window, CatalogService, platform) {
    this.$location = $location;
    this.$state = $state;
    this.$window = $window;
    this.CatalogService = CatalogService;
    this.platform = platform;

    this.hasPlans = false;
    this.loaded = false;
    this.path = `${this.$location.protocol()}://${platform.watchDomain.url}`;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftController#$onInit
   *  @methodOf Boom.Controllers:GiftController
   *  @description
   *    Loads catalog.
   */
  $onInit() {
    this.$window.location.replace(this.path);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:GiftController#processCatalog
   *  @methodOf Boom.Controllers:GiftController
   *  @description
   *    Sets catalog.
   */
  processCatalog(catalog) {
    this.catalog = catalog;
    this.hasPlans = catalog.plans.length > 0;
  }
}

export default GiftController;
