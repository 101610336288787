ConfigLanguagesFunction.$inject = [
  '$windowProvider',
  '$translateProvider',
  '$translatePartialLoaderProvider',
  'UrlLanguageProvider'
];

function ConfigLanguagesFunction(
  $windowProvider,
  $translateProvider,
  $translatePartialLoaderProvider,
  UrlLanguageProvider
) {

  let $window = $windowProvider.$get();

  // Add translation particals
  $translatePartialLoaderProvider.addPart('all');

  //get the device language
  let language = $window.navigator.language.toLowerCase();
  if (language !== 'zh-cn') {
    // only get the first 2 letter code
    language = language.substr(0, 2);
  }

  //override with a cookie language if it is set
  angular.injector(['ngCookies']).invoke(['$cookies', function($cookies) {
    //we use the user- prefix so that platform doesnt blow this away
    const cookieLanguage = $cookies.getObject('user-language');
    if (cookieLanguage) {
      language = cookieLanguage;
    }
  }]);

  // specify which language .json we are going to get
  $translateProvider.preferredLanguage(language);
  $translateProvider.useSanitizeValueStrategy('escape');

  UrlLanguageProvider.addSupportedLanguages([
    {
      urlCode: 'en',
      languageCode: 'en'
    },
    {
      urlCode: 'es',
      languageCode: 'es'
    }
  ]);
  UrlLanguageProvider.setDefaultLanguage('en');
  UrlLanguageProvider.setActiveLanguage(language);
}

export default ConfigLanguagesFunction;
