/**
 *  @ngdoc controller
 *  @name Seed.Controllers:ProductSelection
 *  @module Seed
 *  @requires angular.$state
 *  @requires platformjs.catalog
 *  @description
 *    Responsible for loading and selecting products from the catalog.  
 */
class ProductSelectionController {
  static get $inject() {
    return ['$state', 'platformjs.catalog'];
  }

  constructor($state, CatalogService) {
    this.$state = $state;
    this.CatalogService = CatalogService;

    /**
     *  @ngdoc property
     *  @name product
     *  @propertyOf Seed.Controllers:ProductSelection
     *  @returns {object} Selected product
     *  @description
     *    Currently selected product.
     */
    this.product = null;

    /**
     *  @ngdoc property
     *  @name products
     *  @propertyOf Seed.Controllers:ProductSelection
     *  @returns {object} Product lookup object
     *  @description
     *    Lookup object of all products by ID.
     */
    this.products = null;

    /**
     *  @ngdoc property
     *  @name products
     *  @propertyOf Seed.Controllers:ProductSelection
     *  @returns {number} A product ID.
     *  @description
     *    Suggested product ID, gotten from $state.params
     */
    this.suggestedProductId = $state.params.pid;

    this.onFailure = this.onFailure || angular.noop;
    this.onProductSelect = this.onProductSelect || angular.noop;
    this.onReady = this.onReady || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProductSelection#$onInit
   *  @methodOf Seed.Controllers:ProductSelection
   *  @description
   *    Loads catalog.
   */
  $onInit() {

    this.CatalogService.list()
      .then(catalog => this.showCatalog(catalog), errors => this.showFailure(errors));

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProductSelection#selectProduct
   *  @methodOf Seed.Controllers:ProductSelection
   *  @param {object} product A product.
   *  @description
   *    Sets {@link Seed.Controllers:ProductSelection#properties_selectedProduct selectedProduct} to the passed product.
   */
  selectProduct(product) {

    this.product = product;
    this.onProductSelect({
      product: product
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProductSelection#showCatalog
   *  @methodOf Seed.Controllers:ProductSelection
   *  @param {object} catalog Catalog object.
   *  @description
   *    Displays products and selects initial product.
   */
  showCatalog(catalog) {

    this.products = catalog.productsLookup();

    this.selectProduct(this.products[this.suggestedProductId || catalog.defaultProductId]);
    this.onReady();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProductSelection#showFailure
   *  @methodOf Seed.Controllers:ProductSelection
   *  @param {array} errors Errors list.
   *  @description
   *    Displays errors.
   */
  showFailure(errors) {

    this.errors = errors;
    this.onFailure();

  }
}

export default ProductSelectionController;
