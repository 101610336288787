import { checkForAnnualPlan, capturePlanPrices } from 'utilities/utilities';

/**
*  @ngdoc controller
*  @name Boom.Controllers:HomeUpsellViewController
*  @module Boom
*  @requires $anchorScroll
*  @requires AnalyticsService
*  @requires platform
*  @requires platformjs.storage
*  @requires platformjs.metadata
*  @requires platformjs.user.account
*  @requires detectjs
*  @requires $location
*  @requires $state
*  @requires $timeout
*  @requires $window
*  @description
*    Mobile landing page
*/
class HomeUpsellViewController {

  static get $inject() {
    return [
      '$anchorScroll',
      'AnalyticsService',
      'platform',
      'platformjs.storage',
      'platformjs.metadata',
      'platformjs.user.account',
      'detectjs',
      '$location',
      '$state',
      '$timeout',
      '$window',
      'platformjs.catalog'
    ];
  }

  constructor($anchorScroll, AnalyticsService, platform, StorageService, MetadataService, UserAccountService, detectjs, $location, $state, $timeout, $window, CatalogService) {
    this.$anchorScroll = $anchorScroll;
    this.AnalyticsService = AnalyticsService;
    this.platform = platform;
    this.StorageService = StorageService;
    this.detectjs = detectjs;
    this.MetadataService = MetadataService;
    this.$location = $location;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$window = $window;
    this.CatalogService = CatalogService;
    this.UserAccountService = UserAccountService;
    this.path = `${this.$location.protocol()}://${this.platform.watchDomain.url}`;
    this.loaded = false;
    this.characters = [
      {
        img: 'Scooby',
        content: []
      },

      {
        img: 'bugs',
        content: []
      },

      {
        img: 'jerry',
        content: []
      }
    ];
  }
  /*
      this.path =
        this.platform.watchDomain && this.UserAccountService.isRegistered()
          ? `${this.$location.protocol()}://${this.platform.watchDomain.url}`
          : '/';
  */
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:HomeUpsellViewController#$onInit
   *  @methodOf Boom.Controllers:HomeUpsellViewController
   *  @description
   *    Sets Analytics page to 'Mobile Landing' and clears the promotional catalog cookie
   */
  $onInit() {
    if (this.UserAccountService.isRegistered()) {
      this.$window.location.replace(this.path);
    } else {
      this.CatalogService.list()
        .then(catalog => this.formatPagePerCatalog(catalog))
        .finally(() => {
          this.loaded = true;
          this.updateMetadata();
          this.StorageService.remove('promotional-catalog');
        });
    }
  }

  formatPagePerCatalog(catalog) {
    this.hasDefaultAnnualPlan = checkForAnnualPlan(catalog);
    this.prices = capturePlanPrices(catalog);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:HomeUpsellViewController#scrollToTop
   *  @methodOf Boom.Controllers:HomeUpsellViewController
   *  @description
   *    Scrolls to the top using $anchorScroll
   */
  scrollToTop() {
    this.$anchorScroll();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:HomeUpsellViewController#getDeviceType
   *  @methodOf Boom.Controllers:HomeUpsellViewController
   *  @description
   *    Returns a device type based on user agent string data
   */
  getDeviceType() {
    const ua = this.detectjs.parse(navigator.userAgent);
    const deviceType = ua.device.type.toLowerCase();

    if (deviceType === 'desktop' && ['ios', 'android'].includes(ua.os.family.toLowerCase())) {
      return 'tablet';
    }
    return deviceType;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:HomeUpsellViewController#router
   *  @methodOf Boom.Controllers:HomeUpsellViewController
   *  @description
   *    Route to specified state
   */
  router(template, state) {
    let flowParams = [{
      'destination': template,
      'options': {
        'location': 'replace'
      }
    }];

    this.$state.go(state, {
      flowParams
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:HomeUpsellViewController#updateMetadata
   *  @methodOf Boom.Controllers:HomeUpsellViewController
   *  @description
   *    Populates metadata
   */
  updateMetadata() {
    const title = 'Boomerang | Full Episodes of Your Family’s Favorite Cartoons';
    const description = 'Boomerang has full episodes of all your favorite cartoons all in one place! Your family will love watching classic cartoon shows like Looney Tunes, Tom and Jerry, The Flintstones, Yogi Bear, and so many more.';
    const image = 'images/social/default_share.png';

    this.MetadataService.setData({
      image,
      title,
      description
    });
  }
}

export default HomeUpsellViewController;
