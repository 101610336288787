import BoomRedemptionFormController from './boom-redemption-form-controller';

const BoomRedemptionFormComponent = {
  bindings: {
    token: '<',
    start: '<',
    end: '<',
    email: '<',
    onError: '&',
    onSuccess: '&'
  },
  controller: BoomRedemptionFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-redemption-form/boom-redemption-form.html'
};

export default BoomRedemptionFormComponent;
