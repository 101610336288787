import Platform3 from 'platform.js3';

const instance = new Platform3({
  api: {
    baseURL: '/'
  }
});

/**
 * @param config The config fetched from the server that needs to be persisted
 * Stores platform config in local storage
 */
function persistPlatformConfig(config) {
  try {
    window.localStorage.setItem('pjs-application', JSON.stringify(config));
  } catch (e) {
    //something went very wrong, or the users local storage limit has been
    //hit, this shouldn't ever happen, but we want to make sure we still
    //catch it just in case.
  }
}

/**
 * @param config
 * @param config.consumerSecret The consumer secret fetched via the api consumer call
 * @param config.artSourceUrl The art source url fetched via the api consumer call
 * @param config.userToken The user's auth token from the dc app in local storage
 *
 * Configures the platform instance
 */
export function configurePlatform(config) {
  // we can leave the base url as `/` so api calls are made relative
  instance.config.consumerSecret = config.consumerSecret;
  instance.config.artSourceUrl = config.artSourceUrl;
  instance.http.setConsumerSecret(config.consumerSecret);
  instance.setArtSourceUrl(config.artSourceUrl);
  // set the user token so we can make API calls
  setPlatform3AuthToken(config.userToken);

  persistPlatformConfig(config);
}

export function setPlatform3AuthToken(token) {
  instance.http.setAuthorizationToken(token);
}

export default instance;
