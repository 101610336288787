/**
*  @ngdoc controller
*  @name Boom.Controllers:UpdatePaymentViewController
*  @module Boom
*  @description
*    Responsible for managing the update payment view.
*/
class UpdatePaymentViewController {

  static get $inject() {
    /* istanbul ignore next */
    return ['ProgressBarService', 'platformjs.user.profile'];
  }

  /* istanbul ignore next */
  constructor(ProgressBarService, UserProfileService) {
    //    super($state, ProgressBarService);
    this.UserProfileService = UserProfileService;
  }

  $onInit() {
    // Implement engineering details later.
    // console.log('UpdatePaymentViewController loaded')
  }
}

export default UpdatePaymentViewController;