function HelpRedirectState($urlRouterProvider) {

  $urlRouterProvider.when('/help', ['$window', function($window) {
    $window.open('https://boomerang.zendesk.com', '_self');
    return true;
  }]);

}

HelpRedirectState.$inject = ['$urlRouterProvider'];

export default HelpRedirectState;
