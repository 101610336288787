import PrivacyController from './privacy-controller';

function PrivacyState($stateProvider) {
  $stateProvider.state('privacy', {
    parent: 'language',
    url: '/privacy',
    templateUrl: 'views/privacy/privacy-view.html',
    controller: PrivacyController,
    controllerAs: 'vm',
    ignoreMobileWall: true
  });

}

PrivacyState.$inject = ['$stateProvider'];

export default PrivacyState;
