import { loadImage, FallbackConfig } from './fallback-config';

/**
  *  @ngdoc directive
  *  @name Seed.Directive:fallbackImg
  *  @description
  *    allows us to use loading and fallback images for the image tag.  This directive supports 3 attributes:
  *
  *    fallback-img:  The image that you want to display
  *    fallback-loader:  A loading image to display when trying to the load the image
  *    fallback-error:  An error image to display if the image fails to load
  *  @example
  *   <img 
  *     fallback-img="https://asdf.com/asdf.jpg" 
  *     fallback-loader="https://loadingimage.jpg"
  *     fallback-error="https://error-image.jpg"
  *    />
  */
//This is declared outside the method b/c there were some weird scoping issues that only seem to impact the unit tests, not in usage
function fallbackImg() {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      scope.$watch(() => attrs.fallbackImg, () => {
        if (FallbackConfig.showLoader) {
          element.addClass('fallback-loading');
          element[0].src = attrs.fallbackLoader || FallbackConfig.getLoadingImage();
        }
        loadImage(attrs.fallbackImg, () => {
          element[0].src = attrs.fallbackImg;
          element.removeClass('fallback-loading');
        },
          () => {
            element.addClass('fallback-error');
            element.removeClass('fallback-loading');
            element[0].src = attrs.fallbackError || FallbackConfig.getFallbackImage();
          });
      });
    }
  }
}

export default fallbackImg;
