/**
 *  @ngdoc object
 *  @name Seed.Components:errorList
 *  @module Seed
 *  @description
 *
 *    Component used for displaying list of errors.
 *
 */
const ErrorListComponent = {
  bindings: {
    errors: '<'
  },
  templateUrl: 'components/error-list/error-list.html',
  controllerAs: 'vm'
};

export default ErrorListComponent;
