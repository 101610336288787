import BoomSignupFormController from './boom-signup-form-controller';

const BoomSignupFormComponent = {
  bindings: {
    onError: '&',
    onSubmit: '&',
    onSuccess: '&'
  },
  controller: BoomSignupFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-signup-form/boom-signup-form.html'
};

export default BoomSignupFormComponent;
