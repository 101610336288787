import BoomProductSelectionPromoController from './boom-product-selection-promo-controller';

const BoomProductSelectionPromoComponent = {
  bindings: {
    onFailure: '&',
    onProductSelect: '&',
    onReady: '&',
    series: '<',
    episode: '<',
    catalog: '<',
    allowPromocodeEntry: '<',
    next: '<',
    onNextChange: '&',
    errors: '<'
  },
  controller: BoomProductSelectionPromoController,
  controllerAs: 'vm',
  templateUrl: 'components/product-selection-promo/boom-product-selection-promo.html'
};

export default BoomProductSelectionPromoComponent;
