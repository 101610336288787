/**
 *  @ngdoc controller
 *  @name Seed.Controllers:DeviceRegistrationView
 *  @module Seed
 *  @requires angular.$state
 *  @description
 *    Responsible for managing the DeviceRegistration page.
 */
class DeviceRegistrationViewController {
  static get $inject() {
    return ['$state', 'platformjs.device'];
  }

  constructor($state, DeviceService) {
    this.$state = $state;
    this.DeviceService = DeviceService;

    this.submitting = false;

    if ($state.params.code) {
      this.code = $state.params.code;
    }

  }

  success() {
    this.linkSuccess = true;
  }

  error(response) {
    this.errors = response;
  }

  done() {
    this.submitting = false;
  }

  linkDevice() {
    // remove any previous errors
    this.errors = [];

    this.submitting = true;

    this.DeviceService.linkDevice(this.code)
      .then(() => this.success())
      .catch((response) => this.error(response))
      .finally(() => this.done());
  }
}

export default DeviceRegistrationViewController;
