import BoomChangePasswordFormController from './boom-change-password-form-controller';

const BoomChangePasswordFormComponent = {
  bindings: {
    token: '<',
    onError: '&',
    onSubmit: '&',
    onSuccess: '&',
    errors: '<'
  },
  controller: BoomChangePasswordFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-change-password-form/change-password-form.html'
};

export default BoomChangePasswordFormComponent;
