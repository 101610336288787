import BoomPromoCodeController from '../boom-promocode/boom-promocode-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomPromoCodeStandaloneController
 *  @module Boom
 *  @description
 *    Responsible for managing the promo view, check promocode and redirect the user.
 */
class BoomPromoCodeStandaloneController extends BoomPromoCodeController {

  static get $inject() {
    return ['$state', '$cookies', 'platformjs.premiumProfile', '$anchorScroll'];
  }


  /* istanbul ignore next */
  constructor($state, $cookies, PremiumProfileService, $anchorScroll) {
    super($state, PremiumProfileService, $anchorScroll);
    this.$cookies = $cookies;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPromoCodeStandaloneController#onInit
   *  @methodOf Boom.Controllers:BoomPromoCodeStandaloneController
   *  @description
   *    Initializes promo code submit, if one exists from the view query string
   */
  $onInit() {
    let cookie = this.$cookies.get('subscribe-promo');

    if (cookie) {
      this.promocode = cookie;

      this.submit();
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPromoCodeStandaloneController#checkSuccess
   *  @methodOf Boom.Controllers:BoomPromoCodeStandaloneController
   *  @description
   *    call onSuccess with the valid code
   */
  checkSuccess() {
    this.errors = null;
    this.previouslyRedeemed = false;
    this.expired = false;
    this.doesNotExist = false;
    this.onSuccess({
      code: this.promocode
    });
  }
}

export default BoomPromoCodeStandaloneController;
