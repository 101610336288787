// TO DO: include functionality after UX feedback

class ProductPromoController {
  static get $inject() {
    return ['$stateParams'];
  }
  constructor($stateParams) {
    this.product = $stateParams.pid;
  }
}

export default ProductPromoController;