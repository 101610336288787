import UpdatePaymentViewController from './update-payment-view-controller';

function UpdatePaymentState($stateProvider) {
  $stateProvider.state('account.update-payment', {
    parent: 'account.profile',
    url: '/update-payment',
    controller: UpdatePaymentViewController,
    controllerAs: 'vm',
    templateUrl: 'views/update-payment/update-payment-view.html',
    ignoreMobileWall: true,
    dontBreakGateFlow: true
  });
}

UpdatePaymentState.$inject = ['$stateProvider'];

export default UpdatePaymentState;
