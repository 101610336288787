function DeviceRegistrationRedirectState($urlRouterProvider) {

  $urlRouterProvider.when(/^\/roku\/?$/i, 'device-registration');
  $urlRouterProvider.when(/^\/firetv\/?$/i, 'device-registration');
  $urlRouterProvider.when(/^\/atv\/?$/i, 'device-registration');
}

DeviceRegistrationRedirectState.$inject = ['$urlRouterProvider'];

export default DeviceRegistrationRedirectState;
