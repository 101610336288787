/**
 *  @ngdoc service
 *  @name Seed.Services:SubscribeConfig
 *  @module Seed
 *  @requires angular.$q
 *  @requires platformjs.catalog
 *  @requires platformjs.paymentmethods
 *  @requires platformjs.subscriptions
 *  @requires platformjs.user.account
 *  @description
 *    Convenience service for returning all necessary data for subscribing.
 */
class SubscribeConfigService {
  static get $inject() {
    return ['$q', 'platformjs.catalog', 'platformjs.paymentmethods', 'platformjs.subscriptions', 'platformjs.user.account'];
  }
  constructor($q, CatalogService, PaymentMethodsService, SubscriptionsService, UserAccountService) {
    this.$q = $q;
    this.CatalogService = CatalogService;
    this.PaymentMethodsService = PaymentMethodsService;
    this.SubscriptionsService = SubscriptionsService;
    this.UserAccountService = UserAccountService;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:SubscribeConfig#get
   *  @methodOf Seed.Services:SubscribeConfig
   *  @returns {promise} resolved promise will contain data object.
   *  @description
   *    This method is used for requesting all data required to start the subscription process. The response includes 
   *    catalog data, config object for payment methods, and lists of both available payment methods and current subscriptions 
   *    of the user.
   *
   *  @example
   *
   *  ***Success Response***
   *  ```js
   *    {
   *      "catalog": {},
   *      "paymentMethodsConfig": {},
   *      "paymentMethods": {},
   *      "subscriptions": {}
   *    }
   *  ```
   */
  get() {

    var deferred = this.$q.defer();

    var success = function(response) {
      var responseObject = {
        catalog: response[0],
        paymentMethodsConfig: response[1],
        paymentMethods: response[2],
        subscriptions: response[3]
      };

      deferred.resolve(responseObject);
    };

    var error = function(response) {
      deferred.reject(response);
    };

    var promises = [];

    promises[0] = this.CatalogService.list();
    promises[1] = this.PaymentMethodsService.getConfig();

    if (this.UserAccountService.isRegistered()) {
      promises[2] = this.PaymentMethodsService.list();
    }

    if (this.UserAccountService.isPremium()) {
      promises[3] = this.SubscriptionsService.list();
    }

    this.$q.all(promises).then(success, error);

    return deferred.promise;

  }
}

export default SubscribeConfigService;
