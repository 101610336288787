/**
 *  @ngdoc controller
 *  @name Boom.Controllers:RedemptionView
 *  @module Boom
 *  @requires $location
 *  @requires $state
 *  @requires platformjs.user.login
 *  @description
 *    Responsible for managing the redemption view.
 */
class RedemptionViewController {

  static get $inject() {
    return ['$location', '$state', 'platformjs.user.login'];
  }

  constructor($location, $state, LoginService) {
    this.$location = $location;
    this.$state = $state;
    this.LoginService = LoginService;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Boom.Controllers:RedemptionView
     *  @description
     *  @returns {array} Current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name end
     *  @propertyOf Boom.Controllers:RedemptionView
     *  @description
     *  @returns {string} Url password reset token.
     */
    this.token = this.$location.search().token;

    /**
     *  @ngdoc property
     *  @name start
     *  @propertyOf Boom.Controllers:RedemptionView
     *  @description
     *  @returns {string} Url subscrption start date.
     */
    this.start = this.$location.search().start;

    /**
     *  @ngdoc property
     *  @name end
     *  @propertyOf Boom.Controllers:RedemptionView
     *  @description
     *  @returns {string} Url subscrption end date.
     */
    this.end = this.$location.search().end;

    /**
     *  @ngdoc property
     *  @name end
     *  @propertyOf Boom.Controllers:RedemptionView
     *  @description
     *  @returns {string} Url email.
     */
    this.email = this.$location.search().email;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:RedemptionView#showSuccess
   *  @methodOf Boom.Controllers:RedemptionView
   *  @param {Object} Response password
   *  @description
   *    Logs a user in after resetting password.
   */
  onSuccess({password}) {

    this.LoginService.login(this.email, password)
      .then(() => this.onLoginSuccess())
      .catch(errors => this.showErrors(errors))
      .finally(() => this.onLoginComplete());

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:RedemptionView#onLoginSuccess
   *  @methodOf Boom.Controllers:RedemptionView
   *  @description
   *    Called on success of login request.
   */
  onLoginSuccess() {

    this.$state.go('startgift-success', {
      start: this.start,
      end: this.end
    });

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:RedemptionView#showErrors
   *  @methodOf Boom.Controllers:RedemptionView
   *  @param {array} errors Errors list
   *  @description
   *    Shows passed errors.
   */
  showErrors(errors) {

    this.submitting = false;
    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:RedemptionView#onLoginComplete
   *  @methodOf Boom.Controllers:RedemptionView
   *  @description
   *    Called on completion of login.
   */
  onLoginComplete() {

    this.submitting = false;

  }
}

export default RedemptionViewController;
