import BracketController from './bracket-controller';

function BracketState($stateProvider) {

  $stateProvider.state('bracket', {
    url: '/bracket',
    controller: BracketController,
    controllerAs: 'vm',
    templateUrl: 'views/bracket/bracket.html',
    ignoreMobileWall: true
  });

}

BracketState.$inject = ['$stateProvider'];

export default BracketState;
