import BoomUpsellPromoController from './boom-upsell-promo-controller';

const BoomUpsellPromoComponent = {
  bindings: {
    series: '<',
    episode: '<',
    titleOverride: '<'
  },
  controllerAs: 'vm',
  controller: BoomUpsellPromoController,
  templateUrl: 'components/boom-upsell-promo/boom-upsell-promo.html'
};
export default BoomUpsellPromoComponent;
