import PrivacyOldController from './privacy-old-controller';

function PrivacyOldState($stateProvider) {
  $stateProvider.state('privacy-old', {
    parent: 'language',
    url: '/privacy-old',
    templateUrl: 'views/privacy-old/privacy-old-view.html',
    controller: PrivacyOldController,
    controllerAs: 'vm',
    ignoreMobileWall: true
  });

}

PrivacyOldState.$inject = ['$stateProvider'];

export default PrivacyOldState;
