/**
*  @ngdoc controller
*  @name Seed.Controllers:SubscribeView
*  @module Seed
*  @requires ProgressBarService
*  @description
*    Responsible for managing the subscribe view.
*/
class SubscribeViewController {
  static get $inject() {
    return ['$state', 'ProgressBarService'];
  }
  constructor($state, ProgressBarService) {
    this.$state = $state;
    this.ProgressBarService = ProgressBarService;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:SubscribeView
     *  @returns {array} A list of errors.
     *  @description
     *    Current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name failed
     *  @propertyOf Seed.Controllers:SubscribeView
     *  @returns {boolean} The failure state.
     *  @description
     *    Returns true if subscription form failed to initialize.
     */
    this.failed = false;

    /**
     *  @ngdoc property
     *  @name ready
     *  @propertyOf Seed.Controllers:SubscribeView
     *  @returns {boolean} The ready state.
     *  @description
     *    Returns true if subscription form successfully initialized.
     */
    this.ready = false;
  }
  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeView#$onInit
   *  @methodOf Seed.Controllers:SubscribeView
   *  @description
   *    Begins loading UI.
   */
  $onInit() {

    this.ProgressBarService.start();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeView#showSubscribeReady
   *  @methodOf Seed.Controllers:SubscribeView
   *  @description
   *    Sets {@link Seed.Controllers:SubscribeView#properties_ready ready} to true.
   */
  showSubscribeReady() {

    this.ProgressBarService.complete();
    this.ready = true;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeView#showSubscribeErrors
   *  @methodOf Seed.Controllers:SubscribeView
   *  @param {array} errors A list of errors.
   *  @description
   *    Sets {@link Seed.Controllers:SubscribeView#properties_errors errors} property.
   */
  showSubscribeErrors(errors) {

    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeView#showSubscribeFailure
   *  @methodOf Seed.Controllers:SubscribeView
   *  @description
   *    Sets {@link Seed.Controllers:SubscribeView#properties_failure failure} to true.
   */
  showSubscribeFailure() {

    this.ProgressBarService.complete();
    this.failed = true;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:SubscribeView#showSubscribeSuccess
   *  @methodOf Seed.Controllers:SubscribeView
   *  @description
   *    Redirects to the welcome page.
   */
  showSubscribeSuccess() {

    this.$state.go('welcome');

  }
}

export default SubscribeViewController;

