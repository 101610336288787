import BoomGiftPaymentFormController from './boom-gift-payment-form-controller';

const BoomGiftPaymentFormComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    onSubmit: '&',
    onSuccess: '&',
    options: '<',
    token: '<',
    billingAgreementDescription: '<',
    product: '<',
    creatingGift: '<'
  },
  controller: BoomGiftPaymentFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-gift-payment-form/boom-gift-payment-form.html',
  transclude: {
    paymentFormButtonText: '?paymentFormButtonText'
  }
};

export default BoomGiftPaymentFormComponent;
