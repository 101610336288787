/**
*  @ngdoc service
*  @name Seed.Provider:UrlLanguageProvider
*  @module Seed
*  @param {object} $urlRouterProvider ui-router provider
*  @description
*    Provider that can be configured with supported languages
*    Exposes a service for handling urls containing a language setting
*    Adds the language to the url if not present and exposes it through a UrlLanguage Service
*
*  @example
*
  import UrlLanguageProvider from 'url-language-provider';

  angular.module('demo', ['ui-router'])
  .provider('UrlLanguage', UrlLanguageProvider)
  .config(['UrlLanguageProvider', function(UrlLanguageProvider) {
    UrlLanguageProvider.addSupportedLanguages([
      {
        urlCode: 'en',
        languageCode: 'en-US'
      },
      {
        urlCode: 'es',
        languageCode: 'es-MX'
      },
      {
        urlCode: 'pt',
        languageCode: 'pt-BR'
      },
      {
        urlCode: 'ar',
        languageCode: 'ar-ARB'
      },
      {
        urlCode: 'tr',
        languageCode: 'tr-TR'
      },
      {
        urlCode: 'zh-cn',
        languageCode: 'zn-CH'
      }
    ]);
    UrlLanguageProvider.setDefaultLanguage('en-US');
    UrlLanguageProvider.addRouteLanguageRewrite();
  }]);
*/
function UrlLanguageProvider($urlRouterProvider) {
  /**
  *  @ngdoc service
  *  @name Seed.Service:UrlLanguage
  *  @module Seed
  *  @description
  *    Service exposing language settings for the URL
  */
  const UrlLanguageService = {
    /**
     *  @ngdoc property
     *  @name activeLanguage
     *  @propertyOf Seed.Service:UrlLanguage
     *  @description
     *    Current active language to be added to the URL if it is not present
     */
    activeLanguage: null,
    /**
     *  @ngdoc property
     *  @name content
     *  @propertyOf Seed.Service:UrlLanguage
     *  @description
     *    An array of supported language strings
     */
    supportedLanguages: [],
    /**
     *  @ngdoc property
     *  @name defaultLanguage
     *  @propertyOf Seed.Service:UrlLanguage
     *  @description
     *    The default language for the site that the url can ignore
     */
    defaultLanguage: null,
    /**
     *  @ngdoc method
     *  @name Seed.Service:UrlLanguage#getSupportedUrlLanguageCodes
     *  @methodOf Seed.Service:UrlLanguage
     *  @returns {array} languages array of string url language codes ex. ['us', 'es', 'tr']
     *  @description
     *    gets a list of supported url language codes
     */
    getSupportedUrlLanguageCodes: function() {
      return this.supportedLanguages.map(function(language) {
        return language.urlCode;
      });
    },
    /**
     *  @ngdoc method
     *  @name Seed.Service:UrlLanguage#getUrlCodeFromLanguageCode
     *  @methodOf Seed.Service:UrlLanguage
     *  @param {string} languageCode language code to get the matching url code for ex. 'en-US'
     *  @returns {string} urlCode the corresponding url code ex. 'en'
     *  @description
     *    get a url code from a language code.  helper for working with the objects passed through addSupportedLanguages 
     */
    getUrlCodeFromLanguageCode: function(languageCode) {
      let toReturn;
      this.supportedLanguages.forEach(lang => {
        if (lang.languageCode === languageCode) {
          toReturn = lang.urlCode;
        }
      });
      return toReturn;
    },
    /**
     *  @ngdoc method
     *  @name Seed.Service:UrlLanguage#getLanguageCodeFromUrlCode
     *  @methodOf Seed.Service:UrlLanguage
     *  @param {string} urlCode url code to get the matching language code for ex. 'en'
     *  @returns {string} languageCode the corresponding language code ex. 'en-US'
     *  @description
     *    get a language code from a url code.  helper for working with the objects passed through addSupportedLanguages 
     */
    getLanguageCodeFromUrlCode: function(urlCode) {
      let toReturn;
      this.supportedLanguages.forEach(lang => {
        if (lang.urlCode === urlCode) {
          toReturn = lang.languageCode;
        }
      });
      return toReturn;
    },

    /**
     *  @ngdoc method
     *  @name Seed.Service:UrlLanguage#setActiveLanguage
     *  @methodOf Seed.Service:UrlLanguage
     *  @param {string} lang language string
     *  @description
     *    sets the active langauge
     */
    setActiveLanguage: function(language) {
      this.activeLanguage = language;
    }
  };

  this.UrlLanguageService = UrlLanguageService;

  this.$get = [() => {
    return UrlLanguageService;
  }];

  /**
   *  @ngdoc method
   *  @name Seed.Provider:UrlLanguageProvider#addSupportedLanguages
   *  @methodOf Seed.Provider:UrlLanguageProvider
   *  @param {array} array of objects containing a url lang code and a language codes to add
   *    ex.[{
          urlCode: 'en',
          languageCode: 'en-US'
        },
        {
          urlCode: 'es',
          languageCode: 'es-MX'
        }]
   *  @description
   *    Adds the custom rewrite rule to a $urlRouterProvider from ui-router
   */
  this.addSupportedLanguages = (languageArray) => {
    UrlLanguageService.supportedLanguages = UrlLanguageService.supportedLanguages.concat(languageArray);
  };

  //expose get/setters for properties on the service so .config and .run blocks have access

  /**
  *  @ngdoc method
  *  @name Seed.Provider:UrlLanguageProvider#getSupportedLanguages
  *  @methodOf Seed.Provider:UrlLanguageProvider
  *  @returns {array} languages language config objects
  *  @description
  *    gets all supported language objects configured with addSupportedLanguages
  */
  this.getSupportedLanguages = () => UrlLanguageService.supportedLanguages;

  /**
   *  @ngdoc method
   *  @name Seed.Provider:UrlLanguageProvider#getSupportedUrlLanguageCodes
   *  @methodOf Seed.Provider:UrlLanguageProvider
   *  @returns {array} languages array of string url language codes ex. ['us', 'es', 'tr']
   *  @description
   *    gets a list of supported url language codes
   */
  this.getSupportedUrlLanguageCodes = () => UrlLanguageService.getSupportedUrlLanguageCodes();
  /**
   *  @ngdoc method
   *  @name Seed.Provider:UrlLanguageProvider#getDefaultLanguage
   *  @methodOf Seed.Provider:UrlLanguageProvider
   *  @returns {string} default language string
   *  @description
   *    gets the default language configured on the UrlLanguage Service
   */
  this.getDefaultLanguage = () => UrlLanguageService.defaultLanguage;

  /**
   *  @ngdoc method
   *  @name Seed.Provider:UrlLanguageProvider#setActiveLanguage
   *  @methodOf Seed.Provider:UrlLanguageProvider
   *  @param {string} lang language string
   *  @description
   *    sets the active langauge
   */
  this.setActiveLanguage = (lang) => {
    UrlLanguageService.setActiveLanguage(lang);
  };

  /**
   *  @ngdoc method
   *  @name Seed.Provider:UrlLanguageProvider#setDefaultLanguage
   *  @methodOf Seed.Provider:UrlLanguageProvider
   *  @description
   *    sets the default language on the UrlLanguage Service
   */
  this.setDefaultLanguage = (language) => {
    UrlLanguageService.defaultLanguage = language;
  };

  /**
   *  @ngdoc method
   *  @name Seed.Provider:UrlLanguageProvider#addRouteLanguageRewrite
   *  @methodOf Seed.Provider:UrlLanguageProvider
   *  @description
   *    Adds the custom rewrite rule to a $urlRouterProvider from ui-router
   */
  this.addRouteLanguageRewrite = () => {
    //configure ui-router with our custom url rule.  Uses an arrow func. to maintain "this" context
    $urlRouterProvider.rule(($injector, $location) => this.rewriteRule($injector, $location));
  };

  /**
   *  @ngdoc method
   *  @name Seed.Provider:UrlLanguageProvider#rewriteRule
   *  @methodOf Seed.Provider:UrlLanguageProvider
   *  @param {object} $injector angular injector service
   *  @param {object} $location angular location service
   *  @description
   *    The rewrite function used to add languages to a url and set the property on the urlLanguage service
   */
  this.rewriteRule = ($injector, $location) => {
    //get the current url and split it into parts so we end up with
    //['', 'en', 'browse', 'genre', 'action-adventure?qwe#123']
    let url = $location.url();
    let parts = url.split('/');
    if (parts.length < 2) {
      return;
    }

    let urlLanguageCode = parts[1];
    //check if the first part of the url is one of the supported languages
    if (UrlLanguageService.getSupportedUrlLanguageCodes().indexOf(urlLanguageCode) !== -1) {
      //expose it to the service if it is a valid language
      UrlLanguageService.setActiveLanguage(UrlLanguageService.getLanguageCodeFromUrlCode(urlLanguageCode));
      return;
    }
    //otherwise if there isn't a valid language in the url we want to see if one has previously been set
    //ignore the defualt language and doesn't need to have anything appended
    else if (UrlLanguageService.activeLanguage && UrlLanguageService.activeLanguage !== UrlLanguageService.defaultLanguage) {
      //if it is set, rewrite the url to add the language in and rejoin the url into a string
      parts.splice(1, 0, UrlLanguageService.getUrlCodeFromLanguageCode(UrlLanguageService.activeLanguage));
      let newPath = parts.join('/');
      return newPath;
    }

  //otherwise we just let the url get passed directly to the router
  };
}

UrlLanguageProvider.$inject = ['$urlRouterProvider'];

export default UrlLanguageProvider;
