import BoomWelcomeRedemptionViewController from './boom-welcome-view-controller';

function BoomWelcomeRedemptionState($stateProvider) {

  $stateProvider.state('startgift-success', {
    url: '/startgift-success',
    controller: BoomWelcomeRedemptionViewController,
    controllerAs: 'vm',
    templateUrl: 'views/redemption/welcome-view.html',
    userRequired: true,
    ignoreMobileWall: true,
    params: {
      start: null,
      end: null
    },
  });

}

BoomWelcomeRedemptionState.$inject = ['$stateProvider'];

export default BoomWelcomeRedemptionState;
