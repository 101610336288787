/**
 *  @ngdoc controller
 *  @name Seed.Controllers:ChangePasswordView
 *  @module Seed
 *  @description
 *    Responsible for managing the  methods for changing a forgotten password.
 */
class ChangePasswordViewController {
  static get $inject() {
    return ['$stateParams'];
  }

  constructor($stateParams) {
    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:ChangePasswordView
     *  @description
     *  @returns {array} Current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name success
     *  @propertyOf Seed.Controllers:ChangePasswordView
     *  @description
     *  @returns {boolean} Current success state.
     */
    this.success = false;

    /**
     *  @ngdoc property
     *  @name token
     *  @propertyOf Seed.Controllers:ChangePasswordView
     *  @description
     *  @returns {string} Url token.
     */
    this.token = $stateParams.token;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ChangePasswordView#showSuccess
   *  @methodOf Seed.Controllers:ChangePasswordView
   *  @description
   *    Sets `success` to true.
   */
  showSuccess() {

    this.success = true;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ChangePasswordView#showErrors
   *  @methodOf Seed.Controllers:ChangePasswordView
   *  @param {array} errors Errors list
   *  @description
   *    Shows passed errors.
   */
  showErrors(errors) {

    this.errors = errors;

  }
}

export default ChangePasswordViewController;
