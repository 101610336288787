import OpenAppButtonController from './open-app-button-controller';

const OpenAppButtonComponent = {
  controller: OpenAppButtonController,
  controllerAs: 'vm',
  templateUrl: 'components/open-app-button/open-app-button.html',
  transclude: true
};

export default OpenAppButtonComponent;
