/**
 *  @ngdoc controller
 *  @name Boom.Controllers:SunsetViewController
 *  @module Boom
 *  @description
 *    Responsible redirecting Six Flags promo users through an age gate before subscribe
 */
class SunsetViewController {

  static get $inject() {
    /* istanbul ignore next */
    return [
      'ProgressBarService',
      'platformjs.collection',
      'UrlLanguage'
    ];
  }

  /* istanbul ignore next */
  constructor(ProgressBarService, CollectionService, UrlLanguage) {
    this.UrlLanguage = UrlLanguage;
    this.ProgressBarService = ProgressBarService;
    this.CollectionService = CollectionService;
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SunsetViewController#$onInit
   *  @methodOf Boom.Controllers:SunsetViewController
   *  @description
   *    Loads and displays sunset metadata
   */
  $onInit() {
    this.ProgressBarService.start();

    this.CollectionService.get({
      id: `c293`
    })
      .then(collection => this.setStylesFromCollection(collection))
      .finally(() => this.ProgressBarService.complete());
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:SunsetViewController#setStylesFromCollection
   * @methodOf Boom.Controllers:SunsetViewController
   * @param {object} collection The collection to pull the sunset metadata from
   * @description
   *    Puts together the styles and messaging from the collection to display to the user
   */
  setStylesFromCollection(collection) {
    this.isEnglish = this.UrlLanguage.activeLanguage === 'en';

    const metadata = collection.metadata;

    this.sunsetImageUrl = metadata.image;

    this.sunsetBodyStyle = {
      'background-color': metadata.bg_color
    };

    this.sunsetHeader = this.isEnglish ? metadata.header : metadata.header_es;
    this.sunsetHeaderStyle = {
      'color': metadata.header_font_color,
      'font-size': `${metadata.header_font_size}px`,
    };

    this.sunsetMessage = this.isEnglish ? metadata.body : metadata.body_es;
    this.sunsetMessageStyle = {
      'color': metadata.body_font_color,
      'font-size': `${metadata.body_font_size}px`
    };

    this.showSunsetCta = metadata.show_button && metadata.show_button.toLowerCase() === 'true';


    this.sunsetCtaLabel = this.isEnglish ? metadata.cta : metadata.cta_es;

    this.sunsetCtaText = this.isEnglish ? metadata.button : metadata.button_es;
    this.sunsetCtaUrl = metadata.button_link;
    this.sunsetCtaStyle = {
      'background-color': metadata.button_color,
      'color': metadata.button_text_color,
      'font-size': `${metadata.button_font_size}px`
    };

    this.sunsetFollowUpPrompt = this.isEnglish ? metadata.additional_text : metadata.additional_text_es;
    this.sunsetFollowUpUrl = metadata.additional_link;

    this.sunsetFollowUpLinkStyle = {
      color: this.sunsetCtaStyle['background-color']
    };
  }
}

export default SunsetViewController;
