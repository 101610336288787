import { default as Platform3 } from '../../platform3.module';
import { default as BraintreeV3 } from '../../services/braintree-v3.module';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomPaymentFormController
*  @module Boom
*  @requires $scope
*  @requires platformjs.user.profile
*  @requires $cookies
*  @requires platformjs.premiumProfile
*  @requires moment
*  @description
*    Responsible for subscribing a user.
*/
class BoomPaymentFormController {
  static get $inject() {
    return [
      '$scope',
      'platformjs.user.profile',
      '$cookies',
      'platformjs.premiumProfile',
      'moment',
      '$translate'
    ];
  }

  /* istanbul ignore next */
  constructor($scope, UserProfileService, $cookies, PremiumProfileService, moment, $translate) {
    this.$scope = $scope;
    this.UserProfileService = UserProfileService;
    this.PremiumProfileService = PremiumProfileService;
    this.$cookies = $cookies;
    this.moment = moment;
    this.$translate = $translate;
    /**
    *  @ngdoc property
    *  @name paymentMethod
    *  @propertyOf Boom.Controllers:BoomPaymentFormController
    *  @returns {string} type of payment method, eg. 'cc' or 'paypal'
    *
    *  @description
    *  Stores which payment method is selected in the radio input. Credit Card is pre-selected by default.
    **/
    this.paymentMethod = 'cc';

    /**
    *  @ngdoc property
    *  @name fields
    *  @propertyOf Boom.Controllers:BoomPaymentFormController
    *  @returns {object} Object containing information about each field.
    *
    *  @description
    *  An object used to store the state of each field in the payment form.
    **/
    this.fields = {
      number: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      },
      expirationDate: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      },
      cvv: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      },
      postalCode: {
        isEmpty: true,
        isFocused: false,
        isPotentiallyValid: true,
        isValid: false
      }
    };

    this.submitting = false;
    this.user = undefined;

    /**
    *  @ngdoc property
    *  @name fields
    *  @propertyOf Boom.Controllers:BoomPaymentFormController
    *  @returns {string} String containing a card type
    *
    *  @description
    *  An string used to store the credit card type from the Braintree callback response after field toggles
    **/
    this.cardType = null;
    this.trialChecked = false;
    this.arlConsent = false;


    this.onError = this.onError || angular.noop;
    this.onFailure = this.onFailure || angular.noop;
    this.onReady = this.onReady || angular.noop;
    this.onSubmit = this.onSubmit || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#$onInit
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @description
   *    Calls payment methods and loads premium profile
   */
  $onInit() {
    Platform3.services.paymentMethods.getConfig()
      .then(config => {
        config.clientToken = config.braintreeNonce.clientToken;
        this.createForm(config);
      })
      .catch(errors => {
        this.showFailure(errors);
      });

    this.loadPremiumProfile();
  }

  createSettings() {
    /**
    *  @ngdoc property
    *  @name settings
    *  @propertyOf Boom.Controllers:BoomPaymentFormController
    *  @returns {object} Settings object.
    *
    *  @description
    *  Settings for Braintree setup.
    **/
    this.settings = {
      id: 'payment-form',
      hostedFields: {
        number: {
          selector: '[data-field="card-number"]',
          placeholder: '1111 1111 1111 1111'
        },
        expirationDate: {
          selector: '[data-field="card-expirationDate"]',
          placeholder: 'MM/YY'
        },
        cvv: {
          selector: '[data-field="card-cvv"]',
          placeholder: 'CVV'
        },
        postalCode: {
          selector: '[data-field="card-postalCode"]',
          placeholder: this.$translate.instant('Zip Code')
        },
      },
      styles: {
        'input': {
          'color': '#3c3c3c',
          'font-size': '1.125rem',
          'font-family': "Arial, sans-serif"
        },
        '@media screen and (min-width: 780px)': {
          '#credit-card-number': {
            'color': '#ffffff'
          },
          '#cvv': {
            'color': '#ffffff'
          }
        },
        '@media screen and (min-width: 172px) and (max-width: 173px)': {
          '#expiration-date,#cvv,#postal-code': {
            'color': '#ffffff'
          }
        },
        '.invalid': {
          'color': '#3c3c3c'
        }
      }
    };
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#createForm
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @param {object} config configuration object to be proxied to PaymentFormController.createForm
   *  @param {string} config.clientToken the user's client token returned by PaymentMethodsService.getConfig()
   *  @description
   *    Sets the config from Platform3.services.paymentMethods.getConfig() on the instance and sets up credit card and paypal forms
   */
  createForm(config) {
    this.createSettings();
    this.clientConfig = config;

    return BraintreeV3.createBraintree(config)
      .then(clientInstance => {
        this.clientInstance = clientInstance;
      })
      .then(() => {
        return BraintreeV3.createPaypal({
          client: this.clientInstance
        });
      })
      .then(paypalInstance => {
        this.paypalInstance = paypalInstance;
      })
      .then(() => {
        return BraintreeV3.createHostedFields({
          client: this.clientInstance,
          fields: this.settings.hostedFields,
          styles: this.settings.styles
        });
      })
      .then(hostedFields => {
        this.hostedFields = hostedFields;
      })
      .catch(this.showFailure)
      .finally(() => {
        if (this.hostedFields) {
          this.hostedFields.on('validityChange', this.onBraintreeFieldEvent.bind(this));
        }
        this.onBraintreeReady();
      });
  }

  submitCCPayment() {
    this.error = null;
    this.submitting = true;

    this.updateUserFirstLastName();

    return BraintreeV3.ccFlow(this.hostedFields)
      .then(ccPaymentMethod => {
        this.paymentMethod = 'cc';
        this.onBraintreePaymentMethodReceived(ccPaymentMethod);
      })
      .catch(err => {
        this.onBraintreeError(err);
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#showFailure
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @param {array} errors Error list.
   *  @description
   *    Triggers onFailure and passes errors.
  **/
  showFailure(errors) {

    this.onFailure({
      errors: errors
    });

  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#createPaymentMethod
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @param {string} nonce Payment nonce
  *  @description
  *   Attempts to create a new payment method with passed nonce.
  **/
  createPaymentMethod(params) {
    this.submitting = true;

    // NOTE:
    // If we also need to pass a country code for 'CreditCard' payment type,
    // we will need to collect country code from users. The decision has not
    // been made yet.

    return Platform3.services.paymentMethods.create(params)
      .then(response => {
        if (response.status !== 'rejected') {
          this.createPaymentMethodSuccess(response);
        } else {
          return Promise.reject([{
            code: '',
            message: 'Payment method rejected'
          }]);
        }
      })
      .catch(errors => {
        this.createPaymentMethodFailure(errors);
      });
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#createPaymentMethodSuccess
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @param {object} response Success response
  *  @description
  *    Calls {@link Boom.Controllers:BoomPaymentFormController#methods_onSuccess onSuccess} method and passes `success.paymentMethodGuid`
  **/
  createPaymentMethodSuccess(response) {
    var onSuccessResponse = this.onSuccess({
      paymentMethod: response
    });

    // in the case that the containing controller executes a promise with
    // the passed paymentMethod, wait for that promise to be resolved/rejected
    // before ending the submission process.

    if (onSuccessResponse && onSuccessResponse.finally) {
      onSuccessResponse.finally(() => this.showSubmitComplete());
    } else {
      this.showSubmitComplete();
    }

  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#fieldClasses
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @param {string} key Field key
  *  @description
  *    Returns classes according to the property of {@link Boom.Controllers:BoomPaymentFormController#properties_fields fields}
  *    that matches the passed key.
  **/
  fieldClasses(key) {
    if (!this.fields[key]) {
      return {};
    }

    return {
      'is-focused': this.fields[key].isFocused,
      'is-not-empty': !this.fields[key].isEmpty,
      'is-invalid': !this.fields[key].isPotentiallyValid
    };
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#isBraintreeFormPotentiallyValid
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @returns {boolean} Valid
   *  @description
   *    Returns `false` if any object in {@link Boom.Controllers:BoomPaymentFormController#properties_fields fields} has an `isPotentiallyValid` property set to `false`.
   **/
  isBraintreeFormPotentiallyValid() {
    for (var k in this.fields) {
      if (!this.fields[k].isPotentiallyValid) {
        return false;
      }
    }
    return true;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#isBraintreeFormValid
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @returns {boolean} Valid
  *  @description
  *    Returns `true` if every object in {@link Boom.Controllers:BoomPaymentFormController#properties_fields fields} has an `isValid` property set to `true`.
  **/
  isBraintreeFormValid() {

    for (var k in this.fields) {
      if (!this.fields[k].isValid) {
        return false;
      }
    }

    return true;

  }

  nameFieldsValid() {
    return !!this.user && !!this.user.firstName && !!this.user.lastName;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#onBraintreeError
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @param {object} error Error object
  *  @description
  *    Called on Braintree Hosted Fields error.
  *
  *  Calls {@link Boom.Controllers:BoomPaymentFormController#methods_onError onError} method and passes the error object.
  **/
  onBraintreeError(error) {

    // TO DO: error factory for braintree errors

    this.$scope.$apply(this.onError({
      errors: error
    }));

  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#onBraintreeFieldEvent
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @param {Object} event Callback event
  *  @description
  *    Handles BrainTree Host Fields callback when user toggles through fields
  **/

  onBraintreeFieldEvent(event) {
    if (event.emittedBy) {
      const targetField = this.fields[event.emittedBy];
      const eventField = event.fields[event.emittedBy];

      targetField.isEmpty = eventField.isEmpty;
      targetField.isFocused = eventField.isFocused;
      targetField.isPotentiallyValid = eventField.isPotentiallyValid;
      targetField.isValid = eventField.isValid;

      this.$scope.$apply();

      this.cardType = event.cards && event.cards[0] ?
        event.cards[0].type : null;
    }
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#onBraintreeReady
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @description
  *   Called on successful initialization of Braintree Hosted Fields.
  *
  *   Calls {@link Boom.Controllers:BoomPaymentFormController#methods_onReady onReady} method.
  **/
  onBraintreeReady() {

    this.$scope.$apply(this.onReady());

  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#onBraintreePaymentMethodReceived
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @param {object} success Success object
  *  @description
  *   Called on Braintree Hosted Fields success.
  *
  *  Calls {@link Boom.Controllers:BoomPaymentFormController#methods_createPaymentMethod createPaymentMethod} method and passes the generated payment nonce.
  **/
  onBraintreePaymentMethodReceived(paymentMethod) {
    this.$scope.$apply(this.createPaymentMethod({
      merchantNonce: paymentMethod.nonce
    }));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#onPaypalReady
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @description
   *    Braintree's `onReady` callback to return paypal method. This is needed
   *    to use `headless` PayPal integration.
   */
  onPaypalReady(integration) {
    this.checkout = integration;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#displayPaypalForm
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @description
   *    This opens the PayPal auth form.
   */
  displayPaypalForm() {
    this.error = null;
    this.submitting = true;

    BraintreeV3.paypalFlow(this.paypalInstance)
      .then(paypalPaymentMethod => {
        this.paymentMethod = 'paypal';
        const paypalParams = {
          merchantNonce: paypalPaymentMethod.nonce
        };

        if (paypalPaymentMethod.details.billingAddress) {
          paypalParams.postalCode = paypalPaymentMethod.details.billingAddress.postalCode;
          paypalParams.countryCode = paypalPaymentMethod.details.billingAddress.countryCodeAlpha2;
        }
        this.createPaymentMethod(paypalParams);
      })
      .catch(err => {
        this.onBraintreeError(err);
        this.submitting = false;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#updateUserFirstLastName
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @description
   *    Update user profile with first and last name.
   */
  updateUserFirstLastName() {
    this.UserProfileService.update(this.user).catch((err) => {
      Raven.captureException(err);
    });
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#formCompleted
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @description
   *    Checks for form completion to toggle error message
   */
  formCompleted(fields) {
    let completed = false;
    for (let field in fields) {
      if (fields[field].isEmpty === false) {
        completed = true;
      } else {
        completed = false;
      }
    }
    return completed;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#createPaymentMethodFailure
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @param {array} errors Error list
  *  @description
  *    Calls {@link Boom.Controllers:BoomPaymentFormController#methods_onError onError} method and passes error array.
  **/
  createPaymentMethodFailure(errors) {
    if (this.paymentMethod === 'cc') {
      this.errors = errors;
    } else {
      this.paypalErrors = errors;
    }
    this.onError({
      errors: errors
    });

    this.showSubmitComplete();
  }

  showSubmitComplete() {
    let cookie = this.$cookies.get('subscribe-promo');

    if (cookie) {
      this.$cookies.remove('subscribe-promo');
    }
    this.submitting = false;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomPaymentFormController#submit
  *  @methodOf Boom.Controllers:BoomPaymentFormController
  *  @description
  *   Sets {@link Boom.Controllers:BoomPaymentFormController#properties_submitting submitting} to `true`.
  **/
  submit() {
    this.submitting = true;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#loadPremiumProfile
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @description
   *    Loads the user premium profile
   */
  loadPremiumProfile() {
    this.PremiumProfileService.get()
      .then(this.setPremiumProfile.bind(this))
      .finally(() => {
        this.trialChecked = true;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#setPremiumProfile
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @param {object} profile The user's premium profile
   *  @description
   *    Sets user profile from loadPremiumProfile
   */
  setPremiumProfile(profile) {
    this.userPremiumProfile = profile;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomPaymentFormController#setPremiumProfile
   *  @methodOf Boom.Controllers:BoomPaymentFormController
   *  @param {int} freeDays The product free Days
   *  @description
   *    Calculates free days for trial
   */
  calculateEndDate(freeDays) {
    const expire = this.moment(new Date()).add(freeDays, 'days').format('MM/DD/YYYY');
    return expire;
  }
}

export default BoomPaymentFormController;
