import BoomRedirectController from 'views/redirect/boom-redirect-view-controller';

function BoomRedirectState($stateProvider) {
  $stateProvider.state('redirect', {
    controller: BoomRedirectController,
    controllerAs: 'vm',
    templateUrl: 'views/redirect/boom-redirect-view.html'
  });
}

BoomRedirectState.$inject = ['$stateProvider'];

export default BoomRedirectState;
