import SuccessViewController from 'views/success/success-view-controller';

function SuccessState($stateProvider) {

  $stateProvider.state('success', {
    url: '/success',
    controller: SuccessViewController,
    controllerAs: 'vm',
    templateUrl: 'views/success/success-view.html',
    parent: 'register',
    userRequired: true,
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });

}

SuccessState.$inject = ['$stateProvider'];

export default SuccessState;
