/**
*  @ngdoc controller
*  @name Boom.Controllers:ActiveSubscription
*  @module Boom
*  @description
*    Responsible for displaying a user's subscription
*/
class ActiveSubscriptionController {
}

export default ActiveSubscriptionController;
