/**
 *  @ngdoc controller
 *  @name Seed.Controllers:LogoutButton
 *  @module Seed
 *  @requires angular.$state
 *  @requires platformjs.user.login
 *  @description
 *    Manages button that logs a user out.
 */
class LogoutButtonController {
  static get $inject() {
    return ['$state', '$analytics', 'platformjs.user.login'];
  }

  constructor($state, $analytics, LoginService) {
    this.$state = $state;
    this.$analytics = $analytics;
    this.LoginService = LoginService;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:LogoutButton#logout
   *  @methodOf Seed.Controllers:LogoutButton
   *  @description
   *    Logs the current user out.
   */
  logout() {

    this.LoginService.logout()
      .then(() => this.onLogoutSuccess(), errors => this.onLogoutFailure(errors));

  }

  onLogoutFailure(errors) {

    // TO DO: how to handle this type of error

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:LogoutButton#onLogoutSuccess
  *  @methodOf Seed.Controllers:LogoutButton
  *  @description
  *    Redirects to home page.
  */
  onLogoutSuccess() {

    this.$analytics.eventTrack('Logout', {
      category: 'User'
    });

    this.$state.go('home');

  }
}

export default LogoutButtonController;
