/**
   *  @ngdoc controller
   *  @name Seed.Controllers:ForgotPasswordForm
   *  @module Seed
   *  @requires platformjs.user.login
   *  @description
   *    Responsible for requesting change password email.
   */
class ForgotPasswordFormController {
  static get $inject() {
    return ['platformjs.user.login'];
  }

  constructor(LoginService) {
    this.LoginService = LoginService;

    /**
     *  @ngdoc property
     *  @name email
     *  @propertyOf Seed.Controllers:ForgotPasswordForm
     *  @returns {string} Email.
     *  @description
     *    User email input value.
     */
    this.email = '';

    /**
     *  @ngdoc property
     *  @name submitting
     *  @propertyOf Seed.Controllers:ForgotPasswordForm
     *  @returns {boolean} Status.
     *  @description
     *    Current submission status.
     */
    this.submitting = false;

    this.onError = this.onError || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ForgotPasswordForm#showSubmitErrors
   *  @methodOf Seed.Controllers:ForgotPasswordForm
   *  @param {array} errors Errors 
   *  @description
   *    Called on failure of request for password email.
   */
  showSubmitErrors(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ForgotPasswordForm#showSubmitComplete
   *  @methodOf Seed.Controllers:ForgotPasswordForm
   *  @description
   *    Called on complete of request for password email.
   */
  showSubmitComplete() {

    this.submitting = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ForgotPasswordForm#showSubmitSuccess
   *  @methodOf Seed.Controllers:ForgotPasswordForm
   *  @description
   *    Called on success of request for password email.
   */
  showSubmitSuccess() {

    this.onSuccess();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ForgotPasswordForm#submit
   *  @methodOf Seed.Controllers:ForgotPasswordForm
   *  @description
   *    Submit form data to make request for password email.
   */
  submit() {

    this.submitting = true;

    this.LoginService.requestPasswordToken(this.email)
      .then(() => this.showSubmitSuccess(), errors => this.showSubmitErrors(errors))
      .finally(() => this.showSubmitComplete());

  }

}

export default ForgotPasswordFormController;
