/**
 *  @ngdoc service
 *  @name Gates.Service:BabyGateService
 *  @description
 *    Service for interacting with the baby gate
 *  @fires babygate-open
 *  @fires babygate-success
 *  @fires babygate-failed
 */
class BabyGateService {
  static get $inject() {
    return ['$q', '$rootScope'];
  }

  constructor($q, $rootScope) {
    this.$q = $q;
    this.$rootScope = $rootScope;

    this.state = {
      open: false,
      resolve: angular.noop,
      reject: angular.noop,
      error: false,
      config: {}
    };

    this.testStrings = [
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine'
    ];

    this.numberOfTestStrings = 4;
    this.testString = '';
    this.testAnswer = [];

    this.generateTest();
  }

  /**
   *  @ngdoc method
   *  @name Gates.Service:BabyGateService#open
   *  @methodOf Gates.Service:BabyGateService
   *  @param {object} config configuration object
   *  @param {boolean} config.fullscreen If the baby gate should be full screen
   *  @returns {promise} Promise to be resolved when the baby gate is passed
   *  @description
   *    Sets configuration and generates an initial test question
   *    Stores resolve/reject on the state for consumers of the service to call
   */
  open(config, params) {
    this.state.config = config;
    this.params = params;

    this.generateTest();
    //when opening create a new promise to return back and
    //store the promises on the state so that controllers
    //can resolve/reject it
    return this.$q((resolve, reject) => {
      this.state.error = false;
      this.state.open = true;
      this.state.resolve = resolve;
      this.state.reject = reject;

      this.$rootScope.$broadcast('babygate-open', {
        params
      });
    });
  }

  /**
   *  @ngdoc method
   *  @name Gates.Service:BabyGateService#generateTest
   *  @methodOf Gates.Service:BabyGateService
   *  @description
   *    Creates the set of numbers that the user must enter and the string version for display
   */
  generateTest() {
    this.testAnswer = [];

    const testStringValues = [];
    let index;
    let i = 0;
    while (i < this.numberOfTestStrings) {
      index = Math.floor(Math.random() * this.testStrings.length);
      testStringValues.push(this.testStrings[index]);
      this.testAnswer.push(index + 1);
      i++;
    }
    this.testString = testStringValues.join(' ');
  }

  /**
   *  @ngdoc method
   *  @name Gates.Service:BabyGateService#check
   *  @methodOf Gates.Service:BabyGateService
   *  @param {string} answer the numeric string the user entered to match against our generated values
   *  @returns {boolean} result if the user correctly matched the generated value
   *  @description
   *    Compares the value the passed to our internal value.
   *    If failed, sets state.error and generates a new test string
   */
  check(answer) {
    this.state.error = false;
    if (answer === this.testAnswer.join('')) {
      this.$rootScope.$broadcast('babygate-success', {
        params: this.params
      });
      return true;
    }
    this.state.error = true;
    this.generateTest();
    this.$rootScope.$broadcast('babygate-failed', {
      params: this.params
    });
    return false;
  }
}

export default BabyGateService;
