/**
   *  @ngdoc controller
   *  @name Seed.Controllers:WelcomeView
   *  @module Seed
   *  @description
   *    Responsible for managing the end of a successful signup flow.
   */

class WelcomeViewController {
  static get $inject() {
    return ['$state'];
  }

  constructor($state) {
    this.destinationState = $state.params.destinationState;
    this.destinationParams = $state.params.destinationParams;
  }

}

export default WelcomeViewController;
