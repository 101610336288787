/**
 *  @ngdoc controller
 *  @name Gates.Controllers:BabyGateFormController
 *  @module Boom
 *  @description
 *    Responsible for Showing the baby gate form
 */
class BabyGateFormController {}

export default BabyGateFormController;
