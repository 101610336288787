import CancelConfirmationViewController from './boom-cancel-confirmation-view-controller';

function CancelConfirmationState($stateProvider) {

  $stateProvider.state('account.cancel-confirmation', {
    parent: 'language',
    url: '/cancel-confirmation',
    controller: CancelConfirmationViewController,
    controllerAs: 'vm',
    templateUrl: 'views/cancel-confirmation/cancel-confirmation-view.html',
    userRequired: true,
    ignoreMobileWall: true
  });

}

CancelConfirmationState.$inject = ['$stateProvider'];

export default CancelConfirmationState;
