import ServicesModule from 'services.module';
import ViewsModule from 'views.module';
import ComponentsModule from 'components.module';
import FiltersModule from 'filters.module';
import DirectivesModule from 'directives.module';

import ConfigFunction from 'config.module';
import ConfigPlatform3 from 'config-platform3.module';

import GateModule from '../bower_components/boom-components/src/modules/gates/gates';
import AnalyticsModule from 'modules/analytics/analytics';

import mobileWallSetup from 'utilities/mobile-wall-setup';

angular
  .module('boom-www', [
    'platform.js',
    'ui.router',
    'ngProgress',
    'ngAnimate',
    'focus-if',
    'video.player',
    'pascalprecht.translate',
    'ngSanitize',
    ServicesModule.name,
    ViewsModule.name,
    ComponentsModule.name,
    FiltersModule.name,
    DirectivesModule.name,
    GateModule.name,
    AnalyticsModule.name
  ])
  .config(ConfigFunction)
  .run(['$transitions', 'platformjs.metadata', function($transitions, MetaDataService) {
    $transitions.onStart({}, () => {
      MetaDataService.setData();
    });
  }])

  .run(['platformjs.storage', 'platform', 'platformjs.user.login', function(StorageService, platform, UserLoginService) {
    // to avoid sharing cookies in between bo-qa-www.drama9.com and bo-dev-www.drama9.com
    // we check the environment on application bootstrapping, logout the user if it doesn't match
    // possible platform.application.env are "local", "dev", "qa", and "prod"
    if (StorageService.get('environment') !== platform.application.env) {
      UserLoginService.logout();
      StorageService.clear();
    }
  }])

  .run([
    'StateAuthentication',
    function(
      StateAuthentication
    ) {
      StateAuthentication.activate();
    }])

  .run(['$translate', 'UrlLanguage', function($translate, UrlLanguage) {
    $translate.use(UrlLanguage.activeLanguage);
  }])

  .run(['SunsetCheckService', function(SunsetCheckService) {
    SunsetCheckService.addTransitionHook();
  }])

  .run(['gates.routeInterceptor', function(gateRouteInterceptorService) {
    gateRouteInterceptorService.addTransitionHook();
  }])

  .run(['$transitions', '$anchorScroll', function($transitions, $anchorScroll) {
    //scroll to the top of the page on a route change
    $transitions.onFinish({}, (transition) => {
      const to = transition.to();
      if (to.preventScrollToTop) {
        return;
      }
      $anchorScroll('top');
    });

  }])

  .run(['$rootScope', '$transitions', function($rootScope, $transitions) {
    // save previous state
    $transitions.onStart({}, (transition) => {
      $rootScope.previousStateName = transition.from().name;
    });
  }])

  /**
   *  Determine if the there is a mobile device and routes to the mobile view
   */
  .run(['$transitions', 'UserAgentService', '$window', 'platformjs.user.account', mobileWallSetup])

  //identify users on page load
  .run(['AnalyticsService', function(AnalyticsService) {
    AnalyticsService.identify();
    AnalyticsService.bindToGateEvents();
  }])

  //Fetch UserProfileService
  .run(['platformjs.user.account', 'platformjs.user.profile', function(UserAccountService, UserProfileService) {
    if (UserAccountService.isRegistered()) {
      UserProfileService.get();
    }
  }])

  // setup Platform3
  .run(['platformjs.user', ConfigPlatform3]);
