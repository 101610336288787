/**
  *  @ngdoc filter
  *  @name Boom.Filter:TimecodeFilter
  *  @param {String} time time string formatted as hh:mm:ss (for alternate format ex. hh-mm-ss pass parseSeparator)
  *  @param {String} outputSeparator The separator to join with - defaults to colon (:)
  *  @param {String} parseSeparator The separator to parse against - defaults to colon (:)
  *  @returns {String} only minutes and seconds or hours if needed
  *  @description
  *     Gets only minutes and seconds from time string, otherwise hours if needed
  *     If not String type, or not formatted correctly it will return empty string by default.
  *
  *     Can join on a different separator (first arg)
  *     Can split on a different separator (second arg)
  *
  *  @example
  *     <p>{{ vm.episode.duration | timecode }}</p>
  *
  *     If input formatted as hh-mm-ss and output format should be hh|mm|ss
  * 
  *     <p>{{ vm.episode.duration | timecode:'|':'-' }}</p>
  */

const TimecodeFilter = () => {
  {
    return (time, outputSeparator, parseSeparator) => {
      if (!time || typeof time !== 'string') {
        return '';
      }
      if (!parseSeparator) {
        parseSeparator = ':';
      }
      if (!outputSeparator) {
        outputSeparator = ':';
      }

      const timeParts = time.split(parseSeparator);
      if (timeParts.length !== 3) {
        return '';
      }

      if (timeParts[0] === '00') {
        return [timeParts[1], timeParts[2]].join(outputSeparator);
      }
      return timeParts.join(outputSeparator);

    };
  }
};

export default TimecodeFilter;
