import BoomProfileViewController from './boom-profile-view-controller';

function BoomProfileState($stateProvider) {

  $stateProvider.state('account.profile', {
    parent: 'language',
    url: '',
    controller: BoomProfileViewController,
    controllerAs: 'vm',
    templateUrl: 'views/profile/profile-view.html',
    params: {
      destinationState: 'account.profile',
      destinationParams: null,
      newPaymentGuid: null
    },
    ageGate: {
      fullscreen: false,
      failState: 'home-upsell'
    },
    ignoreMobileWall: true,
    userRequired: true
  });

}

BoomProfileState.$inject = ['$stateProvider'];

export default BoomProfileState;
