/**
 *  @ngdoc controller
 *  @name Seed.Controllers:SignupButton
 *  @module Seed
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @description
 *    Manages button that sends a user to the signup flow and preserves their origin.
 */
class SignupButtonController {
  static get $inject() {
    return ['$state', '$stateParams'];
  }
  constructor($state, $stateParams) {
    this.$state = $state;
    this.$stateParams = $stateParams;
  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:SignupButton#getSignupDestination
  *  @methodOf Seed.Controllers:SignupButton
  *  @returns {object} destination Destination object
  *  @description
  *    Returns an object containing the state information of the desired
  *    destination post-signup.
  */
  getSignupDestination() {

    return {
      destinationState: this.destinationState || this.$state.current.name,
      destinationParams: this.destinationParams || this.$stateParams
    };

  }
}

export default SignupButtonController;
