import BoomPaymentFormController from '../boom-payment-form/boom-payment-form-controller';
import { getRange } from 'utilities/utilities';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomGiftPaymentFormController
 *  @module Boom
 *  @requires $scope
 *  @requires $anchorScroll
 *  @requires platformjs.user.profile
 *  @requires platformjs.user.login
 *  @requires moment
 *  @requires $cookies
 *  @requires platformjs.premiumProfile
 *  @description
 *    Responsible for managing the gifting payment form
 */
class BoomGiftPaymentFormController extends BoomPaymentFormController {
  static get $inject() {
    return [
      '$scope',
      '$anchorScroll',
      'platformjs.user.profile',
      'platformjs.user.login',
      'moment',
      '$cookies',
      'platformjs.premiumProfile',
      '$translate'
    ];
  }

  /* istanbul ignore next */
  constructor(
    $scope,
    $anchorScroll,
    UserProfileService,
    UserLoginService,
    moment,
    $cookies,
    PremiumProfileService,
    $translate
  ) {
    super($scope, UserProfileService, $cookies, PremiumProfileService, moment, $translate);

    this.UserLoginService = UserLoginService;
    this.$anchorScroll = $anchorScroll;
    this.moment = moment;

    this.helpMessage = {
      securityCode: [
        'MasterCard, Visa, and Discover security codes are located on the back of the card and are typically a separate group of 3 digits to the right of the signature.',
        'American Express security codes are 4 digits located on the front of the card and usually towards the right'
      ],
      subStart: [
        'This is the date your Boomerang gift e-card will be sent to your gift recipient. This is also the day that their one-year subscription will start!'
      ],
      yourInfo: [
        'Enter in your name and email so we can send you a receipt of your purchase.'
      ],
      theirInfo: [
        'Enter the name and email of your gift recipient, so we can send them the gift of Boomerang!'
      ]
    };

    let currentDate = new Date();
    this.startDateOptions = {
      month: getRange(1, 12),
      date: getRange(1, 31),
      year: getRange(currentDate.getFullYear(), currentDate.getFullYear() + 1)
    };
    this.isRecipientAdult = true;
    this.emailRegex = /^[\-\+\.\w]+@[\-\+\.\w]+\.[\-\+\.\w]+$/;



    /**
    *  @ngdoc property
    *  @name settings
    *  @propertyOf Boom.Controllers:BoomPaymentFormController
    *  @returns {object} Settings object.
    *
    *  @description
    *  Settings for Braintree setup.
    **/
    this.settings = {
      id: 'payment-form',
      hostedFields: {
        number: {
          selector: '[data-field="card-number"]'
        },
        expirationMonth: {
          selector: '[data-field="card-expirationMonth"]'
        },
        expirationYear: {
          selector: '[data-field="card-expirationYear"]'
        },
        cvv: {
          selector: '[data-field="card-cvv"]'
        },
        postalCode: {
          selector: '[data-field="card-postalCode"]'
        },
        styles: {
          'input': {
            'color': '#ffffff',
            'font-size': '1.125rem',
            'font-family': "'AvenirNextRoundedW01-De1075568', Helvetica, Arial, sans-serif"
          },
          '.invalid': {
            'color': '#ffffff'
          }
        }
      }
    };

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftPaymentFormController#checkRecipientEmail
   *  @methodOf Boom.Controllers:BoomGiftPaymentFormController
   *  @description
   *    Validates recipient email
   */
  checkRecipientEmail() {
    if (!this.recipientEmail) {
      this.invalidRecipientEmail = true;
      this.recipientEmailError = 'Valid email is required';
      return;
    }
    this.UserLoginService.checkEmail(this.recipientEmail)
      .then(() => this.invalidRecipientEmail = false)
      .catch((err) => {
        this.invalidRecipientEmail = true;
        this.recipientEmailError = err[0].message;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftPaymentFormController#checkRecipientEmailEmpty
   *  @methodOf Boom.Controllers:BoomGiftPaymentFormController
   *  @description
   *    Checks for an empty recipientEmail and clears errors if it is not set
   */
  checkRecipientEmailEmpty() {
    if (!this.recipientEmail) {
      this.invalidRecipientEmail = false;
      this.recipientEmailError = '';
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftPaymentFormController#checkSubStartDate
   *  @methodOf Boom.Controllers:BoomGiftPaymentFormController
   *  @description
   *    Validates subscription start date form
   */
  checkSubStartDate() {
    if (this.giftForm.monthSelect.$dirty &&
      this.giftForm.dateSelect.$dirty &&
      this.giftForm.yearSelect.$dirty) {

      let selectedDateString = `${this.startDate.year}/${this.startDate.month}//${this.startDate.date}`;

      let timeNow = this.moment().startOf('day');
      let allowedTime = this.moment().add(3, 'month');
      let selectedDate = this.moment(selectedDateString, 'YYYY/MM/DD');

      this.startDateErrorMessage = (!selectedDate.isValid() || selectedDate > allowedTime || selectedDate < timeNow) ? true : false;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftPaymentFormController#validateProceedButton
   *  @methodOf Boom.Controllers:BoomGiftPaymentFormController
   *  @param {string} paymentMethod selected payment method, either 'cc' or 'paypal'
   *  @description
   *    Validates form based on the selected payment method
   */
  validateProceedButton(paymentMethod) {
    if (paymentMethod === 'cc') {
      return this.giftForm.$invalid || this.invalidRecipientEmail || this.startDateErrorMessage || !this.isBraintreeFormValid();
    } else {
      return this.giftForm.$invalid || this.invalidRecipientEmail || this.startDateErrorMessage;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftPaymentFormController#isBraintreeFieldValid
   *  @methodOf Boom.Controllers:BoomGiftPaymentFormController
   *  @param {string} field the braintree field to check the validation state for
   *  @description
   *    Checks the validation state of the passed braintree form field
   */
  isBraintreeFieldValid(field) {
    return this.fields[field].isEmpty || (this.fields[field].isPotentiallyValid && this.fields[field].isValid);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftPaymentFormController#toggleDisplay
   *  @methodOf Boom.Controllers:BoomGiftPaymentFormController
   *  @description
   *    Toggles between form display and validation display
   */
  toggleDisplay() {
    this.showConfirmation = !this.showConfirmation;
    this.$anchorScroll('scroll-top');
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomGiftPaymentFormController#createPaymentMethodSuccess
  *  @methodOf Boom.Controllers:BoomGiftPaymentFormController
  *  @param {object} response Success response
  *  @description
  *    Calls {@link Boom.Controllers:PaymentForm#methods_onSuccess onSuccess} method and passes paymentMethod and giftInfo
  **/
  createPaymentMethodSuccess(response) {
    const onSuccessResponse = this.onSuccess({
      paymentMethod: response.paymentMethod,
      giftInfo: {
        premiumStartDate: `${this.startDate.year}-${this.startDate.month}-${this.startDate.date}`,
        senderName: this.senderName,
        senderEmail: this.senderEmail,
        receiverName: this.recipientName,
        receiverEmail: this.recipientEmail,
        personalMessage: this.cardMessage
      }
    });

    if (onSuccessResponse && onSuccessResponse.finally) {
      onSuccessResponse.finally(this.showSubmitComplete.bind(this));
    } else {
      this.showSubmitComplete();
    }

  }

}

export default BoomGiftPaymentFormController;
