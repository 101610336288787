import { userInfo } from "os";

export default function mobileWallSetup($transitions, UserAgentService, $window, UserAccountService) {
  //check the transition to see if it is exempt from mobile wall
  $transitions.onStart({}, (transition) => {
    const to = transition.to();
    if (to.ignoreMobileWall) {
      return true;
    }

    //if not check to see if it needs to be mobile walled and redirect
    if (UserAgentService.getUserAgent().os.family === 'Android' ||
      UserAgentService.getUserAgent().os.family === 'iOS' ||
      (typeof $window.innerWidth === 'number' && $window.innerWidth < 500)
    ) {
      //if not logged in, redirect to mobile wall
      if (UserAccountService.isRegistered()) {
        $window.location = '/account';
        return false;
      } else {
        $window.location = '/mobile';
        return false;
      }
    }

    //otherwise continue through to the state
    return true;
  });
}
;
