import UpdateProfileViewController from './update-profile-controller';

function AccountEmailState($stateProvider) {

  $stateProvider.state('account.email', {
    parent: 'account.profile',
    url: '/email',
    controller: UpdateProfileViewController,
    controllerAs: 'vm',
    templateUrl: 'views/profile/update-email-view.html',
    ignoreMobileWall: true,
    dontBreakGateFlow: true
  });

}

AccountEmailState.$inject = ['$stateProvider'];

export default AccountEmailState;
