import { formatDateString } from 'utilities/utilities';
import WelcomeViewController from 'views/welcome/welcome-view-controller';
/**
 *  @ngdoc controller
 *  @name Boom.Controllers:WelcomeView
 *  @module Boom
 *  @requires platformjs.user.profile
 *  @requires $state
 *  @requires $stateParams
 *  @requires AnalyticsService
 *  @description
 *    Responsible fetching the profile user data after successful signup flow.
 */
class BoomWelcomeViewController extends WelcomeViewController {
  static get $inject() {
    return ['platformjs.user.profile', '$state', '$stateParams', 'AnalyticsService'];
  }
  /* istanbul ignore next */
  constructor(UserProfileService, $state, $stateParams, AnalyticsService) {
    super($state);

    this.UserProfileService = UserProfileService;
    this.AnalyticsService = AnalyticsService;
    this.$stateParams = $stateParams;
    this.getProfile();

    this.start = formatDateString(this.$stateParams.start);
    this.end = formatDateString(this.$stateParams.end);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#$onInit
   *  @methodOf Boom.Controllers:WelcomeView
   *  @description
   *    Logs to Segemnt on load.
   */
  $onInit() {
    this.AnalyticsService.track('Gift Subscription Started');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#getProfile
   *  @methodOf Boom.Controllers:WelcomeView
   *  @description
   *    Gets user profile.
   */
  getProfile() {
    this.UserProfileService.get();
  }

}

export default BoomWelcomeViewController;

