/* Import Seed Providers */

import StateAuthenticationProvider from "components/state-authentication/state-authentication-provider";
import UrlLanguageProvider from "components/url-language/url-language-provider";

/* Import Custom Providers */

/* Import Seed Services */

import GoogleAnalyticsService from "services/google-analytics/google-analytics-service";
import BraintreeService from "components/braintree/braintree-service";
import OverlayService from "components/overlay/overlay-service";
import ProgressBarService from "components/progress-bar/progress-bar-service";
import StateAuthenticationService from "components/state-authentication/state-authentication-service";
import SubscribeConfigService from "components/subscribe-config/subscribe-config-service";
import UserAgentService from "services/user-agent/user-agent-service";
import AgreementModalService from "components/tos-pp-banner/tos-pp-banner-service";

/* Import Custom Services */
import SunsetCheckService from "services/sunset-check-service";

/* Import Custom Factories */

const ServicesModule = angular
  .module("boom-www.services", [])
  /* Register Seed Providers */

  .provider("StateAuthentication", StateAuthenticationProvider)
  .provider("UrlLanguage", UrlLanguageProvider)

  /* Register Custom Providers */

  .service("detectjs", [
    "$window",
    ($window) => {
      return $window.detect;
    },
  ])

  /* Register Seed Services */

  .service("GoogleAnalyticsService", GoogleAnalyticsService)
  .service("BraintreeService", BraintreeService)
  .service("OverlayService", OverlayService)
  .service("ProgressBarService", ProgressBarService)
  .service("StateAuthenticationService", StateAuthenticationService)
  .service("SubscribeConfigService", SubscribeConfigService)
  .service("UserAgentService", UserAgentService)
  .service("AgreementModalService", AgreementModalService)
  /* Register Custom Services */
  .service("SunsetCheckService", SunsetCheckService);

/* Register Custom Factories */

export default ServicesModule;
