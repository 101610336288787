import braintree from 'braintree-web';

/**
 * promise wrappers for creating the braintree instance
 * and for launching the paypal/cc flows
 */
export default {
  paypalFlow(paypalInstance) {
    return new Promise((resolve, reject) => {
      paypalInstance.tokenize(
        {
          flow: 'vault'
        },
        (tokenizeErr, paymentMethod) => {
          if (tokenizeErr) {
            return reject(tokenizeErr);
          }
          return resolve(paymentMethod);
        }
      );
    });
  },
  ccFlow(hostedFields) {
    return new Promise((resolve, reject) => {
      hostedFields.tokenize((tokenizeErr, paymentMethod) => {
        if (tokenizeErr) {
          return reject(tokenizeErr);
        }
        return resolve(paymentMethod);
      });
    });
  },
  createBraintree(config) {
    return new Promise((resolve, reject) => {
      braintree.client.create(
        {
          authorization: config.braintreeNonce.clientToken
        },
        (err, clientInstance) => {
          if (err) {
            return reject(err);
          }
          resolve(clientInstance);
        }
      );
    });
  },
  createPaypal({client}) {
    return new Promise((resolve, reject) => {
      braintree.paypal.create(
        {
          client
        },
        (err, paypalInstance) => {
          if (err) {
            return reject(err);
          }
          resolve(paypalInstance);
        }
      );
    });
  },
  createHostedFields({client, fields, styles}) {
    return new Promise((resolve, reject) => {
      braintree.hostedFields.create(
        {
          client,
          fields,
          styles
        },
        (err, hostedFields) => {
          if (err) {
            return reject(err);
          }
          resolve(hostedFields);
        }
      );
    });
  }
};
