
/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomSubscribeViewController
*  @module Boom
*  @description
*    Responsible for managing the subscribe view.
*/
class ProductsViewController {

  static get $inject() {
    /* istanbul ignore next */
    return ['$state', 'ProgressBarService', 'platformjs.user.profile', 'detectjs'];
  }

  /* istanbul ignore next */
  constructor($state, ProgressBarService, UserProfileService, detectjs) {
    this.$state = $state;
    this.ProgressBarService = ProgressBarService;
    this.UserProfileService = UserProfileService;
    this.detectjs = detectjs;
    this.mobile = false;
    this.useMobileLogoLink = true;

    this.productSelected = {};
    this.next = false;
    this.onNextChange = this.onNextChange || angular.noop;
  }

  $onInit() {
    this.checkMobile(navigator.userAgent);
    this.ready = true;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#checkMobile
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Uses detectjs to check for "Mobile" device type
   */
  checkMobile(userAgent) {
    let ua = this.detectjs.parse(userAgent);
    this.mobile = ['mobile', 'tablet'].includes(ua.device.type.toLowerCase()) ||
    ['ios', 'android'].includes(ua.os.family.toLowerCase());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#showSubscribeErrors
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @param {array} errors A list of errors.
   *  @description
   *    Sets {@link Boom.Controllers:BoomSubscribeViewController#properties_errors errors} property.
   */
  showSubscribeErrors(errors) {

    this.errors = (errors.message) ? [errors] : errors;

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#showSubscribeSuccess
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Redirects to the welcome page.
   */
  showSubscribeSuccess() {
    this.loadProfile()
      .then(profile => this.showProfile(profile))
      .catch(errors => this.showProfileFailure(errors))
      .finally(() => this.ProgressBarService.complete());
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#loadProfile
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Loads user profile.
   */
  loadProfile() {
    return this.UserProfileService.get();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#loadProfile
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Checks profile for user.subscriptionInfo.premiumFreeTrialEndDate to determine route
   */
  showProfile(profile) {
    let route = 'subscribe';

    if (profile) {
      let signupDate = new Date(profile.signupDate);

      if (profile.subscriptionInfo.premiumFreeTrialEndDate) {
        let expiration = new Date(profile.subscriptionInfo.premiumFreeTrialEndDate);
        route = (signupDate < expiration && this.mobile) ? 'success' : 'welcome';

      } else {
        route = 'welcome';
      }
    }

    this.$state.go(route);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubscribeViewController#showProfileFailure
   *  @methodOf Boom.Controllers:BoomSubscribeViewController
   *  @description
   *    Redirects user to login state if profile gets error
   */
  showProfileFailure() {
    this.$state.go('login');
  }

  goToNext() {
    let flowParams = this.getParams();
    flowParams.params.pid = this.product.id;
    this.$state.go(flowParams.destination, flowParams.params, flowParams.options);
  }

  getParams() {
    let flowParamsNext = {};
    if (this.$state.params.flowParams) {
      flowParamsNext = Object.assign({}, this.$state.params.flowParams[0]);
      flowParamsNext.params = (!!flowParamsNext.params ? flowParamsNext.params : {});
    } else {
      flowParamsNext = {
        destination: 'signup',
        params: {}
      };
    }

    return flowParamsNext;
  }

  selectProduct(product) {
    this.product = product;
  }
}

export default ProductsViewController;
