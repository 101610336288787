import BoomProductSelectionController from './boom-product-selection-controller';

const BoomProductSelectionComponent = {
  bindings: {
    onFailure: '&',
    onProductSelect: '&',
    onReady: '&',
    series: '<',
    episode: '<',
    catalog: '<',
    allowPromocodeEntry: '<',
    next: '<',
    onNextChange: '&',
    errors: '<'
  },
  controller: BoomProductSelectionController,
  controllerAs: 'vm',
  templateUrl: 'components/product-selection/boom-product-selection.html'
};

export default BoomProductSelectionComponent;
