import { default as Platform3, configurePlatform } from './platform3.module.js';

function ConfigPlatform3(UserService) {
  return Platform3.services.consumer
    .get({
      name: 'www'
    })
    .then(config => {
      if (UserService.user && UserService.user.token) {
        config.userToken = UserService.user.token;
      }

      //configure the plat3 instance
      configurePlatform(config);
    });
}

export default ConfigPlatform3;
