/**
 *  @ngdoc controller
 *  @name Gates.Controllers:BabyGateController
 *  @module Boom
 *  @description
 *    Responsible for Showing the baby gate
 *  @fires babygate-close
 */
class BabyGateController {
  static get $inject() {
    return ['gates.babyGate', '$rootScope'];
  }

  constructor(BabyGateService, $rootScope) {
    this.answer = '';
    this.BabyGateService = BabyGateService;

    this.$rootScope = $rootScope;
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:BabyGateController#isFullscreenVisible
   *  @methodOf Gates.Controllers:BabyGateController
   *  @returns {boolean} isVisible If we are in fullscreen mode
   *  @description
   *    Checks the internal baby gate service state and configuration to
   *    determine if the fullscreen version of the baby gate should be visible
   */
  isFullscreenVisible() {
    return !!(
      this.BabyGateService.state.open &&
      this.BabyGateService.state.config &&
      this.BabyGateService.state.config.fullscreen
    );
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:BabyGateController#isModalVisible
   *  @methodOf Gates.Controllers:BabyGateController
   *  @returns {boolean} isVisible If we are in modal mode
   *  @description
   *    Checks the internal baby gate service state and configuration to
   *    determine if the modal version of the baby gate should be visible
   */
  isModalVisible() {
    return (
      this.BabyGateService.state.open &&
      (!this.BabyGateService.state.config ||
        (this.BabyGateService.state.config &&
          !this.BabyGateService.state.config.fullscreen))
    );
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:BabyGateController#check
   *  @methodOf Gates.Controllers:BabyGateController
   *  @description
   *    Proxies our answer to the baby gate service for checking.  If successful,
   *    updates the service's state and calls the resolve method.
   *    Always clears our answer in case another attempt needs to be made
   */
  check() {
    if (this.BabyGateService.check(this.answer)) {
      this.BabyGateService.state.open = false;
      this.BabyGateService.state.resolve();
    }
    this.answer = '';
  }

  /**
   *  @ngdoc method
   *  @name Gates.Controllers:BabyGateController#close
   *  @methodOf Gates.Controllers:BabyGateController
   *  @description
   *    Updates the service's state and calls the reject method passing a config param
   *    to indicate the the user closed the modal, it wasn't failed.
   */
  close() {
    this.BabyGateService.state.open = false;
    this.BabyGateService.state.reject({
      closed: true
    });
    this.$rootScope.$broadcast('babygate-close');
  }
}

export default BabyGateController;
