// This component is currently not in use and is pending the Premium API's ability to change your payment method or cancel it.

import PaymentMethodsFormController from './payment-methods-form-controller';

const PaymentMethodsComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    onSubmit: '&',
    onSuccess: '&'
  },
  controller: PaymentMethodsFormController,
  controllerAs: 'vm',
  templateUrl: 'components/payment-methods-form/payment-methods-form.html'
};

export default PaymentMethodsComponent;
