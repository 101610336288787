function GuideState($stateProvider) {

  $stateProvider.state('guide', {
    parent: 'language',
    abstract: true,
    url: '/guide',
    templateUrl: 'views/guide/guide.html'
  });

}

GuideState.$inject = ['$stateProvider'];

export default GuideState;
