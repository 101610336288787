import StateAuthentication from './state-authentication-service';

/**
 *  @ngdoc service
 *  @name Seed.Services:StateAuthenticationProvider
 *  @module Seed
 *  @description
 *    Provider for StateAuthentication service.
 */
class StateAuthenticationProvider {
  constructor() {
    this.authenticationParameter = 'userRequired';
    this.authenticationState = 'login';

    var self = this;
    this.$get = StateAuthentication.$inject.concat([
      function ServiceGetter($transitions, $state, UserAccountService) {
        return new StateAuthentication($transitions, $state, UserAccountService, self);
      }
    ]);
  }
  /**
   *  @ngdoc method
   *  @name Seed.Services:StateAuthenticationProvider#getAuthenticationParameter
   *  @methodOf Seed.Services:StateAuthenticationProvider
   *  @returns {string} Parameter name.
   *  @description
   *    Gets the parameter name used for state authentication.
   */
  getAuthenticationParameter() {
    return this.authenticationParameter;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:StateAuthenticationProvider#getAuthenticationState
   *  @methodOf Seed.Services:StateAuthenticationProvider
   *  @returns {string} State name.
   *  @description
   *    Gets the state name used as a redirect target for authentication.
   */
  getAuthenticationState() {
    return this.authenticationState;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:StateAuthenticationProvider#setAuthenticationParameter
   *  @methodOf Seed.Services:StateAuthenticationProvider
   *  @param {string} value Parameter name.
   *  @description
   *    Sets the parameter name used for state authentication.
   */
  setAuthenticationParameter(value) {
    this.authenticationParameter = value;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Services:StateAuthenticationProvider#setAuthenticationState
   *  @methodOf Seed.Services:StateAuthenticationProvider
   *  @param {string} value State name.
   *  @description
   *    Sets the state name used as a redirect target for authentication.
   */
  setAuthenticationState(value) {
    this.authenticationState = value;
  }
}

export default StateAuthenticationProvider;
