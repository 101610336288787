function GeneralState($stateProvider) {

  $stateProvider.state('guide.general', {
    parent: 'language',
    url: '/general',
    templateUrl: 'views/guide/general/general.html'
  });

}

GeneralState.$inject = ['$stateProvider'];

export default GeneralState;
