import BoomPromoCodeViewController from './boom-promocode-view-controller';

function BoomPromoCode($stateProvider) {

  $stateProvider.state('promo', {
    parent: 'language',
    url: '/promo',
    templateUrl: 'views/promocode/boom-promocode-view.html',
    controller: BoomPromoCodeViewController,
    controllerAs: 'vm',
    ignoreMobileWall: true
  });

}

BoomPromoCode.$inject = ['$stateProvider'];

export default BoomPromoCode;
