/**
 *  @ngdoc controller
 *  @name Boom.Controllers:LanguageSelector
 *  @module Boom
 *  @requires UrlLanguage
 *  @description
 *    Handles changing of the active language.
 */
class LanguageSelector {
  static get $inject() {
    return ['UrlLanguage'];
  }

  constructor(UrlLanguage) {
    this.UrlLanguage = UrlLanguage;
    this.selectorIsOpen = this.selectorIsOpen || false;
    this.headerLangMap = {
      en: 'ENG',
      es: 'ESP'
    };
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:LanguageSelector#updateLocale
   * @methodOf Boom.Controllers:LanguageSelector
   * @description
   *   Calls method bound to onChangeMethod, passing key as an object.
   * @param {String} key two-character string of the language
   */
  updateLocale(key) {
    if (!key) {
      return;
    }

    this.onChangeLanguage({
      key
    });

    this.selectorIsOpen = false;
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:LanguageSelector#toggleSelector
   * @methodOf Boom.Controllers:LanguageSelector
   * @description
   *  Toggles isSelectorOpen and prevents further propagation to the
   *  body click event.
   * @param {Object} $event Click event when clicking on the toggle menu.
   */
  toggleSelector($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.selectorIsOpen = !this.selectorIsOpen;
  }

  handleKeypress($event, key) {
    if ($event.keyCode === 13) {
      this.updateLocale(key);
    }
  }

  handleKeypressToggle($event) {
    if ($event.keyCode === 13) {
      this.toggleSelector($event);
    }
  }

  handleLanguageToggleBlur($event) {
    if (
      !$event.relatedTarget ||
      !$event.relatedTarget.dataset ||
      $event.relatedTarget.dataset.buttonType !== 'language-selector'
    ) {
      this.selectorIsOpen = false;
    }
  }
}

export default LanguageSelector;
