/**
  *  @ngdoc filter
  *  @name Boom.Filter:OnlyDollar
  *  @param {Number} amount currency amount. It takes integer or floating number
  *  @returns {string} only the dollar amount
  *  @description
  *     Gets only the dollar amount from integer / floating number without rounding.
  *     If not number type, it will return empty string by default.
  *
  *  @example
  *     <p>{{ vm.price | onlyDollar }}</p>
  */

const OnlyDollarFilter = () => {
  {
    return (amount) => {

      if (typeof amount === 'number') {

        return amount.toString().split('.')[0];

      }

      return '';

    };
  }
};

export default OnlyDollarFilter;
