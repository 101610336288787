import ChangePasswordFormController from 'components/change-password-form/change-password-form-controller';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomRedemptionForm
*  @module Boom
*  @description
*    Responsible for changing user password with token.
*/
class BoomRedemptionFormController extends ChangePasswordFormController {

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomRedemptionForm#showSubmitComplete
   *  @methodOf Boom.Controllers:BoomRedemptionForm
   *  @description
   *    Called on completion of request for change password.
   * 
   *    We need to override this method in seed since this component is used in the redemption view
   *    and the redemption view will make a second request to log the user in. 
   *    We do not want the button to be updated to a finished state until that is done. 
   */
  showSubmitComplete() {}

  /**
  * @ngdoc method
  * @name Boom.Controllers:BoomRedemptionForm#showSubmitSuccess
  * @methodOf Boom.Controllers:BoomRedemptionForm
  * @description
  * Called on success of request for change password.
  */
  showSubmitSuccess() {

    this.onSuccess({
      password: this.password
    });

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomRedemptionForm#showSubmitErrors
   *  @methodOf Boom.Controllers:BoomRedemptionForm
   *  @param {array} errors Errors
   *  @description
   *    Called on failure of request for change password.
   */
  showSubmitErrors(errors) {

    this.submitting = false;

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomRedemptionForm#submit
   *  @methodOf Boom.Controllers:BoomRedemptionForm
   *  @description
   *    Validates password match and submits form data to make request for change password.
   */
  submit() {
    this.errors = [];
    if (this.password !== this.password2) {
      this.onError({
        errors: [{
          message: 'Passwords do not match.'
        }]
      });
    } else {
      /* istanbul ignore next */
      super.submit();
    }
  }
}

export default BoomRedemptionFormController;
