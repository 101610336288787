import GiftController from './gift-controller';

function GiftState($stateProvider) {

  $stateProvider.state('gifting', {
    parent: 'language',
    url: '/gift',
    controllerAs: 'vm',
    controller: GiftController,
    template: '<div></div>',
    ignoreMobileWall: true
  });

}

GiftState.$inject = ['$stateProvider'];

export default GiftState;
