/**
 *  @ngdoc controller
 *  @name Boom.Controllers:ThatsAllFolksViewController
 *  @module Boom
 *  @description
 *    Responsible redirecting Six Flags promo users through an age gate before subscribe
 */
class ThatsAllFolksViewController {

  static get $inject() {
    /* istanbul ignore next */
    return [
      'ProgressBarService',
      'platformjs.collection',
      'UrlLanguage',
      '$sce'
    ];
  }

  /* istanbul ignore next */
  constructor(ProgressBarService, CollectionService, UrlLanguage, $sce) {
    this.UrlLanguage = UrlLanguage;
    this.ProgressBarService = ProgressBarService;
    this.CollectionService = CollectionService;
    this.$sce = $sce;
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ThatsAllFolksViewController#$onInit
   *  @methodOf Boom.Controllers:ThatsAllFolksViewController
   *  @description
   *    Loads and displays thatsallfolks metadata
   */
  $onInit() {
    this.ProgressBarService.start();

    this.CollectionService.get({
      id: `c298`
    })
      .then(collection => this.setStylesFromCollection(collection))
      .finally(() => this.ProgressBarService.complete());
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:ThatsAllFolksViewController#setStylesFromCollection
   * @methodOf Boom.Controllers:ThatsAllFolksViewController
   * @param {object} collection The collection to pull the thatsallfolks metadata from
   * @description
   *    Puts together the styles and messaging from the collection to display to the user
   */
  setStylesFromCollection(collection) {
    const isEnglish = this.UrlLanguage.activeLanguage === 'en';

    const metadata = collection.metadata;

    this.thatsallfolksImageUrl = metadata.image;

    this.thatsallfolksBodyStyle = {
      'background-color': metadata.bg_color
    };

    this.thatsallfolksHeader = isEnglish ? metadata.header : metadata.header_es;
    this.thatsallfolksHeaderStyle = {
      'color': metadata.header_font_color,
      'font-size': `${metadata.header_font_size}px`,
    };

    this.thatsallfolksMessage = this.$sce.trustAsHtml(
      isEnglish ? metadata.body : metadata.body_es
    );
    this.thatsallfolksMessageStyle = {
      'color': metadata.body_font_color,
      'font-size': `${metadata.body_font_size}px`
    };

    this.showThatsAllFolksCta = metadata.show_button && metadata.show_button.toLowerCase() === 'true';


    this.thatsallfolksCtaLabel = isEnglish ? metadata.cta : metadata.cta_es;

    this.thatsallfolksCtaText = isEnglish ? metadata.button : metadata.button_es;
    this.thatsallfolksCtaUrl = metadata.button_link;
    this.thatsallfolksCtaStyle = {
      'background-color': metadata.button_color,
      'color': metadata.button_text_color,
      'font-size': `${metadata.button_font_size}px`
    };

    this.thatsallfolksFollowUpPrompt = isEnglish ? metadata.additional_text : metadata.additional_text_es;
    this.thatsallfolksFollowUpUrl = metadata.additional_link;

    this.thatsallfolksFollowUpLinkStyle = {
      color: this.thatsallfolksCtaStyle['background-color']
    };
  }
}

export default ThatsAllFolksViewController;
