import BoomProductSelectionController from 'components/product-selection/boom-product-selection-controller';
/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomGiftingController
 *  @module Boom
 *  @requires angular.$state
 *  @requires platformjs.catalog
 *  @requires angular.$attrs
 *  @requires AnalyticsService
 *  @requires platformjs.catalogV2
 *  @description
 *    Responsible for allowing users to purchase gifts.
 */
class BoomGiftingController extends BoomProductSelectionController {
  static get $inject() {
    return ['$location', '$state', 'platformjs.catalog', '$attrs', 'AnalyticsService', 'platformjs.catalogV2'];
  }

  /* istanbul ignore next */
  constructor($location, $state, CatalogService, $attrs, AnalyticsService, CatalogServiceV2) {
    super($location, $state, CatalogService, $attrs, AnalyticsService);
    this.CatalogServiceV2 = CatalogServiceV2;
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftingController#$onInit
   *  @methodOf Boom.Controllers:BoomGiftingController
   *  @description
   *    Loads catalog.
   */
  $onInit() {
    //If the catalog is not being passed in, then make a fetch
    if (!this.$attrs.catalog) {
      this.CatalogServiceV2.list({
        show: 'gift,sub'
      }) // need to pass this parameter to get gift product
        .then(catalog => this.showGiftingCatalog(catalog))
        .catch(errors => this.showFailure(errors));
    } else {
      this.onReady();
    }
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftingController#showGiftingCatalog
   *  @methodOf Boom.Controllers:BoomGiftingController
   *  @param {object} catalog CatalogV2 object.
   *  @description
   *    Displays gift products if isGift set to true.
   */
  showGiftingCatalog(catalog) {
    this.showEmptyProductsMessage = !this.isPopulatedProductCatalog(catalog);

    const originalProducts = catalog.productsLookup();

    this.products = Object.keys(originalProducts).map((key) => originalProducts[key]);
    this.products.forEach((product, index) => {
      if (product.isGift) {
        this.suggestedProductId = product.id;
      }
    });
    this.selectProduct(originalProducts[this.suggestedProductId || catalog.defaultProductId]);
    this.onReady();

  }
}

export default BoomGiftingController;
