import AgeGateAlertController from './age-gate-alert-controller';

const AgeGateAlertComponent = {
  bindings: {
    visible: '<',
    locked: '<',
    lockedText: '<',
    lockedImage: '<',
    lockedButtonText: '<',
    dismiss: '&',
    close: '&'
  },
  controller: AgeGateAlertController,
  controllerAs: 'vm',
  templateUrl: 'modules/gates/components/age-gate-alert/age-gate-alert.html'
};

export default AgeGateAlertComponent;
