import ChangePasswordViewController from 'views/change-password/change-password-view-controller';

function ChangePasswordState($stateProvider) {

  $stateProvider.state('change-password', {
    parent: 'language',
    url: '/change-password/:token',
    templateUrl: 'views/change-password/change-password-view.html',
    controller: ChangePasswordViewController,
    controllerAs: 'vm',
    ignoreMobileWall: true
  });

}

ChangePasswordState.$inject = ['$stateProvider'];

export default ChangePasswordState;
