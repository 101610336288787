import HomeUpsellViewController from 'views/home-upsell/home-upsell-view-controller';

function HomeUpsellState($stateProvider) {

  $stateProvider.state('home-upsell', {
    parent: 'language',
    url: '/',
    controller: HomeUpsellViewController,
    controllerAs: 'vm',
    templateUrl: 'views/home-upsell/home-upsell-view.html',
    ignoreMobileWall: true
  });

}

HomeUpsellState.$inject = ['$stateProvider'];

export default HomeUpsellState;
