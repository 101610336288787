import BabyGateFormController from './baby-gate-form-controller';

const BabyGateFormComponent = {
  bindings: {
    visible: '<',
    answerText: '<',
    answerModel: '=',
    error: '<',
    check: '&'
  },
  controller: BabyGateFormController,
  controllerAs: 'vm',
  templateUrl: 'modules/gates/components/baby-gate-form/baby-gate-form.html'
};

export default BabyGateFormComponent;
