import PaymentFormController from './payment-form-controller';

const PaymentFormComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    onSubmit: '&',
    onSuccess: '&',
    options: '<',
    token: '<'
  },
  controller: PaymentFormController,
  controllerAs: 'vm',
  templateUrl: 'components/payment-form/payment-form.html',
  transclude: {
    paymentFormButtonText: '?paymentFormButtonText'
  }
};

export default PaymentFormComponent;
