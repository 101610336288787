/* Top-level Language State for handling the lang url prefix */
function LanguageState($stateProvider, UrlLanguageProvider) {
  $stateProvider.state('language', {
    url: '/{lang:(?:' + UrlLanguageProvider.getSupportedUrlLanguageCodes().join('|') + ')}',
    abstract: true,
    template: '<div ui-view=""></div>',
    params: {
      lang: {
        squash: true,
        value: 'en'
      }
    }
  });
}

LanguageState.$inject = ['$stateProvider', 'UrlLanguageProvider'];

export default LanguageState;
