/**
*  @ngdoc controller
*  @name Seed.Controllers:ProfileView
*  @module Seed
*  @requires platformjs.user.profile
*  @requires ProgressBarService
*  @description
*    Responsible for managing the profile information for a user.
*/
class ProfileViewController {
  static get $inject() {
    return ['platformjs.user.profile', 'ProgressBarService'];
  }
  constructor(UserProfileService, ProgressBarService) {
    this.UserProfileService = UserProfileService;
    this.ProgressBarService = ProgressBarService;

    /**
     *  @ngdoc property
     *  @name displayedForms
     *  @propertyOf Seed.Controllers:ProfileView
     *  @returns {object} Object containing currently displayed forms
     *  @description
     *    Object containing currently displayed forms.
     */
    this.displayedForms = {};

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:ProfileView
     *  @returns {array} Error list
     *  @description
     *    List of current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name profile
     *  @propertyOf Seed.Controllers:ProfileView
     *  @returns {object} User profile
     *  @description
     *    Current user profile object.
     */
    this.profile = null;

    /**
     *  @ngdoc property
     *  @name success
     *  @propertyOf Seed.Controllers:ProfileView
     *  @returns {string} Update success message
     *  @description
     *    Success message.
     */
    this.success = '';
  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:ProfileView#$onInit
  *  @methodOf Seed.Controllers:ProfileView
  *  @description
  *    Makes initial request for a profile object to display.
  */
  $onInit() {

    this.ProgressBarService.start();

    this.loadProfile()
      .then(profile => this.showProfile(profile))
      .catch(errors => this.showProfileFailure(errors))
      .finally(() => this.ProgressBarService.complete());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileView#toggleForm
   *  @methodOf Seed.Controllers:ProfileView
   *  @param {string} form Form to toggle
   *  @description
   *    Toggles whether a form should be displayed.
   */
  toggleForm(form) {

    this.displayedForms[form] = !this.displayedForms[form];

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileView#loadProfile
   *  @methodOf Seed.Controllers:ProfileView
   *  @description
   *    Loads user profile.
   */
  loadProfile() {

    return this.UserProfileService.get();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileView#showProfile
   *  @methodOf Seed.Controllers:ProfileView
   *  @param {object} profile User profile
   *  @description
   *    Called on the success of the initial request for a user's profile.
   */
  showProfile(profile) {

    this.profile = profile;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileView#showProfileFailure
   *  @methodOf Seed.Controllers:ProfileView
   *  @param {array} errors Error list
   *  @description
   *    Called on the failure of the initial request for a user's profile.
   */
  showProfileFailure(errors) {

    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileView#showUpdateFailure
   *  @methodOf Seed.Controllers:ProfileView
   *  @param {array} errors Error list
   *  @description
   *    Called on the failure of updating a user's profile.
   */
  showUpdateFailure(errors) {

    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileView#showUpdateSuccess
   *  @methodOf Seed.Controllers:ProfileView
   *  @param {object} profile Updated profile
   *  @description
   *    Called on the success of updating a user's profile.
   */
  showUpdateSuccess(form) {

    this.success = form;
    this.errors = [];
    this.toggleForm(form);

  }
}

export default ProfileViewController;
