/**
 *  @ngdoc controller
 *  @name Boom.Controllers:AccountViewController
 *  @module Boom
 *  @description
 *    Responsible for displaying Cancel View.
 *  @requires angular.$state
 */
class AccountViewController {
  static get $inject() {
    return [
      '$state'
    ];
  }

  constructor($state) {
    this.$state = $state;
  }

}

export default AccountViewController;
