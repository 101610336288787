import ChangePasswordFormController from 'components/change-password-form/change-password-form-controller';

/**
*  @ngdoc controller
*  @name Boom.Controllers:ChangePasswordForm
*  @module Boom
*  @description
*    Responsible for changing user password with token.
*/
class BoomChangePasswordFormController extends ChangePasswordFormController {

  static get $inject() {
    return ['platformjs.user.login'];
  }

  /* istanbul ignore next */
  constructor(LoginService) {
    super(LoginService);

    /**
     *  @ngdoc property
     *  @name passwordVisibility
     *  @propertyOf Boom.Controllers:ChangePasswordForm
     *  @description
     *    state of show/hide password buttons
     */
    this.passwordVisibility = {
      new: false,
      new2: false
    };

    /**
     *  @ngdoc property
     *  @name errorHighlightStatus
     *  @propertyOf Boom.Controllers:ChangePasswordForm
     *  @description
     *    state of error fileds on password inputs
     */
    this.errorHighlightStatus = {
      new: false,
      new2: false
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ChangePasswordForm#$onChanges
   *  @methodOf Boom.Controllers:ChangePasswordForm
   *  @description
   *    Check for changes in password
   */
  $onChanges(changes) {
    this.errorHighlight();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ChangePasswordForm#togglePasswordVisibility
   *  @methodOf Boom.Controllers:ChangePasswordForm
   *  @description
   *    Toggle to show password as text instead of password.
   */
  togglePasswordVisibility(version) {
    this.passwordVisibility[version] = !this.passwordVisibility[version];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ChangePasswordForm#errorHighlighting
   *  @methodOf Boom.Controllers:ChangePasswordForm
   *  @description
   *    Toggle error highlighting
   */
  errorHighlight() {
    forEach(this.errors, (error) => {
      switch (error.code) {
        case 99999:
          this.errorHighlightStatus.new = true;
          this.errorHighlightStatus.new2 = true;
          break;

        case 1001:
          this.errorHighlightStatus.new = true;
          this.errorHighlightStatus.new2 = true;
          break;

        default:
          this.errorHighlightStatus.new = false;
          this.errorHighlightStatus.new2 = false;
      }
    });

    if (this.errors === undefined || this.errors.length == 0) {
      this.errorHighlightStatus = {
        new: false,
        new2: false
      }
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ChangePasswordForm#submit
   *  @methodOf Boom.Controllers:ChangePasswordForm
   *  @description
   *    Validates password match and submits form data to make request for change password.
   */
  submit() {
    this.errors = [];
    if (this.password !== this.password2) {
      this.onError({
        errors: [{
          message: 'Passwords do not match.',
          code: 99999
        }]
      });
    } else {
      /* istanbul ignore next */
      super.submit();
    }
  }
}

export default BoomChangePasswordFormController;
