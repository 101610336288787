/* Import Custom Directives */

import stateClasses from 'directives/state-classes/state-classes-directive';
import fallbackImg from 'directives/fallback-images/fallback-img-directive';
import fallbackBackground from 'directives/fallback-images/fallback-background-directive';

const DirectivesModule = angular.module('boom-www.directives', [])

  /* Register Custom Directives */

  .directive('stateClasses', stateClasses)
  .directive('fallbackImg', fallbackImg)
  .directive('fallbackBackground', fallbackBackground);

export default DirectivesModule;
