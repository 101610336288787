/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomPromoCodeViewController
*  @module Boom
*  @requires platformjs.user.account
*  @description
*    Responsible for managing the promocode view.
*/
class BoomPromoCodeViewController {
  static get $inject() {
    return ['platformjs.user.account'];
  }

  /* istanbul ignore next */
  constructor(UserAccountService) {
    this.UserAccountService = UserAccountService;
  }

  onError() {
    this.outOfKansas = true;
  }

}

export default BoomPromoCodeViewController;
