import SignupFormController from 'components/signup-form/signup-form-controller';
import { setPlatform3AuthToken } from '../../platform3';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomSignupFormController
*  @module Boom
*  @requires $stateParams
*  @requires $rootScope
*  @requires $state
*  @requires $cookies
*  @requires platformjs.user.login
*  @requires platformjs.user.preferences.email
*  @requires AnalyticsService
*  @requires platformjs.storage
*  @requires platform
*  @requires platformjs.user.profile
*  @requires platformjs.user
*  @description
*    Responsible for signing up a user.
*/

class BoomSignupFormController extends SignupFormController {
  static get $inject() {
    return [
      '$stateParams',
      '$rootScope',
      '$state',
      '$cookies',
      'platformjs.user.login',
      'platformjs.user.preferences.email',
      'AnalyticsService',
      'platformjs.storage',
      'platform',
      'platformjs.user.profile',
      'AgreementModalService',
      'platformjs.user'
    ];
  }

  /* istanbul ignore next */
  constructor(
    $stateParams,
    $rootScope,
    $state,
    $cookies,
    LoginService,
    PreferencesEmailService,
    AnalyticsService,
    StorageService,
    platform,
    UserProfileService,
    AgreementModalService,
    UserService) {

    super(LoginService, PreferencesEmailService);
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$cookies = $cookies;
    this.analytics = AnalyticsService;
    this.StorageService = StorageService;
    this.UserProfileService = UserProfileService;
    this.passwordVisibility = false;
    this.AgreementModalService = AgreementModalService;
    this.UserService = UserService;

    /**
     *  @ngdoc property
     *  @name ENV
     *  @propertyOf Boom.Controllers:BoomSignupFormController
     *  @returns {string} environment
     *  @description
     *    Application environment, possible values: local, dev, qa, and prod
     */
    this.ENV = platform.application.env;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#showSignupSuccess
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Called after a successful registration
   */
  showSignupSuccess() {
    this.setUserAuthToken();
    this.setEnvCookie();
    /* istanbul ignore next */
    super.showSignupSuccess();

    this.AgreementModalService.agreeTerms();
    this.AgreementModalService.clearAnonCookie();
  }

  setUserAuthToken() {
    if (this.UserService.user && this.UserService.user.token) {
      setPlatform3AuthToken(this.UserService.user.token);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#setEnvCookie
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Set environment cookie.
   */
  setEnvCookie() {
    this.StorageService.set('environment', this.ENV);
    //set the users language on their profile to whatever
    //they currently have selected
    this.$rootScope.$$childHead.rm.changeLanguage();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#submit
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Submits form data to signup request.  Make Analytics call.
   *    Notifies the banner that the user might change
   */
  submit() {
    this.AgreementModalService.userInFlux();
    /* istanbul ignore next */
    super.submit();
    let eventName = 'Account Creation Submitted';
    if (this.$stateParams.premiumLevel == 1) {
      eventName += ' Monthly';
    } else if (this.$stateParams.premiumLevel == 12) {
      eventName += ' Annual';
    }
    this.analytics.track(eventName);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#showSignupFailure
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @param {array} errors Errors
   *  @description
   *    Called on failure of signup request. redirect to login if user exists
   */
  showSignupFailure(errors) {
    this.errors = errors;
    errors.forEach(error => {
      if (error.code === '1008') {
        return this.LoginService.login(this.email, this.password)
          .then(this.existingUser.bind(this))
          .catch(this.showLoginError.bind(this))
          .finally(this.showLoginFinally.bind(this));

      }
      this.onError({
        errors: errors
      });
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#errorCheck
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Check error codes to highlight appropriate input field. List of applicable codes is in the function
   */
  errorCheck(type) {
    if (this.errors && this.errors.length > 0) {
      let errorCodes = {};
      errorCodes.email = ['1008'];
      for (let i = 0; i < this.errors.length; i++) {
        if (errorCodes[type].includes(this.errors[i].code)) {
          return true;
        }
      }
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#showSignupFinally
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Called after a signup request. Notifies the banner that the user is no longer going to change
   */
  showSignupFinally() {
    /* istanbul ignore next */
    super.showSignupFinally();
    this.AgreementModalService.userOutOfFlux();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#existingUser
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @param {array} user User
   *  @description
   *    Called on success of login request and existing user.
   */
  existingUser(response) {
    if (response.success === true) {
      this.setUserAuthToken();
      this.analytics.identify();
      this.setEnvCookie();

      this.UserProfileService.get()
        .then(() => {
          this.$state.go('welcome-back');
        })
        .catch(errors => this.showLoginError(errors));
    } else {
      this.redirectTo('signup');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#onLoginSuccess
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @param {array} user User
   *  @description
   *    Called on success of login request.
   */
  onLoginSuccess(response) {
    if (response.success === true) {
      this.setUserAuthToken();
      this.analytics.identify();
      this.setEnvCookie();

      this.UserProfileService.get()
        .then(user => this.redirectUser(user))
        .catch(errors => this.showLoginError(errors));

    } else {
      this.redirectTo('signup');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#redirectUser
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @param {object} user User object
   *  @description
   *    Redirects user to appropriate route based on premiumLevel
   */
  redirectUser(user) {
    if (user) {
      if (user.subscriptionInfo.premiumLevel != null) {
        this.redirectTo('welcome');
      } else {
        this.redirectTo('subscribe');
      }
    } else {
      this.redirectTo('signup');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupView#redirectTo
   *  @methodOf Boom.Controllers:BoomSignupView
   *  @param {string} state the state name
   *  @description
   *    Changes state.
   */
  redirectTo(state) {
    this.$state.go(state);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#onLoginError
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @param {array} errors Errors
   *  @description
   *    Called on failure of login request.
   */
  showLoginError(errors) {
    this.redirectTo('signup');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#onLoginFinally
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Called at the end of login request.
   */
  showLoginFinally() {
    this.submitting = false;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupFormController#togglePasswordVisibility
   *  @methodOf Boom.Controllers:BoomSignupFormController
   *  @description
   *    Toggle to show password as text instead of password.
   */
  togglePasswordVisibility() {
    this.passwordVisibility = !this.passwordVisibility;
  }

}

export default BoomSignupFormController;
