/* Import Seed Components */

import ChangePasswordFormComponent from 'components/change-password-form/change-password-form-component';
import ErrorListComponent from 'components/error-list/error-list-component';
import ForgotPasswordFormComponent from 'components/forgot-password-form/forgot-password-form-component';
import LoginButtonComponent from 'components/login-button/login-button-component';
import LoginFormComponent from 'components/login-form/boom-login-form-component';
import NotificationSettingsComponent from 'components/notification-settings/notification-settings-component';
import PaymentFormComponent from 'components/payment-form/payment-form-component';
import PaymentMethodsFormComponent from 'components/payment-methods-form/payment-methods-form-component';
import PaymentSelectionComponent from 'components/payment-selection/payment-selection-component';
import ProductPromoComponent from 'components/product-promo/product-promo-component';
import ProductSelectionComponent from 'components/product-selection/boom-product-selection-component';
import ProductSelectionPromoComponent from 'components/product-selection-promo/boom-product-selection-promo-component';
import AccountProfileComponent from 'components/account-profile/boom-account-profile-component';
import SignupButtonComponent from 'components/signup-button/signup-button-component';

/* Import Custom Components */

import BoomLogoutButtonComponent from 'components/boom-logout-button/boom-logout-button-component';
import BoomPaymentFormComponent from 'components/boom-payment-form/boom-payment-form-component';
import BoomGiftPaymentFormComponent from 'components/boom-gift-payment-form/boom-gift-payment-form-component';
import BoomFormHelpComponent from 'components/boom-form-help/boom-form-help-component';
import BoomSignupFormComponent from 'components/boom-signup-form/boom-signup-form-component';
import BoomUpsellComponent from 'components/boom-upsell/boom-upsell-component';
import BoomUpsellPromoComponent from 'components/boom-upsell-promo/boom-upsell-promo-component';
import BoomSubscribeFormComponent from 'components/boom-subscribe-form/boom-subscribe-form-component';
import BoomPromoCodeComponent from 'components/boom-promocode/boom-promocode-component';
import BoomPromoCodeStandaloneComponent from 'components/boom-promocode-standalone/boom-promocode-standalone-component';
import BoomChangePasswordFormComponent from 'components/boom-change-password-form/boom-change-password-component';
import ActiveSubscriptionComponent from 'components/active-subscription/active-subscription-component';
import BoomGiftingComponent from 'components/boom-gifting/boom-gifting-component';
import BoomRedemptionFormComponent from 'components/boom-redemption-form/boom-redemption-form-component';
import CancelSurveyComponent from 'components/cancel-survey/cancel-survey-component';
import AgreementModalService from 'components/tos-pp-banner/tos-pp-banner-component';
import OpenAppButtonComponent from 'components/open-app-button/open-app-button-component';
import BoomErrorListComponent from 'components/boom-error-list/boom-error-list-component';
import BoomForgotPasswordFormComponent from 'components/boom-forgot-password-form/forgot-password-form-component';
import BoomUpdatePaymentFormComponent from 'components/boom-update-payment-form/boom-update-payment-form-component';

/* Boom Components Components */
import MainHeaderComponent from '../bower_components/boom-components/src/components/main-header/main-header-component.module.js';
import LanguageSelectorComponent from '../bower_components/boom-components/src/components/language-selector/language-selector-component.module.js';
import MainFooterComponent from '../bower_components/boom-components/src/components/main-footer/main-footer-component.module.js';

const ComponentsModule = angular.module('boom-www.components', [])

  /* Register Seed Components */

  .component('errorList', ErrorListComponent)
  .component('forgotPasswordForm', BoomForgotPasswordFormComponent)
  .component('loginButton', LoginButtonComponent)
  .component('loginForm', LoginFormComponent)
  .component('notificationSettings', NotificationSettingsComponent)
  .component('paymentMethodsForm', PaymentMethodsFormComponent)
  .component('paymentSelection', PaymentSelectionComponent)
  .component('productPromo', ProductPromoComponent)
  .component('productSelection', ProductSelectionComponent)
  .component('productSelectionPromo', ProductSelectionPromoComponent)
  .component('accountProfile', AccountProfileComponent)
  .component('signupButton', SignupButtonComponent)
  .component('signupForm', BoomSignupFormComponent)

  /* Register Boom Components Components */
  .component('mainHeader', MainHeaderComponent)
  .component('languageSelector', LanguageSelectorComponent)
  .component('mainFooter', MainFooterComponent)
  .component('updatePaymentForm', BoomUpdatePaymentFormComponent)

  /* Register Custom Components */
  .component('logoutButton', BoomLogoutButtonComponent)
  .component('paymentForm', BoomPaymentFormComponent)
  .component('giftPaymentForm', BoomGiftPaymentFormComponent)
  .component('formHelp', BoomFormHelpComponent)
  .component('upsell', BoomUpsellComponent)
  .component('upsellPromo', BoomUpsellPromoComponent)
  .component('subscribeForm', BoomSubscribeFormComponent)
  .component('promocode', BoomPromoCodeComponent)
  .component('promocodeStandalone', BoomPromoCodeStandaloneComponent)
  .component('changePasswordForm', BoomChangePasswordFormComponent)
  .component('activeSubscription', ActiveSubscriptionComponent)
  .component('gifting', BoomGiftingComponent)
  .component('redemptionForm', BoomRedemptionFormComponent)
  .component('cancelSurvey', CancelSurveyComponent)
  .component('tosPpBanner', AgreementModalService)
  .component('openAppButton', OpenAppButtonComponent)
  .component('boomErrorList', BoomErrorListComponent);


export default ComponentsModule;
