/**
 *  @ngdoc service
 *  @name Gates.Service:BabyRouteInterceptorService
 *  @description
 *    You can configure a route with an ageGate property to inject the age/baby gate flow before a route loads
 * ```
  ageGate: {
    header: '',
    fullscreen: true,
    babyGateOnFail: true,
    maxAttempts: 2,
    failState: '',
    onFail: function(resolve, reject, failConfig) {
      //resolve or reject the route manually
    },
    onSuccess: function(resolve, reject, successConfig) {
      //resolve or reject the route manually
    }
  }
 * ```
 * @param {string} header The text to display at the top of the age gate
 * @param {boolean} fullscreen If the age/baby gate should be displayed fullscreen or in a modal (default false, modal)
 * @param {boolean} babyGateOnFail If the baby gate should appear after a failed age gate attempt
 * @param {string} failState The state to redirect to on a failure (default to 'home')
 * @param {function} onFail An override method that will be called if the age gate is failed instead of redirecting the user
 * @param {function} onSuccess An override method that will be called if the age gate is passed instead of resolving the route
 *
 * Both onFail and onSuccess are called with (resolve, reject, config)
 * @param {function} onFail.resolve Resolve the route the user was attempting to load
 * @param {function} onFail.reject Reject the route the user was attempting to load
 * @param {object} onFail.config Configuration information passed back from the age gate
 * @param {boolean} onFail.config.closed If the age/baby gate was closed instead of failed
 *
 * @param {function} onSuccess.resolve Resolve the route the user was attempting to load
 * @param {function} onSuccess.reject Reject the route the user was attempting to load
 * @param {object} onSuccess.config Configuration information passed back from the age gate
 */
class GateRouteInterceptorService {
  static get $inject() {
    return ['$transitions', '$state', '$q', 'gates.ageGate'];
  }

  constructor($transitions, $state, $q, AgeGateService) {
    this.$transitions = $transitions;
    this.$state = $state;
    this.$q = $q;
    this.AgeGateService = AgeGateService;

    this.inGatedFlow = false;
  }

  addTransitionHook() {
    this.$transitions.onStart({}, transition => this.checkForGate(transition));
  }

  checkForToFrom(to, from) {
    return !!(from.parent && to.name && from.parent == to.name);
  }

  checkForGate(transition) {
    const to = transition.to();
    const from = transition.from();
    if (!to.ageGate && !to.dontBreakGateFlow) {
      //reset the gated flow flag when they go outside a gated page
      this.inGatedFlow = false;
      return;
    }

    //if they are already in a gated flow they can just keep going
    //or if there is not an age gate on the target
    if (this.inGatedFlow || !to.ageGate || this.checkForToFrom(to, from)) {
      return;
    }

    let resolve;
    let reject;

    const onGateSuccess = config => {
      //flag that they have entered a gated flow
      this.inGatedFlow = true;
      //passed the age gate, resolve the route
      if (typeof to.ageGate.onSuccess === 'function') {
        to.ageGate.onSuccess(resolve, reject, config);
      } else {
        resolve();
      }
    };

    const onGateError = config => {
      if (typeof to.ageGate.onFail === 'function') {
        to.ageGate.onFail(resolve, reject, config);
      } else {
        if (config && config.closed) {
          if (!from.name) {
            this.$state.go(to.ageGate.failState || 'home');
          }
          //prevent the redirect since the modal was closed
          return reject();
        }

        reject();
        this.$state.go(to.ageGate.failState || 'home');
      }
    };

    return this.$q((promiseResolve, promiseReject) => {
      resolve = promiseResolve;
      reject = promiseReject;
      this.AgeGateService.open(to.ageGate, transition.params())
        .then(onGateSuccess)
        .catch(onGateError);
    });
  }
}

export default GateRouteInterceptorService;
