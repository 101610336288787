/**
  *  @ngdoc filter
  *  @name Boom.Filter:JpgToPng
  *  @param {string} url url with possible jpg image
  *  @returns {string} url with jpg converted to png
  *  @description
  *     Converts jpg extensions to png
  *
  *  @example
  *     <p>{{ vm.images.box.url | jpgToPng }}</p>
  */

const JpgToPng = () => {
  {
    return (url) => {
      if (url) {
        return url.replace('.jpg', '.png');
      }
      return '';

    };
  }
};

export default JpgToPng;
