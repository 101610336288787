/**
 *  @ngdoc controller
 *  @name Boom.Controllers:MainFooter
 *  @module Boom
 *  @requires $location
 *  @description
 *    Footer
 */
class MainFooter {
  static get $inject() {
    return ['$location', 'UrlLanguage', 'platformjs.claims'];
  }

  /* istanbul ignore next */
  constructor($location, UrlLanguage, ClaimsService) {
    this.$location = $location;
    this.UrlLanguage = UrlLanguage;
    this.ClaimsService = ClaimsService;
    this.showPrivacyUpdatedLink = false;
    this.showTermsUpdatedLink = false;
  }

  $onInit() {
    this.createHelpUrls();
    this.checkForWatch();
    this.getLinkUpdateClaims();
  }

  getLinkUpdateClaims() {
    return this.ClaimsService.get()
      .then(response => {
        this.showPrivacyUpdatedLink =
          parseInt(response.premium._rawProperties.privacy_policy_updated) ===
          1;
        this.showTermsUpdatedLink =
          parseInt(response.premium._rawProperties.terms_of_use_updated) === 1;
      })
      .catch(() => {
        this.showPrivacyUpdatedLink = false;
        this.showTermsUpdatedLink = false;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftingController#checkForWatch
   *  @methodOf Boom.Controllers:MainFooter
   *  @description
   *    Checks $location host for url for watch site.
   */
  checkForWatch() {
    this.kidSafe = this.$location.host().includes('watch');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomGiftingController#createHelpUrls
   *  @methodOf Boom.Controllers:MainFooter
   *  @description
   *    Creates Help Urls for Footer based on language
   */
  createHelpUrls() {
    this.lang = this.UrlLanguage.activeLanguage;
  }

  getTranslateValues() {
    return {
      year: new Date().getFullYear()
    };
  }
}

export default MainFooter;
