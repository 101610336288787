import SignupViewController from 'views/signup/signup-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomSignupView
 *  @module Boom
 *  @requires angular.$state
 *  @description
 *    Responsible for managing the signup view.
 */
class BoomSignupViewController extends SignupViewController {
  static get $inject() {
    return ['$state', '$stateParams', 'AnalyticsService', 'platformjs.premiumProfile'];
  }

  /* istanbul ignore next */
  constructor($state, $stateParams, analytics, PremiumProfileService) {
    super($state);

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.analytics = analytics;
    this.PremiumProfileService = PremiumProfileService;

    this.signupSuccess = false;
    this.isPromocodeApplied = false;
    this.useMobileLogoLink = true;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupView#showSignupFailure
   *  @methodOf Boom.Controllers:BoomSignupView
   *  @param {array} errors Error list
   *  @description
   *    Sets {@link Boom.Controllers:BoomSignupView#properties_errors errors} property.
   */
  showSignupFailure(errors) {
    let eventName = 'Account Creation Failed';
    if (this.$stateParams.premiumLevel == 1) {
      eventName += ' Monthly';
    } else if (this.$stateParams.premiumLevel == 12) {
      eventName += ' Annual';
    }
    this.analytics.track(eventName, {
      error_code: errors[0].code,
      error_name: errors[0].message
    });

    /* istanbul ignore next */
    super.showSignupFailure(errors);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupView#showSignupSuccess
   *  @methodOf Boom.Controllers:BoomSignupView
   *  @description
   *    Once signup succeed, check if the user has a promo code and try to apply it, otherwise, redirect to the subscribe view.
   *
   */
  showSignupSuccess() {

    this.signupSuccess = true;
    if (this.getPromocode()) {
      this.applyPromoCode();
    } else {
      this.redirectTo('subscribe');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupView#applyPromoCode
   *  @methodOf Boom.Controllers:BoomSignupView
   *  @description
   *    Call PremiumProfileService.applyPromo method to apply the promocode.
   *
   */
  applyPromoCode() {
    let config = {
      promoCode: this.$stateParams.code
    };

    this.PremiumProfileService.applyPromo(config)
      .then((response) => this.applySuccess(response))
      .catch((errors) => this.redirectTo('subscribe'));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupView#applySuccess
   *  @methodOf Boom.Controllers:BoomSignupView
   *  @param {Object} response response object from PremiumProfileService.applyPromo
   *  @description
   *    Apply promocode success, redirect to the subscribe view.
   *
   */
  applySuccess(response) {
    this.analytics.track('Promo Code Success', {
      code_value: this.$stateParams.code,
    });
    // Comped Access will subscribe the user from the server side
    if (response.promos &&
      response.promos[0] &&
      response.promos[0].requiresPaymentMethod === false) {
      this.redirectTo('welcome');
    } else {
      this.redirectTo('subscribe');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupView#redirectTo
   *  @methodOf Boom.Controllers:BoomSignupView
   *  @param {string} state the state name
   *  @description
   *    Changes state.
   */
  redirectTo(state) {
    this.$state.go(state, this.$state.params.destinationParams);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSignupView#getPromocode
   *  @methodOf Boom.Controllers:BoomSignupView
   *  @return {string} promocode the promocode
   *  @description
   *    Return $stateParams.code
   */
  getPromocode() {
    return this.$stateParams.code;
  }
}

export default BoomSignupViewController;
