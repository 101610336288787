import BoomDeviceRegistrationViewController from './boom-device-registration-view-controller';

function DeviceRegistrationState($stateProvider) {

  $stateProvider.state('device-registration', {
    url: '/device-registration',
    controller: BoomDeviceRegistrationViewController,
    controllerAs: 'vm',
    templateUrl: 'views/device-registration/device-registration-view.html',
    parent: 'language',
    userRequired: true,
    ignoreMobileWall: true
  });
}

DeviceRegistrationState.$inject = ['$stateProvider'];

export default DeviceRegistrationState;
