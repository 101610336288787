/**
   *  @ngdoc controller
   *  @name Boom.Controllers:WelcomeBackView
   *  @module Boom
   *  @requires AnalyticsService
   *  @requires $location
   *  @requires platform
   *  @description
   *    Welcomes back existing users who have tried to sign up again
   */

class BoomWelcomeBackViewController {
  static get $inject() {
    return [
      'AnalyticsService',
      '$location',
      'platform'
    ];
  }

  constructor(AnalyticsService, $location, platform) {
    this.AnalyticsService = AnalyticsService;
    this.$location = $location;
    this.platform = platform;
    this.path = `${this.$location.protocol()}://${this.platform.watchDomain.url}`;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeBackView#$onInit
   *  @methodOf Boom.Controllers:WelcomeBackView
   *  @description
   *    Sends analytics info for welcome back page.
   */
  $onInit() {
    this.AnalyticsService.page('Welcome Back Page Visited');
    this.$location.search('pid', null);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeBackView#$getWatchUrl
   *  @methodOf Boom.Controllers:WelcomeBackView
   *  @description
   *    Returns watch url
   */
  getWatchUrl() {
    return this.path;
  }

}

export default BoomWelcomeBackViewController;
