function FeedbackState($stateProvider) {

  $stateProvider.state('account.feedback', {
    parent: 'language',
    url: '/feedback',
    templateUrl: 'views/feedback/feedback-view.html'
  });

}

FeedbackState.$inject = ['$stateProvider'];

export default FeedbackState;
