/**
 *  @ngdoc directive
 *  @module boom.analytics.directives:AnalyticsTrack
 *  @name boom.analytics.directives.AnalyticsTrack
 *  @description
 *    A Directive to track clicks any any item.
 *
 *  @example
     *  <pre>
     *    <a href=""
     *        analytics-track="{{ {name: 'event name', properties: {prop1: 'value1', prop2: 'value2'}} }}">
     *      ...
     *    </a>
     *  </pre>
 */
function AnalyticsTrackDirective(analytics, $parse) {
  return {
    link: function(scope, element, attrs) {
      element.bind('click', () => {
        const options = $parse(attrs.analyticsTrack)();
        if (options) {
          analytics.track(options.name, options.properties);
        }
      });
    }
  };
}

AnalyticsTrackDirective.$inject = ['AnalyticsService', '$parse'];

export default AnalyticsTrackDirective;
