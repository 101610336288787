/**
 *  @ngdoc controller
 *  @name Seed.Controllers:ProfileForm
 *  @module Seed
 *  @requires platformjs.user.profile
 *  @description
 *    Responsible for signing up a user.
 */
class ProfileFormController {
  static get $inject() {
    return ['platformjs.user.profile', 'platformjs.user.login'];
  }

  /* istanbul ignore next */
  constructor(UserProfileService, UserLoginService) {
    this.UserProfileService = UserProfileService;
    this.UserLoginService = UserLoginService;

    /**
     *  @ngdoc property
     *  @name profile
     *  @propertyOf Seed.Controllers:ProfileForm
     *  @returns {object} User profile
     *  @description
     *    Current user profile object.
     */
    this.profile = this.profile || {};

    this.screenProfile = angular.copy(this.profile);
    /**
     *  @ngdoc property
     *  @name submitting
     *  @propertyOf Seed.Controllers:ProfileForm
     *  @returns {boolean} Status
     *  @description
     *    Current submission status.
     */
    this.submitting = false;

    this.onError = this.onError || angular.noop;
    this.onSubmit = this.onSubmit || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileForm#onUpdateError
   *  @methodOf Seed.Controllers:ProfileForm
   *  @param {array} errors Errors
   *  @description
   *   Called on failure of profile update request.
   */
  onUpdateError(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileForm#onUpdateFinally
   *  @methodOf Seed.Controllers:ProfileForm
   *  @description
   *   Called at the end of profile update request.
   */
  onUpdateFinally() {

    this.submitting = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileForm#onUpdateSuccess
   *  @methodOf Seed.Controllers:ProfileForm
   *  @param {object} profile User profile
   *  @description
   *   Called on success of profile update request.
   */
  onUpdateSuccess(profile) {

    this.onSuccess({
      profile: profile
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:ProfileForm#submit
   *  @methodOf Seed.Controllers:ProfileForm
   *  @description
   *   Submits form data to update profile request.
   */
  submit() {

    this.submitting = true;

    var config = {
      username: (this.screenProfile.username !== this.profile.username ? this.screenProfile.username : undefined),
      email: (this.screenProfile.email !== this.profile.email ? this.screenProfile.email : undefined),
      password: (this.screenProfile.password !== this.profile.password ? this.screenProfile.password : undefined)

    };

    this.UserLoginService.changeLoginInfo(config)
      .then(profile => this.onUpdateSuccess(profile))
      .catch(errors => this.onUpdateError(errors))
      .finally(() => this.onUpdateFinally());

  }
}

export default ProfileFormController;
