import BoomGiftingController from './boom-gifting-controller';

const BoomGiftingComponent = {
  bindings: {
    analytics: '<',
    onFailure: '&',
    onProductSelect: '&',
    onReady: '&',
    series: '<',
    episode: '<'
  },
  controller: BoomGiftingController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-gifting/gifting.html'
};

export default BoomGiftingComponent;
