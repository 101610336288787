function HelpState($stateProvider) {

  $stateProvider.state('account.help', {
    parent: 'language',
    url: '/help',
    templateUrl: 'views/help/help-view.html'
  });

}

HelpState.$inject = ['$stateProvider'];

export default HelpState;
