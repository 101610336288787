import moment from 'moment';

/**
 *  @ngdoc service
 *  @name Gates.Service:AgeGateService
 *  @description
 *    Service for interacting with the age gate
 *  @fires agegate-open
 *  @fires agegate-success
 *  @fires agegate-failed
 */
class AgeGateService {
  static get $inject() {
    return ['$q', '$cookies', '$rootScope', 'gates.babyGate', '$timeout'];
  }

  constructor($q, $cookies, $rootScope, BabyGateService, $timeout) {
    this.cookieName = 'boom-age-gate-locked';
    this.lockTime = 1; //hours

    this.$q = $q;
    this.$cookies = $cookies;
    this.moment = moment;
    this.$rootScope = $rootScope;
    this.BabyGateService = BabyGateService;
    this.$timeout = $timeout;

    this.state = {
      open: false,
      resolve: angular.noop,
      reject: angular.noop,
      config: {},
      locked: false
    };

    this.defaultMaxAttempts = 2;
    this.minAge = 18;
    this.attempts = 0;
  }

  /**
   *  @ngdoc method
   *  @name Gates.Service:AgeGateService#open
   *  @methodOf Gates.Service:AgeGateService
   *  @param {object} config configuration object
   *  @param {boolean} config.fullscreen If the baby gate should be full screen
   *  @param {number} config.maxAttempts The maximum number of attempts before locking
   *  @returns {promise} Promise to be resolved/rejected when the age gate is passed/failed
   *  @description
   *    Sets configuration and generates the promise.
   *    Stores resolve/reject on the state for consumers of the service to call
   */
  open(config, params) {
    this.checkLocked();
    this.params = params;

    //figure out the number of max attempts
    this.state.config = config || {};
    if (!this.state.config.maxAttempts) {
      this.state.config.maxAttempts = this.defaultMaxAttempts;
    }

    //create the promise and store the resolve/reject methods in our state
    const gatePromise = this.$q((resolve, reject) => {
      this.state.resolve = resolve;
      this.state.reject = reject;
    });

    if (
      this.showBabyGateOnNextOpen &&
      this.state.config.babyGateOnFail &&
      !this.state.locked
    ) {
      this.BabyGateService.open(
        {
          fullscreen: this.state.config.fullscreen
        },
        this.params
      ).then(() => {
        this.showBabyGateOnNextOpen = false;
        this.open(config, params);
      });
    } else {
      this.state.open = true;
      this.$rootScope.$broadcast('agegate-open', {
        params
      });
    }
    return gatePromise;
  }

  /**
   *  @ngdoc method
   *  @name Gates.Service:AgeGateService#check
   *  @methodOf Gates.Service:AgeGateService
   *  @param {string} age the response age from the user
   *  @returns {boolean} result if the user's age matched or exceeded minAge
   *  @description
   *    Compares the value the passed to our internal value.
   *    If failed, checks if we have reached our maxAttempts and if so, sets the lock cookie for an hour
   */
  check(age) {
    //increment the attempt count and normalize the age
    this.attempts++;
    const numAge = Number(age);
    //k.
    if (!isNaN(numAge) && numAge >= this.minAge) {
      this.$rootScope.$broadcast('agegate-success', {
        age: numAge,
        params: this.params
      });

      //reset the attempt count
      this.attempts = 0;
      return true;
    }
    //failed multiple times, break everything.
    if (this.attempts >= this.state.config.maxAttempts) {
      const time = this.moment().add(this.lockTime, 'hour');
      this.state.locked = time;
      this.$cookies.put(this.cookieName, time.unix(), {
        expires: time.toDate()
      });
      //reset the attempt count
      this.attempts = 0;

      if (this.state.config.failureAutocloseTimeout) {
        this.$timeout(() => {
          this.state.reject();
        }, this.state.config.failureAutocloseTimeout);
      }
    }
    this.$rootScope.$broadcast('agegate-failed', {
      params: this.params,
      age: numAge
    });
    return false;
  }

  /**
   *  @ngdoc method
   *  @name Gates.Service:AgeGateService#checkLocked
   *  @methodOf Gates.Service:AgeGateService
   *  @returns {boolean} result if the gates have been cookie-locked
   *  @description
   *    Checks the cookie to see if the gate is locked
   */
  checkLocked() {
    //check if its locked
    const isPersistLocked = this.$cookies.get(this.cookieName);
    if (isPersistLocked) {
      this.state.locked = moment.unix(isPersistLocked);
    } else {
      this.state.locked = false;
    }
  }
}

export default AgeGateService;
