import ForgotPasswordViewController from 'views/forgot-password/forgot-password-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:ForgotPasswordView
 *  @module Boom
 *  @description
 *    Responsible for displaying Forgot Password View. This extends from seed, primarily to override `showErrors()` method.
 */
class BoomForgotPasswordViewController extends ForgotPasswordViewController {

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ForgotPasswordView#$showErrors
   *  @methodOf Boom.Controllers:ForgotPasswordView
   *  @description
   *    Override bug in `showErrors` method from seed, so that, if there is error, we want vm.success to be false.
   */
  showErrors(errors) {

    this.errors = errors;
    this.success = false;

  }
}

export default BoomForgotPasswordViewController;
