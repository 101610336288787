/**
   *  @ngdoc service
   *  @name Seed.Services:Overlay
   *  @module Seed
   *  @description
   *    Responsible for managing various overlay states.
   */
class OverlayService {
  constructor() {
    this.overlays = {};
  }

  close(key) {
    this.overlays[key] = false;
  }

  get(key) {
    return this.overlays[key];
  }

  open(key) {
    this.overlays[key] = true;
  }

  isActive() {
    let keys = [];

    for (var key in this.overlays) {
      keys.push(key);
    }

    return keys.length > 0;

  }

}

export default OverlayService;
