import NotificationSettingsController from './notification-settings-controller';

const NotificationSettingsComponent = {
  bindings: {
    onError: '&',
    onSubmit: '&',
    onSuccess: '&',
    notification: '<'
  },
  controller: NotificationSettingsController,
  controllerAs: 'vm',
  templateUrl: 'components/notification-settings/notification-settings.html'
};

export default NotificationSettingsComponent;
