import BoomWelcomeBackViewController from './boom-welcome-back-view-controller';

function BoomWelcomeBackState($stateProvider) {

  $stateProvider.state('welcome-back', {
    url: '/welcome-back',
    controller: BoomWelcomeBackViewController,
    controllerAs: 'vm',
    templateUrl: 'views/welcome-back/welcome-back-view.html',
    parent: 'register',
    userRequired: true,
    ignoreMobileWall: true
  });

}

BoomWelcomeBackState.$inject = ['$stateProvider'];

export default BoomWelcomeBackState;
