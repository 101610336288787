/**
 *  @ngdoc controller
 *  @name Boom.Controllers:MainHeader
 *  @module Boom
 *  @requires angular.$state
 *  @requires angular.$location
 *  @requires platformjs
 *  @requires platformjs.user.account
 *  @requires platformjs.premiumProfile
 *  @requires platformjs.claims
 *  @property hadFreeTrial
 *  @description
 *    MainHeader and navigation
 */
class MainHeader {
  static get $inject() {
    return [
      '$state',
      '$location',
      'platform',
      'platformjs.user.account',
      'platformjs.premiumProfile',
      'platformjs.claims'
    ];
  }
  constructor(
    $state,
    $location,
    platform,
    UserAccountService,
    PremiumProfileService,
    ClaimsService
  ) {
    this.$state = $state;
    this.$location = $location;
    this.platform = platform;
    this.UserAccountService = UserAccountService;
    this.PremiumProfileService = PremiumProfileService;
    this.ClaimsService = ClaimsService;
    this.languageKey = this.languageKey || angular.noop;
    this.loading = true;

    /**
     *  @ngdoc property
     *  @name hadFreeTrial
     *  @type Boolean
     *  @name Boom.Controllers:MainHeader
     *  @description
     *    A flag from user.premium.freeTrial to provide a toggle value for header 'Subscribe' and 'Start Free Trial' Button
     */
    this.hadFreeTrial = false;

    /**
     *  @ngdoc property
     *  @name inFreeTrial
     *  @type Boolean
     *  @name Boom.Controllers:MainHeader
     *  @description
     *    A flag from user.premium.freeTrial to provide a toggle value for header 'Subscribe' and 'Start Free Trial' Button
     */
    this.inFreeTrial = false;
  }

  mainHeaderLanguageChange(key) {
    this.languageKey({ key: key });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MainHeader.#$onInit
   *  @methodOf Boom.Controllers:MainHeader
   *  @description
   *    Initializes the logo image url depending on user premium status, also checks for user free trial status to toggle header buttons
   */
  $onInit() {
    this.checkForWatch();
    this.path = this.platform.watchDomain
      ? `${this.$location.protocol()}://${this.platform.watchDomain.url}`
      : '/';

    if (this.UserAccountService.isRegistered()) {
      this.PremiumProfileService.get()
        .then(response => {
          this.hadFreeTrial = response.freeTrial.hadFreeTrial;
          this.inFreeTrial = response.freeTrial.inFreeTrial;
        })
        .catch(errors => {
          this.errors = errors;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  /**
  `*  @ngdoc method
   *  @name Boom.Controllers:MainHeader#checkForWatch
   *  @methodOf Boom.Controllers:MainHeader
   *  @description
   *    Checks $location host for url for watch site.
   */
  checkForWatch() {
    this.kidSafe = this.$location.host().includes('watch');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MainHeader#showStartFreeTrial
   *  @methodOf Boom.Controllers:MainHeader
   *  @description
   *    Checks status of operation_nightfall claim and determines if
   *    start trial should be shown
   */
  showStartFreeTrial() {
    const premiumClaim = this.ClaimsService.getClaim('premium');

    if (!premiumClaim || !premiumClaim._rawProperties) {
      return false;
    }

    return (
      premiumClaim._rawProperties.operation_nightfall === undefined ||
      parseInt(premiumClaim._rawProperties.operation_nightfall) !== 1
    );
  }
}

export default MainHeader;
