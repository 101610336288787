/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomUpsellController
*  @module Boom
*  @description
*  @requires $location
*  @requires $cookies
*    Responsible for subscribing a user.
*/
class BoomUpsellController {
  static get $inject() {
    return [
      '$location',
      '$cookies',
      'platformjs.catalog',
      'platformjs.premiumProfile',
      'moment',
      'UrlLanguage',
      '$translate'
    ];
  }

  /* istanbul ignore next */
  constructor($location, $cookies, CatalogService, PremiumProfileService, moment, UrlLanguage, $translate) {
    this.$location = $location;
    this.$cookies = $cookies;
    this.CatalogService = CatalogService;
    this.PremiumProfileService = PremiumProfileService;
    this.moment = moment;
    this.UrlLanguage = UrlLanguage;
    this.$translate = $translate;

    this.expire = null;
    this.hasPlans = false;
    this.loaded = false;
    this.promo = false;
    this.analytics = 'Gift Plan Option Submitted';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellController#$onInit
   *  @methodOf Boom.Controllers:BoomUpsellController
   *  @description
   *    Loads catalog.
   */
  $onInit() {
    this.checkPromo();
    this.$translate.use(this.UrlLanguage.activeLanguage);
    this.CatalogService.list()
      .then(catalog => this.processCatalog(catalog))
      .then(() => this.checkForPromoCode())
      .finally(() => {
        this.loaded = true;
      });
  }

  processCatalog(catalog) {
    this.catalog = catalog;
    this.hasPlans = catalog.plans.length > 0;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomUpsellController#checkPromo
  *  @methodOf Boom.Controllers:BoomUpsellController
  *  @description
  *    Checks for promo query and sets cookie
  */
  checkPromo() {
    let promo = this.$location.search().promo;

    let date = new Date();
    date.setTime(date.getTime() + (5 * 60 * 1000));

    this.$cookies.put('subscribe-promo', promo, {
      expires: date
    });

  }
  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomUpsellController#checkForPromoCode
  *  @methodOf Boom.Controllers:BoomUpsellController
  *  @description
  *    Checks for query string or cookie for promo code
  */
  checkForPromoCode() {
    let cookie = this.$cookies.get('subscribe-promo');

    if (cookie) {
      this.isPromoValid(cookie)
        .then((response) => this.checkSuccess(response));
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellController#isPromoValid
   *  @methodOf Boom.Controllers:BoomUpsellController
   *  @description
   *    Returns a check for promo code validity
   */
  isPromoValid(promo) {
    let config = {
      promoCode: promo
    };

    return this.PremiumProfileService.checkPromo(config);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellController#checkSuccess
   *  @methodOf Boom.Controllers:BoomUpsellController
   *  @description
   *    Sets promoId property and turns it into a string 
   */
  checkSuccess(promo) {
    this.promo = promo;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellController#getExpiration
   *  @methodOf Boom.Controllers:BoomUpsellController
   *  @description
   *    Returns 7 days expiration for upsell screen promo product
   */
  getExpiration() {
    return this.moment(new Date()).add(7, 'days').format('MM/DD/YYYY');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellController#isPremiumView

   *  @methodOf Boom.Controllers:BoomUpsellController
   *  @param {object} product Product object
   *  @description
   *    Checks for premium view
   */
  isPremiumView() {
    return this.$location.path() === '/premium';
  }

}

export default BoomUpsellController;
