import ForgotPasswordFormController from 'components/forgot-password-form/forgot-password-form-controller';

/**
   *  @ngdoc controller
   *  @name Boom.Controllers:BoomForgotPasswordFormController
   *  @module Boom
   *  @requires platformjs.user.login
   *  @description
   *    Responsible for requesting change password email.
   */
class BoomForgotPasswordFormController extends ForgotPasswordFormController {
  static get $inject() {
    return ['platformjs.user.login'];
  }

  /* istanbul ignore next */
  constructor(LoginService) {
    super(LoginService);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomForgotPasswordFormController#showSubmitSuccess
   *  @methodOf Boom.Controllers:BoomForgotPasswordFormController
   *  @description
   *    Called on success of request for password email.
   */
  showSubmitSuccess() {
    this.onSuccess();
    this.verificationSuccess = true;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomForgotPasswordFormController#showSubmitErrors
   *  @methodOf Boom.Controllers:BoomForgotPasswordFormController
   *  @param {array} errors Errors 
   *  @description
   *    Stops angular noop from super. and sets errors for forgot password form
   */
  showSubmitErrors(errors) {
    this.errors = errors;
  }
}

export default BoomForgotPasswordFormController;
