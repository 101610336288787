import UpsellViewController from './upsell-view-controller.module';

function UpsellState($stateProvider) {

  $stateProvider.state('upsell', {
    parent: 'language',
    url: '/premium',
    templateUrl: 'views/upsell/upsell-view.html',
    segment: {
      name: 'Plan Options Page'
    },
    controller: UpsellViewController,
    ignoreMobileWall: true
  });
}

UpsellState.$inject = ['$stateProvider'];

export default UpsellState;
