/**
*  @ngdoc controller
*  @name Boom.Controllers:CancelSurveyController
*  @module Boom
*  @requires platformjs.user
*  @requires $sce
*  @requires $window
*  @description
*    Responsible for displaying an embedded survey from Survey Legend
*/
class CancelSurveyController {

  static get $inject() {
    return [
      '$window'
    ];
  }

  constructor($window) {
    this.$window = $window;
    this.boundOnReceiveSurveySubmittedMessage = this.onReceiveSurveySubmittedMessage.bind(this);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelSurvey#$onDestroy
   *  @methodOf Boom.Controllers:CancelSurveyController
   *  @description
   *    remove the message event handler from the global window object
   */
  $onDestroy() {
    // Remove the message event handler from the window object
    this.$window.removeEventListener("message", this.boundOnReceiveSurveySubmittedMessage);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelSurvey#$onInit
   *  @methodOf Boom.Controllers:CancelSurveyController
   *  @description
   *    adds a message event handler to the global window object
   */
  $onInit() {
    // attach a message event handler to the window object to handle
    // the iFrame notification when the user submits the survey
    // this.$window.addEventListener("message", this.onReceiveSurveySubmittedMessage.bind(this), false);
    this.$window.addEventListener("message", this.boundOnReceiveSurveySubmittedMessage, false);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:CancelSurvey#$onReceiveSurveySubmittedMessage
   *  @methodOf Boom.Controllers:CancelSurveyController
   *  @param {event} a JavaScript event object
   *  @description
   *    a method used to catch message events, check that the message results from a user
   *    clicking submit on a survey legend form and then redirect the user to a new URL.
   */
  onReceiveSurveySubmittedMessage(event) {
    // verify that message is really coming from www.surveylegend.com upon survey submit
    if (event.origin === 'https://www.surveylegend.com' &&
      event.data && // null is also of type "object"
      typeof event.data === 'object' &&
      event.data.type === 'submit-responses'
    ) {
      this.$window.location.href = this.redirectURL;
    }
  }

}
export default CancelSurveyController;
