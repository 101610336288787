/**
 *  @ngdoc controller
 *  @name Boom.Controllers:AgreementModalService
 *  @module Boom
 *  @requires platformjs.user
 *  @requires platformjs.storage
 *  @requires platformjs.agreements
 *  @requires angular.$q
 *  @description
 *    Responsible for displaying watch information.
 */
export default class AgreementModalService {
  static get $inject() {
    return [
      'platformjs.user',
      'platformjs.agreements',
      '$q',
      '$cookies',
      'AnalyticsService'
    ];
  }

  constructor(UserService, AgreementsService, $q, $cookies, AnalyticsService) {
    this.UserService = UserService;
    this.AgreementsService = AgreementsService;
    this.$q = $q;
    this.$cookies = $cookies;
    this.analytics = AnalyticsService;

    this.activeAgreements = null;
    /**
     * We have two maps in cookie storage that tracks which users have
     * agreed to which versions of terms/pp.  the store is populated from
     * the backend when a user logs in, and is stored on the cookie
     * for anon users or if the api fails for some reason and during bootstrap,
     * especially when moving between sites.
     * 
     * This behaves like a singleton store, and is mapped as:
     * {
     *   userGuid2345: {
     *     termsRevisionsAccepted: ['efg123'],
     *     privacyRevisionsAccepted: ['abcd123']
     *   },
     *   userGuid455: {
     *     termsRevisionsAccepted: [],
     *     privacyRevisionsAccepted: ['bd278']
     *   }
     * }
     */
    this.agreementCookieStore = {};

    this.emptyAgreements = {
      termsRevisionsAccepted: [],
      privacyRevisionsAccepted: []
    };
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#loadAgreements
   *  @methodOf Boom.Controllers:AgreementModalService
   *  @description
   *    Fetch both the privacy policy and the terms agreements
   */
  loadAgreements() {
    this.$q.all([
      this.AgreementsService.getPrivacy({
        flavor: 'www'
      }),
      this.AgreementsService.getTerms({
        flavor: 'www'
      })
    ])
      .then(response => {
        this.activeAgreements = {
          privacy: response[0],
          terms: response[1]
        };
        this.fetchCookieStore();
        if (this.UserService.profile.guid) {
          this.updateUserCookieStoreFromActiveAgreements();
        }
      })
      .catch(error => {
        this.activeAgreements = null;
      });

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#getCookieName
   *  @methodOf Boom.Controllers:AgreementModalService
   *  @description
   *   Gets the cookie name to use for storing agreementCookieStore
   *   We have a different cookie for anon vs logged in users so the
   *   expire timeout can be different
   */
  getCookieName() {
    if (this.UserService.profile.guid) {
      return 'agreed-terms';
    }
    return 'agreed-terms-anon';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#fetchCookieStore
   *  @methodOf Boom.Controllers:AgreementModalService
   * @description
   *   Gets the correct object from the browser cookies and normalizes
   *   if the user has an old version of the cookie value
   */
  fetchCookieStore() {
    this.agreementCookieStore = this.$cookies.getObject(this.getCookieName()) || {};

    // If they have the old cookie set, use a default
    if (this.agreementCookieStore === 1) {
      this.agreementCookieStore = {};
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#getUsersAgreementsMappingFromActiveCookieStore
   *  @methodOf Boom.Controllers:AgreementModalService
   *  @param {boolean} forceCheckAnonymous forces check for anonymous cookie
   *  @description
   *    Gets the correct object from the agreementCookieStore depending on if the user is authed or anon
   *    Creates a new empty agreements map if one does not exist for the user
   */
  getUsersAgreementsMappingFromActiveCookieStore(forceCheckAnonymous = false) {
    const store = this.agreementCookieStore;

    let userAgreementsMap;
    if (this.UserService.profile.guid && !forceCheckAnonymous) {
      //authenticated user
      if (!store[this.UserService.profile.guid]) {
        //create the object in the store if they dont have one
        store[this.UserService.profile.guid] = angular.copy(this.emptyAgreements);
      }
      userAgreementsMap = store[this.UserService.profile.guid];
    } else {
      //anonymous user
      if (!store.anonymous) {
        //create the object in the store if they dont have one
        store.anonymous = angular.copy(this.emptyAgreements);
      }
      userAgreementsMap = store.anonymous;
    }

    return userAgreementsMap;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#getCookieConfig
   *  @methodOf Boom.Controllers:AgreementModalService
   *  @description
   *    Returns the config for setting a cookie
   *    Anonymous users get an empty (session) cookie
   *    Authenticated users get a cookie that never expires (10 years in the future)
   */
  getCookieConfig() {
    const config = {};
    if (this.UserService.profile.guid) {
      const expire = new Date();
      expire.setFullYear(expire.getFullYear() + 10);
      config.expires = expire;
    }
    return config;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#updateUserCookieStoreFromActiveAgreements
   *  @methodOf Boom.Controllers:AgreementModalService
   *  @description
   *    Updates the user cookie with the data fetched from the API
   */
  updateUserCookieStoreFromActiveAgreements() {
    //reset to an empty map
    this.agreementCookieStore[this.UserService.profile.guid] = angular.copy(this.emptyAgreements);
    //fetch the reset agreement map
    let userAgreementsMap = this.getUsersAgreementsMappingFromActiveCookieStore();
    //store the revisions if they have accepted
    if (this.activeAgreements.terms.accepted) {
      userAgreementsMap.termsRevisionsAccepted.push(this.activeAgreements.terms.revisionId);
    }
    if (this.activeAgreements.privacy.accepted) {
      userAgreementsMap.privacyRevisionsAccepted.push(this.activeAgreements.privacy.revisionId);
    }
    //save it
    this.$cookies.putObject(this.getCookieName(), this.agreementCookieStore, this.getCookieConfig());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#isAgreedTerms
   *  @methodOf Boom.Controllers:AgreementModalService
   *  @param {object} config config object for checking Tos and PP
   *  @param {boolean} config.forceCheckAnonymous forces check for anonymous cookie for PP and ToS
   *  @returns {bool | null} Bool if fetched, then correct status, or null if unfetched
   *  @description
   *    Check if the user already agreed to the current terms of use and the privacy policy.
   */
  isAgreedTerms(config = {
      forceCheckAnonymous: false
    }) {
    // If the terms haven't been fetched yet, we will return null so
    // the consuming controllers have a way to differentiate between true/false and unloaded
    if (this.activeAgreements === null || this.userFlux) {
      return null;
    }

    // Use the user's cookie or use the anonymous user
    let userAgreementsMap = this.getUsersAgreementsMappingFromActiveCookieStore(config.forceCheckAnonymous);

    //if they dont match the latest version, they haven't accepted    
    if (userAgreementsMap.termsRevisionsAccepted.indexOf(this.activeAgreements.terms.revisionId) === -1 ||
      userAgreementsMap.privacyRevisionsAccepted.indexOf(this.activeAgreements.privacy.revisionId) === -1) {
      return false;
    }

    //assume that they have accepted
    return true;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:AgreementModalService#agreeTerms
   *  @methodOf Boom.Controllers:AgreementModalService
   *  @description
   *    Stores the revisionIds that are accepted.
   *    For a registered user, uses a long-term cookie 
   *    For an anonymous user, uses a session cookie.
   */
  agreeTerms() {
    let userAgreementsMap = this.getUsersAgreementsMappingFromActiveCookieStore();

    //add the new revision to the list
    userAgreementsMap.termsRevisionsAccepted.push(this.activeAgreements.terms.revisionId);
    userAgreementsMap.privacyRevisionsAccepted.push(this.activeAgreements.privacy.revisionId);

    //store it
    if (this.UserService.profile.guid) {
      //send the agreement to the backend api
      //fire and forget
      if (!this.activeAgreements.privacy.accepted) {
        this.AgreementsService.acceptPrivacy({
          flavor: 'www',
          revisionId: this.activeAgreements.privacy.revisionId
        });
      }
      if (!this.activeAgreements.terms.accepted) {
        this.AgreementsService.acceptTerms({
          flavor: 'www',
          revisionId: this.activeAgreements.terms.revisionId
        });
      }
    }
    this.$cookies.putObject(this.getCookieName(), this.agreementCookieStore, this.getCookieConfig());

    this.analytics.track('Terms of Use Agreed');
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:AgreementModalService#clearAnonCookie
   * @methodOf Boom.Controllers:AgreementModalService
   * @description
   *    Clears the anonymous user off the store and the anonymous cookie
   */
  clearAnonCookie() {
    if (this.agreementCookieStore) {
      delete this.agreementCookieStore.anonymous;
    }
    this.$cookies.remove('agreed-terms-anon');
  }

  /**
  * @ngdoc method
  * @name Boom.Controllers:AgreementModalService#userInFlux
  * @methodOf Boom.Controllers:AgreementModalService
  * @description
  *    Sets the flag that the user might change
  */
  userInFlux() {
    this.userFlux = true;
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:AgreementModalService#userOutOfFlux
   * @methodOf Boom.Controllers:AgreementModalService
   * @description
   *    Clears the flag that the user might change
   */
  userOutOfFlux() {
    this.userFlux = false;
  }
}
