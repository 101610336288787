class UpsellViewController {
  static get $inject() {
    return [
      'platformjs.storage'
    ];
  }
  /* istanbul ignore next */
  constructor(
    StorageService
  ) {
    this.StorageService = StorageService;
  }

  $onInit() {
    this.StorageService.remove('promotional-catalog');
  }
}

export default UpsellViewController;