function OverviewState($stateProvider) {

  $stateProvider.state('guide.overview', {
    parent: 'language',
    url: '',
    templateUrl: 'views/guide/overview/overview.html'
  });

}

OverviewState.$inject = ['$stateProvider'];

export default OverviewState;
