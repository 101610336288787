import CancelViewController from './boom-cancel-view-controller';

function CancelState($stateProvider) {

  $stateProvider.state('account.cancel', {
    parent: 'account.profile',
    url: '/cancel',
    controller: CancelViewController,
    controllerAs: 'vm',
    templateUrl: 'views/cancel/cancel-view.html',
    userRequired: true,
    ignoreMobileWall: true,
    dontBreakGateFlow: true
  });

}

CancelState.$inject = ['$stateProvider'];

export default CancelState;
