import BoomLoginFormController from './boom-login-form-controller';

const BoomLoginFormComponent = {
  bindings: {
    onError: '&',
    onSubmit: '&',
    onSuccess: '&'
  },
  controller: BoomLoginFormController,
  controllerAs: 'vm',
  templateUrl: 'components/login-form/login-form.html'
};

export default BoomLoginFormComponent;
