import SubscribeFormController from 'components/subscribe-form/subscribe-form-controller';
/**
 *  @ngdoc controller
 *  @name Boom.Controllers:SubscribeForm
 *  @module Boom
 *  @requires platformjs.subscriptions
 *  @requires platformjs.paymentmethods
 *  @requires AnalyticsService
 *  @requires platformjs.storage
 *  @description
 *    Responsible for subscribing a user.
 */
class BoomSubscribeFormController extends SubscribeFormController {
  static get $inject() {
    return [
      'platformjs.subscriptions',
      'platformjs.paymentmethods',
      'AnalyticsService',
      'platformjs.storage',
      'platformjs.agreements',
      '$sce',
      'platformjs.premiumProfile',
      'moment',
      '$translate'
    ];
  }

  /* istanbul ignore next */
  constructor(
    SubscriptionsService,
    PaymentMethodsService,
    AnalyticsService,
    StorageService,
    AgreementsService,
    $sce,
    PremiumProfileService,
    moment,
    $translate
  ) {
    super(SubscriptionsService, PaymentMethodsService);

    this.AnalyticsService = AnalyticsService;
    this.StorageService = StorageService;
    this.onError = this.onError || angular.noop;
    this.onSubscribeError = this.onSubscribeError || angular.noop;
    this.onNextChange = this.onNextChange || angular.noop;
    this.AgreementsService = AgreementsService;
    this.$sce = $sce;
    this.PremiumProfileService = PremiumProfileService;
    this.moment = moment;
    this.$translate = $translate;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#$onInit
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @description
   *    Grabs the payment methods and ARL agreement
   */
  $onInit() {
    this.PaymentMethodsService.list()
      .then(paymentMethods => this.setPaymentMethod(paymentMethods), () => this.showFailure());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#selectProduct
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @param {object} product A product.
   *  @description
   *    Sets product and calls function to get ARL consent and format it
   */
  selectProduct(product) {
    this.product = product;
    this.arlConsentAgreementFlavor = this.getConsentAgreementFlavor();

    this.AgreementsService.getTerms({
      flavor: this.arlConsentAgreementFlavor
    }).then(response => {
      this.arlConsentAgreement = response;
    })
      .catch(error => {
        this.arlConsentAgreement = {};
      })
      .finally(() => {
        this.getPremiumProfileAndFormatConsent();
      }
    );
  }

  getConsentAgreementFlavor() {
    let flavor;

    switch (this.product && this.product.merchantPlanId) {
      case 'july2020monthly':
      case 'SpringSale2021Monthly':
        flavor = 'autorenewal-us-web-monthly-sale';
        break;
      default:
        flavor = 'autorenewal-us-web';
    }

    return flavor;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:SubscribeForm#getPremiumProfileAndFormatConsent
  *  @methodOf Boom.Controllers:SubscribeForm
  *  @description
  *    Gets the premium profile and formats ARL consent from the related values
  */
  getPremiumProfileAndFormatConsent() {
    this.PremiumProfileService.get()
      .then(profile => {
        this.premiumProfile = profile;

        let trialOffer = '';
        let freeTrialDateMessage = '';

        if (!this.premiumProfile.freeTrial.hadFreeTrial &&
          parseInt(this.product.freeTrialDays) > 0) {
          const freeTrialEndDate = this.moment(new Date())
            .add(this.product.freeTrialDays, 'days')
            .format('MM/DD/YYYY');

          trialOffer = this.getFreeTrialOffer();
          freeTrialDateMessage = this.getFreeTrialDateMessage(freeTrialEndDate);
        }
        const price = `$${ this.product.price }`;
        const initialPrice = this.product.descriptors.promo_price || price;
        const term = this.getProductTermLength(this.product.premiumLevel);

        this.arlConsentText = this.$sce.trustAsHtml(this.dynamicTemplate(
          this.arlConsentAgreement.text,
          {
            trialOffer,
            freeTrialDateMessage,
            price,
            term,
            initialPrice
          }
        ));
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#getFreeTrialOffer
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @description
   *   Returns the free trial offer in the appropriate language
   */
  getFreeTrialOffer() {
    return this.$translate.instant('free trial and');
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#getFreeTrialDateMessage
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @description
   *   Returns the free trial date message in the appropriate language
   */
  getFreeTrialDateMessage(freeTrialEndDate) {
    return this.$translate.instant('FREE TRIAL MESSAGE WITH DATE', {
      freeTrialEndDate
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#getProductTermLength
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @description
   *   Returns the product term length in the appropriate language
   */
  getProductTermLength(premiumLevel) {
    return this.$translate.instant(
      premiumLevel === '12' ? 'year' : 'month'
    );
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#dynamicTemplate
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @param {string} template The template literal inject vars
   *  @param {object} vars The variables to inject into the template
   *  @returns {string} template
   *  @description
   *   Takes a string template with variables and replaces with with values from vars
   * 
   *   PhantomJS did not play nice with the newfangled templating stuff, soooo.... ignore?
   */
  /* istanbul ignore next */
  dynamicTemplate(template, vars) {
    /* istanbul ignore next */
    const names = Object.keys(vars);
    /* istanbul ignore next */
    const values = Object.keys(vars).map(key => vars[key]);
  /*esfmt-ignore-start*/
    /* istanbul ignore next */
    return new Function(...names, `return \`${template}\`;`)(...values);
    /*esfmt-ignore-end*/
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#showSuccess
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @param {object} product A product.
   *  @description
   *    Triggers onSuccess and logs to segment.
   */
  showSuccess(product) {

    const term = product.premiumLevel == 12 ? 'Annual' : 'Monthly';
    const properties = {
      plan_type: term.toLocaleLowerCase()
    };

    const segmentPayload = Object.assign(properties, this.fetchPromotionalConfig());

    this.AnalyticsService.track(`Trial Started ${term}`, segmentPayload);

    this.StorageService.remove('promotional-catalog');

    this.onSuccess();

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#fetchPromotionalConfig
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @description
   *    Fetches the promotional config data
   */
  fetchPromotionalConfig() {
    return this.StorageService.get('promotional-config') || {};
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#submit
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @param {object} paymentMethod A payment method.
   *  @description
   *    Attempts to subscribe using passed payment method and current product ID.
   */
  submit(paymentMethod) {
    this.onSubmit();
    var params = {
      productId: this.product.id,
      paymentMethodGuid: paymentMethod.guid || paymentMethod.paymentMethodGuid
    };

    params = this.addPromotionalCatalog(params);

    return this.AgreementsService.acceptTerms({
      flavor: this.arlConsentAgreementFlavor,
      revisionId: this.arlConsentAgreement.revisionId
    }).then(() => this.SubscriptionsService.create(params))
      .then(() => this.showSuccess(this.product))
      .catch(errors => this.showErrors(errors));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#showErrors
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @param {errors} errors A list of errors.
   *  @description
   *    Triggers onError and passes error list.
   */
  showErrors(errors) {
    this.onSubscribeError({
      onSubscribeError: {
        errors: errors
      }
    });

    this.errors = errors;
    this.paypalErrors = errors;

    this.onError({
      errors: errors
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SubscribeForm#setNext
   *  @methodOf Boom.Controllers:SubscribeForm
   *  @description
   *  Changes the view from product selection to payment form
   */
  setNext() {
    this.next = true;
    this.onNextChange({
      productSelected: {
        next: true
      }
    });
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:SubscribeForm#addPromotionalCatalog
  *  @methodOf Boom.Controllers:SubscribeForm
  *  @param {object} params subscription creation parameters
  *  @description
  *    Checks StorageService for a promotional catalog and appends it to the subscription
  *    creation parameters if it exists.
  */
  addPromotionalCatalog(params) {
    const promotionalCatalog = this.StorageService.get('promotional-catalog');
    if (!promotionalCatalog) {
      return params;
    }

    return Object.assign({
      promotionalCatalog
    }, params);
  }
}

export default BoomSubscribeFormController;
