import LoginFormController from 'components/login-form/login-form-controller';
import { setPlatform3AuthToken } from '../../platform3';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomLoginFormController
*  @module Boom
*  @requires $analytics
*  @requires platformjs.user.login
*  @requires GoogleAnalyticsService
*  @requires AnalyticsService
*  @requires platformjs.storage
*  @requires platform
*  @requires $rootScope
*  @requires platformjs.user
*  @requires AgreementModalService
*  @requires $location
*  @requires $cookies
*  @requires platformjs.claims
*  @requires UrlLanguage
*  @description
*    Responsible for logging in a user.
*/
class BoomLoginFormController extends LoginFormController {
  static get $inject() {
    return [
      '$analytics',
      'platformjs.user.login',
      'GoogleAnalyticsService',
      'AnalyticsService',
      'platformjs.storage',
      'platform',
      '$rootScope',
      'platformjs.user',
      'AgreementModalService',
      '$location',
      '$cookies',
      'platformjs.claims',
      'UrlLanguage'
    ];
  }

  /* istanbul ignore next */
  constructor($analytics, LoginService, GoogleAnalyticsService, AnalyticsService, StorageService, platform, $rootScope, User, AgreementModalService, $location, $cookies, ClaimsService, UrlLanguage) {
    super($analytics, LoginService, GoogleAnalyticsService);

    this.analytics = AnalyticsService;
    this.StorageService = StorageService;
    this.$rootScope = $rootScope;
    this.User = User;
    this.AgreementModalService = AgreementModalService;
    this.passwordVisibility = false;
    this.$location = $location;
    this.$cookies = $cookies;
    this.ClaimsService = ClaimsService;
    this.UrlLanguage = UrlLanguage;

    /**
     *  @ngdoc property
     *  @name username
     *  @propertyOf Boom.Controllers:BoomLoginFormController
     *  @returns {string} environment
     *  @description
     *    Application environment, possible values: local, dev, qa, and prod
     */
    this.ENV = platform.application.env;

    /**
     *  @ngdoc property
     *  @name username
     *  @propertyOf Boom.Controllers:BoomLoginFormController
     *  @returns {string} email
     *  @description
     *    User email input value.
     */
    this.email = '';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomLoginFormController#submit
   *  @methodOf Boom.Controllers:BoomLoginFormController
   *  @description
   *    Submits form data to login request.
   */
  submit() {

    this.submitting = true;
    this.analytics.track('Login Submitted');

    this.LoginService.login(this.email, this.password)
      .then(response => this.onLoginSuccess(response))
      .catch(errors => {
        this.onLoginError(errors);
        this.errors = errors;
      })
      .finally(() => this.onLoginFinally());

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomLoginFormController#onLoginSuccess
   *  @methodOf Boom.Controllers:BoomLoginFormController
   *  @param {array} user User
   *  @description
   *    Called on success of login request.
   */
  onLoginSuccess(response) {
    this.setUserAuthToken();
    this.analytics.identify();
    this.setEnvCookie();
    this.onSuccess(response);
  }

  setUserAuthToken() {
    if (this.User.user && this.User.user.token) {
      setPlatform3AuthToken(this.User.user.token);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomLoginFormController#setEnvCookie
   *  @methodOf Boom.Controllers:BoomLoginFormController
   *  @description
   *    Set environment cookie.
   */
  setEnvCookie(userResponse) {
    this.StorageService.set('environment', this.ENV);
    this.$rootScope.$$childHead.rm.changeLanguage(this.User.profile.language);
    this.AgreementModalService.clearAnonCookie();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomLoginFormController#togglePasswordVisibility
   *  @methodOf Boom.Controllers:BoomLoginFormController
   *  @description
   *    Toggle to show password as text instead of password.
   */
  togglePasswordVisibility() {
    this.passwordVisibility = !this.passwordVisibility;
  }

  /**
    *  @ngdoc method
    *  @name Boom.Controllers:BoomLoginFormController#getSunsetText
    *  @methodOf Boom.Controllers:BoomLoginFormController
    *  @description
    *    Toggle to show password as text instead of password.
    */
  getSunsetText() {
    const isEnglish = this.UrlLanguage.activeLanguage === 'en';

    const premiumClaim = this.ClaimsService.getClaim('premium');

    if (!premiumClaim || !premiumClaim._rawProperties || !premiumClaim._rawProperties.nightfall_signin_text) {
      return;
    }

    return isEnglish ?
      premiumClaim._rawProperties.nightfall_signin_text :
      premiumClaim._rawProperties.nightfall_signin_text_es;
  }


  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomLoginFormController#showSubscribeMessage
  *  @methodOf Boom.Controllers:BoomLoginFormController
  *  @description
  *    Ensure that we can test the nightfall claim values before showing the subscribe message
  */

  showSubscribeMessage() {
    const premiumClaim = this.ClaimsService.getClaim('premium');

    if (!premiumClaim || !premiumClaim._rawProperties) {
      return false;
    }

    return !premiumClaim._rawProperties.nightfall_signin_text;
  }
}

export default BoomLoginFormController;
