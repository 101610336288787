import AccountViewController from './boom-account-view-controller';

function AccountState($stateProvider) {
  $stateProvider.state('account', {
    parent: 'language',
    abstract: true,
    url: '/account',
    controller: AccountViewController,
    controllerAs: 'vm',
    templateUrl: 'views/account/account-view.html'
  });
}

AccountState.$inject = ['$stateProvider'];

export default AccountState;
