import MobileViewController from 'views/mobile/mobile-view-controller';

function MobileState($stateProvider) {

  $stateProvider.state('mobile', {
    parent: 'language',
    url: '/mobile',
    templateUrl: 'views/mobile/mobile-view.html',
    controller: MobileViewController,
    controllerAs: 'vm',
    ignoreMobileWall: true
  });

}

MobileState.$inject = ['$stateProvider'];

export default MobileState;
