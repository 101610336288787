import WelcomeViewController from 'views/welcome/welcome-view-controller';
/**
   *  @ngdoc controller
   *  @name Boom.Controllers:WelcomeView
   *  @module Boom
   *  @requires platformjs.user
   *  @requires $state
   *  @requires platformjs.premiumProfile
   *  @requires platformjs.subscriptionsV2
   *  @requires moment
   *  @description
   *    Responsible fetching the profile user data after successful signup flow.
   */

class BoomWelcomeViewController extends WelcomeViewController {
  /* istanbul ignore next */
  static get $inject() {
    return [
      'platformjs.user',
      '$state',
      'platformjs.premiumProfile',
      'platformjs.subscriptionsV2',
      'moment',
      'platform',
      '$location'
    ];
  }
  /* istanbul ignore next */
  constructor(
    UserService,
    $state,
    PremiumProfileService,
    SubscriptionsServiceV2,
    moment,
    platform,
    $location) {
    super($state);
    this.PremiumProfileService = PremiumProfileService;
    this.SubscriptionsServiceV2 = SubscriptionsServiceV2;
    this.moment = moment;
    this.useMobileLogoLink = true;
    this.UserService = UserService;
    this.userEmail = '';
    this.loading = true;
    this.recentSignup = false;

    this.$location = $location;
    this.platform = platform;
    this.path = `${this.$location.protocol()}://${this.platform.watchDomain.url}`;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#setPremiumProfile
   *  @methodOf Boom.Controllers:WelcomeView
   *  @param {object} profile The user's premium profile
   *  @description
   *    Sets user email, loads premium Profile and Subscriptions
   */
  $onInit() {
    this.userEmail = this.UserService.profile.email;
    this.loadPremiumProfile();
    this.loadSubscriptions();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#setPremiumProfile
   *  @methodOf Boom.Controllers:WelcomeView
   *  @param {object} profile The user's premium profile
   *  @description
   *    Exposes the user's premium profile and calls to apply any promo code if the user has one
   */
  loadPremiumProfile() {
    this.PremiumProfileService.get()
      .then(this.setPremiumProfile.bind(this))
      .catch(error => this.showFailure(error))
      .finally(() => this.loading = false);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#setPremiumProfile
   *  @methodOf Boom.Controllers:WelcomeView
   *  @param {object} profile The user's premium profile
   *  @description
   *    Exposes the user's premium profile and calls to apply any promo code if the user has one
   */
  setPremiumProfile(profile) {
    this.userPremiumProfile = profile;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#showFailure
   *  @methodOf Boom.Controllers:WelcomeView
   *  @param {object} error Errors returned from API
   *  @description
   *    Sets errors to premiumProfile errors
   */
  showFailure(error) {
    this.errors = error;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#loadSubscriptions
   *  @methodOf Boom.Controllers:WelcomeView
   *  @description
   *    Loads user subscriptions
   */
  loadSubscriptions() {
    this.SubscriptionsServiceV2.list()
      .then(this.showActiveSubscription.bind(this))
      .catch(this.showFailure.bind(this));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#showActiveSubscription
   *  @methodOf Boom.Controllers:WelcomeView
   *  @param {array} allSubscriptions All of the users subscriptions
   *  @description
   *    Called with an array of all the user's subscriptions
   *    Searches for the active subscription and stores it on the instance
   *
   */
  showActiveSubscription(allSubscriptions) {
    allSubscriptions.forEach(subscription => {
      if (subscription.active) {
        const signupDate = this.moment(subscription.premium.signupDate);
        if (Math.abs(signupDate.diff(this.moment(), 'days')) < 2) {
          this.recentSignup = true;
        }
        this.term = subscription.premium.billingPeriod === 'P1Y' ? 'year' : 'month';
        this.activeSubscription = subscription;
      }
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WelcomeView#$getWatchUrl
   *  @methodOf Boom.Controllers:WelcomeView
   *  @description
   *    Returns watch url
   */
  getWatchUrl() {
    return this.path;
  }

  showProductPrice() {
    const productsToHidePrices = [
      'july2020monthly',
      'SpringSale2021Monthly',
      'Winback2022Monthly',
      'SpringSale2022Monthly'
    ];

    return this.recentSignup &&
      !productsToHidePrices.includes(this.activeSubscription.merchantPlanId);
  }
}

export default BoomWelcomeViewController;
