import BoomUpsellController from './boom-upsell-controller';

const BoomUpsellComponent = {
  bindings: {
    series: '<',
    episode: '<'
  },
  controllerAs: 'vm',
  controller: BoomUpsellController,
  templateUrl: 'components/boom-upsell/boom-upsell.html'
};
export default BoomUpsellComponent;
