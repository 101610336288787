import ConfigLanguagesFunction from 'config-languages.module';

/* Import Seed Views */

import RedirectState from 'views/redirect/boom-redirect-state';
import AccountState from 'views/account/boom-account-state';
import CancelState from 'views/cancel/boom-cancel-state';
import ChangePasswordState from 'views/change-password/change-password-state';
import CancelConfirmationState from 'views/cancel-confirmation/boom-cancel-confirmation-state';
import DeviceRegistrationState from 'views/device-registration/device-registration-state';
import ForgotPasswordState from 'views/forgot-password/boom-forgot-password-state';
import HomeState from 'views/home/boom-home-state';
import LanguageState from 'views/language/language-state';
import LoginState from 'views/login/login-state';
import ProfileState from 'views/profile/boom-profile-state';
import RegisterState from 'views/register/boom-register-state';
import SignupState from 'views/signup/signup-state';
import SubscribeState from 'views/subscribe/subscribe-state';
import WelcomeState from 'views/welcome/boom-welcome-state';

import AppApplicationController from 'controllers/app-application-controller';

/* Guide states */
import GuideState from 'views/guide/guide-state';
import ComponentsState from 'views/guide/components/components-state';
import GeneralState from 'views/guide/general/general-state';
import OverviewState from 'views/guide/overview/overview-state';

/* Import Custom Views */
import HelpState from 'views/help/help-state';
import HelpRedirectState from 'views/help/help-redirect-state';
import FeedbackState from 'views/feedback/feedback-state';
import TermsState from 'views/terms/terms-state';
import PrivacyState from 'views/privacy/privacy-state';
import PrivacyOldState from 'views/privacy-old/privacy-old-state';
import UpsellState from 'views/upsell/upsell-state';
import SaleState from 'views/sale/sale-state';
import PromocodeState from 'views/promocode/boom-promocode-state';
import DeviceRegistrationRedirectState from 'views/device-registration/device-registration-redirect-state';
import AccountEmailState from 'views/profile/update-email-state';
import AccountPasswordState from 'views/profile/update-password-state';
import HomeRedirectState from 'views/home/home-redirect-state';
import BracketState from 'views/bracket/bracket-state';
import MobileState from 'views/mobile/mobile-state';
import DeviceOfflineState from 'views/device-offline/device-offline-state';
import DeleteAccountRedirectState from 'views/delete-account/delete-account-redirect-state';

import GiftPurchaseState from 'views/gift/gift-purchase-state';
import GiftState from 'views/gift/gift-state';
import GiftSuccessState from 'views/gift/gift-success-state';
import RedemptionState from 'views/redemption/boom-redemption-state';
import RedemptionWelcomeState from 'views/redemption/boom-welcome-state';
import HomeUpsellState from 'views/home-upsell/home-upsell-state';
import SuccessState from 'views/success/success-state';
import ProductsState from 'views/products/products-state';
import WelcomeBackState from 'views/welcome-back/boom-welcome-back-state';

import SunsetState from 'views/sunset/sunset-state';
import ThatsAllFolks from 'views/thatsallfolks/thatsallfolks-state';
import UpdatePaymentState from 'views/update-payment/update-payment-state';

//Import AnalyticsModule
import AnalyticsModule from 'modules/analytics/analytics';

const ViewsModule = angular.module('boom-www.views', [AnalyticsModule.name])
  .config(ConfigLanguagesFunction)

  /* Register Seed Views */

  .config(RedirectState)
  .config(AccountState)
  .config(CancelState)
  .config(ChangePasswordState)
  .config(DeviceRegistrationState)
  .config(ForgotPasswordState)
  .config(HomeState)
  .config(LanguageState)
  .config(LoginState)
  .config(ProfileState)
  .config(RegisterState)
  .config(SignupState)
  .config(SubscribeState)
  .config(WelcomeState)

  /* add the Guide states */
  .config(GuideState)
  .config(ComponentsState)
  .config(GeneralState)
  .config(OverviewState)

  /* Register Custom Views */
  .config(HelpState)
  .config(HelpRedirectState)
  .config(FeedbackState)
  .config(TermsState)
  .config(PrivacyState)
  .config(PrivacyOldState)
  .config(UpsellState)
  .config(SaleState)
  .config(PromocodeState)
  .config(DeviceRegistrationRedirectState)
  .config(AccountEmailState)
  .config(AccountPasswordState)
  .config(HomeRedirectState)
  .config(BracketState)
  .config(MobileState)
  .config(DeviceOfflineState)
  .config(WelcomeBackState)
  .config(DeleteAccountRedirectState)

  .config(GiftPurchaseState)
  .config(GiftState)
  .config(GiftSuccessState)
  .config(RedemptionState)
  .config(RedemptionWelcomeState)
  .config(HomeUpsellState)
  .config(SuccessState)
  .config(ProductsState)
  .config(CancelConfirmationState)
  .config(SunsetState)
  .config(ThatsAllFolks)
  .config(UpdatePaymentState)

  .controller('AppApplicationController', AppApplicationController);

export default ViewsModule;
