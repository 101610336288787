import SaleViewController from './sale-view-controller.module';

function SaleState($stateProvider) {

  $stateProvider.state('sale', {
    parent: 'language',
    url: '/sale',
    templateUrl: 'views/sale/sale-view.html',
    controllerAs: 'vm',
    segment: {
      name: 'Sale Page'
    },
    controller: SaleViewController,
    ignoreMobileWall: true
  });

  $stateProvider.state('springsale', {
    parent: 'language',
    url: '/springsale',
    templateUrl: 'views/sale/sale-view.html',
    controllerAs: 'vm',
    segment: {
      name: 'Spring Sale Page'
    },
    controller: SaleViewController,
    ignoreMobileWall: true
  });
}

SaleState.$inject = ['$stateProvider'];

export default SaleState;
