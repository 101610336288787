import moment from 'moment';

/**
 *  @ngdoc controller
 *  @name Gates.Controllers:AgeGateAlertController
 *  @module Boom
 *  @description
 *    Responsible for Showing the age gate error alerts
 */
class AgeGateAlertController {
  static get $inject() {
    return ['$timeout'];
  }
  constructor($timeout) {
    this.$timeout = $timeout;
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#$onInit
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @description
   *    kicks off polling to update the lock time
   */
  $onInit() {
    this.lockTimePoll();
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#$onChanges
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @description
   *    Sets off lock time on change
   */
  $onChanges() {
    this.setLockTime();
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#lockTimePoll
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @description
   *    exposes the lock timer and fires the timeout
   */
  lockTimePoll() {
    this.lockPoll = this.$timeout(this.setLockTime.bind(this), 200);
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#setLockTime
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @description
   *    exposes the calculated min and seconds left in the lock timer
   */
  setLockTime() {
    if (this.locked) {
      let mins = this.locked.diff(moment(), 'minutes');
      if (mins < 0) {
        mins = 0;
      }
      let secs = this.locked.diff(moment(), 'seconds') - 60 * mins;
      if (secs < 0) {
        secs = 0;
      }
      this.lockTimeFromNow = {
        min: mins,
        sec: secs
      };
      this.setLockText();
    }
    this.lockTimePoll();
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#setLockText
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @description
   *    exposes the user-facing amount of time left that the gate is locked
   */

  setLockText() {
    if (this.lockTimeFromNow.min > 0 || this.lockTimeFromNow.sec > 0) {
      this.lockTimeText = `${this.padTime(
        this.lockTimeFromNow.min
      )}:${this.padTime(this.lockTimeFromNow.sec)} minutes`;
    } else {
      this.lockTimeText = null;
    }
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#padTime
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @param {number} time An integer represeting minutes or seconds'
   *  @returns {string} The newly padded time
   *  @description
   *    Pads a number with leading zeros so it contains at least 2 characters
   */

  padTime(time) {
    let timeString = String(time);
    while (timeString.length < 2) {
      timeString = '0' + timeString;
    }
    return timeString;
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#canRetry
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @returns {boolean} If the user can retry submitting the form
   *  @description
   *    Detects if the user can retry submitting the form
   */

  canRetry() {
    if (!this.lockTimeFromNow) {
      return true;
    }
    const isCountingDown =
      this.lockTimeFromNow.min > 0 || this.lockTimeFromNow.sec > 0;
    return !isCountingDown;
  }
  /**
   *  @ngdoc method
   *  @name Gates.Controllers:AgeGateAlertController#$onDestroy
   *  @methodOf Gates.Controllers:AgeGateAlertController
   *  @returns {boolean} If the user can retry submitting the form
   *  @description
   *    clears the lock timeout fn if one is set
   */

  $onDestroy() {
    if (this.lockPoll) {
      this.$timeout.cancel(this.lockPoll);
    }
  }
}

export default AgeGateAlertController;
