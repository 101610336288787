let loadingImage = '/images/fallback-images/loader.png';
const originalLoader = loadingImage;
let fallbackImage = '/images/fallback-images/error.png';
const originalFallback = fallbackImage;

const FallbackConfig = {
  getLoadingImage: () => loadingImage,
  setLoadingImage: (newLoader = originalLoader) => {
    loadingImage = newLoader;
  },
  getFallbackImage: () => fallbackImage,
  setFallbackImage: (newFallback = originalFallback) => {
    fallbackImage = newFallback
  },
  showLoader: false,
  showFallback: true,
  loadCache: {}
}

const loadImage = (url, successCb, errorCb) => {
  if (url.split('?')[0]) {
    if (FallbackConfig.loadCache[url] !== true) {
      const img = new Image();
      img.onerror = errorCb;
      img.onload = () => {
        FallbackConfig.loadCache[url] = true;
        successCb();
      };
      img.src = url;
    } else {
      successCb();
    }
  }
}

export { FallbackConfig, loadImage };
