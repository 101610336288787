/**
*  @ngdoc controller
*  @name Seed.Controllers:NotificationSettings
*  @module Seed
*  @requires platformjs.user.preferences.email
*  @description
*    Responsible for getting the notification setting and updating.
*/

class NotificationSettingsController {
  static get $inject() {
    return ['platformjs.user.preferences.email'];
  }

  constructor(PreferencesEmailService) {
    this.PreferencesEmailService = PreferencesEmailService;

    this.loading = false;

    /**
     *  @ngdoc property
     *  @name settings
     *  @propertyOf Seed.Controllers:NotificationSettings
     *  @returns {object} Settings
     *  @description
     *    Settings state for notification
     */
    this.settings = null;

    this.onError = this.onError || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:NotificationSettings#$onInit
   *  @methodOf Seed.Controllers:NotificationSettings
   *  @description
   *    Makes initial request for a notification setting object to display.
   */
  $onInit() {

    this.loading = true;

    this.loadSettings()
      .then((settings) => this.showSettings(settings))
      .catch(errors => this.showErrors(errors))
      .finally(() => this.showComplete());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:NotificationSettings#loadSettings
   *  @methodOf Seed.Controllers:NotificationSettings
   *  @description
   *    Loads notification settings.
   */
  loadSettings() {
    return this.PreferencesEmailService.get();
  }


  /**
   *  @ngdoc method
   *  @name Seed.Controllers:NotificationSettings#showComplete
   *  @methodOf Seed.Controllers:NotificationSettings
   *  @description
   *     Boolean state when request is complete
   */
  showComplete() {

    this.loading = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:NotificationSettings#showErrors
   *  @methodOf Seed.Controllers:NotificationSettings
   *  @param {array} errors Errors
   *  @description
   *    Called on failure of get request
   */
  showErrors(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:NotificationSettings#showSettings
   *  @methodOf Seed.Controllers:NotificationSettings
   *  @param {object} settings User settings
   *  @description
   *    Called on the success of the initial request for the notification settings
   */
  showSettings(settings) {

    this.settings = settings;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:NotificationSettings#showSuccess
   *  @methodOf Seed.Controllers:NotificationSettings
   *  @param {array} settings Settings
   *  @description
   *     Called on success of $onInit and update request.
   */
  showSuccess(settings) {

    this.onSuccess({
      settings: settings
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:NotificationSettings#updateSettings
   *  @methodOf Seed.Controllers:NotificationSettings
   *  @param {array} value Value
   *  @description
   *    Updates notification settings
   */
  updateSettings(value) {

    var params = {
      marketing: value
    };

    this.PreferencesEmailService.save(params)
      .then(settings => this.showSuccess(settings))
      .catch(errors => this.showErrors(errors));

  }
}

export default NotificationSettingsController;
