import LoginButtonController from './login-button-controller';

const LoginButtonComponent = {
  bindings: {
    destinationState: '<',
    destinationParams: '<'
  },
  controller: LoginButtonController,
  controllerAs: 'vm',
  templateUrl: 'components/login-button/login-button.html',
  transclude: true
};

export default LoginButtonComponent;
