/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomLoginViewController
 *  @module Seed
 *  @requires angular.$state
 *  @requires detectjs
 *  @requires platformjs.user.profile
 *  @description
 *    Responsible for managing the login view.
 */
class BoomLoginViewController {
  /* istanbul ignore next */
  static get $inject() {
    return ['$location', '$state', '$window', 'detectjs', 'platformjs.user.profile', 'platform'];
  }

  constructor($location, $state, $window, detectjs, UserProfileService, platform) {
    this.$location = $location;
    this.$state = $state;
    this.$window = $window;
    this.detectjs = detectjs;
    this.UserProfileService = UserProfileService;
    this.platform = platform;

    this.watchUrl = `${this.$location.protocol()}://${this.platform.watchDomain.url}`;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Boom.Controllers:BoomLoginViewController
     *  @description Current login errors.
     */
    this.errors = [];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomLoginViewController#showLoginFailure
   *  @methodOf Boom.Controllers:BoomLoginViewController
   *  @param {array} errors An array of errors. 
   *  @description
   *    Sets {@link Boom.Controllers:LoginView#properties_errors errors} to the passed errors parameter.
   */
  showLoginFailure(errors) {
    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomLoginViewController#checkMobile
   *  @methodOf Boom.Controllers:BoomLoginViewController
   *  @params {userAgent} navigator.userAgent
   *  @description
   *    Uses detectjs to check for "Mobile" device type
   */
  checkMobile(userAgent) {
    let ua = this.detectjs.parse(userAgent);
    return ['mobile', 'tablet'].includes(ua.device.type.toLowerCase()) ||
      ['ios', 'android'].includes(ua.os.family.toLowerCase());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomLoginViewController#showLoginSuccess
   *  @methodOf Boom.Controllers:BoomLoginViewController
   *  @description
   *    Redirects to the value of `$state.params.destinationState` with the value
   *    of `$state.params.destinationParams`.
   *
   */
  showLoginSuccess() {
    this.UserProfileService.get()
      .then((user) => {

        if (this.checkMobile(navigator.userAgent)) {
          let state = (user.subscriptionInfo.premiumLevel) ? 'account.profile' : 'upsell';
          this.$state.go(state);
        } else {
          this.$window.location.replace(this.watchUrl);
        }
      })
      .catch(errors => {
        this.errors = errors;
      });

  }
}

export default BoomLoginViewController;
