import BoomUpdatePaymentFormController from './boom-update-payment-form-controller';

const BoomUpdatePaymentFormComponent = {
  bindings: {
    onError: '&',
    onFailure: '&',
    onReady: '&',
    errors: '<'
  },
  controller: BoomUpdatePaymentFormController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-update-payment-form/boom-update-payment-form.html'
};

export default BoomUpdatePaymentFormComponent;
