class SaleViewController {
  static get $inject() {
    return [
      'platformjs.storage',
      '$window',
      '$state'
    ];
  }
  /* istanbul ignore next */
  constructor(
    StorageService,
    $window,
    $state
  ) {
    this.StorageService = StorageService;
    this.$window = $window;
    this.$state = $state;
  }

  $onInit() {
    //this.$window.location.href = '/premium';
    if (this.$state.current.name === 'springsale') {
      this.titleOverride = 'Spring into Boomerang for 99¢!';
      this.StorageService.set('promotional-catalog', 'springsale2021');
    } else {
      this.StorageService.set('promotional-catalog', 'july2020winback');
    }
  }
}

export default SaleViewController;