/**
 *  @ngdoc controller
 *  @name Seed.Controllers:LoginButton
 *  @module Seed
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @description
 *    Manages button that logs in a user and preserves their origin.
 */
class LoginButtonController {
  static get $inject() {
    return ['$state', '$stateParams'];
  }

  constructor($state, $stateParams) {
    this.$state = $state;
    this.$stateParams = $stateParams;
  }

  /**
    *  @ngdoc method
    *  @name Seed.Controllers:LoginButton#getLoginDestination
    *  @methodOf Seed.Controllers:LoginButton
    *  @returns {object} destination Destination object
    *  @description
    *    Returns an object containing the state information of the desired
    *    destination post-login.
    */
  getLoginDestination() {
    return {
      destinationState: this.destinationState || this.$state.current.name,
      destinationParams: this.destinationParams || this.$stateParams
    };
  }
}

export default LoginButtonController;
