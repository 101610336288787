import AgeGateService from './services/age-gate-service';
import BabyGateService from './services/baby-gate-service';
import GateRouteInterceptorService from './services/gate-route-interceptor-service.module.js';

import AgeGateComponent from './components/age-gate/age-gate-component';
import AgeGateAlertComponent from './components/age-gate-alert/age-gate-alert-component';
import AgeGateFormComponent from './components/age-gate-form/age-gate-form-component';

import BabyGateComponent from './components/baby-gate/baby-gate-component';
import BabyGateFormComponent from './components/baby-gate-form/baby-gate-form-component';

const GateModule = angular
  .module('dramafever.gates', ['ui.router', 'ngCookies'])
  .service('gates.ageGate', AgeGateService)
  .service('gates.babyGate', BabyGateService)
  .service('gates.routeInterceptor', GateRouteInterceptorService)

  .component('ageGate', AgeGateComponent)
  .component('ageGateAlert', AgeGateAlertComponent)
  .component('ageGateForm', AgeGateFormComponent)

  .component('babyGate', BabyGateComponent)
  .component('babyGateForm', BabyGateFormComponent);

export default GateModule;
