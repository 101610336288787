/**
*  @ngdoc controller
*  @name Seed.Controllers:LoginForm
*  @module Seed
*  @requires platformjs.user.login
*  @description
*    Responsible for logging in a user.
*/
class LoginFormController {
  static get $inject() {
    return ['$analytics', 'platformjs.user.login', 'GoogleAnalyticsService'];
  }

  constructor($analytics, LoginService, GoogleAnalyticsService) {
    this.LoginService = LoginService;
    this.$analytics = $analytics;
    this.GoogleAnalyticsService = GoogleAnalyticsService;

    /**
     *  @ngdoc property
     *  @name password
     *  @propertyOf Seed.Controllers:LoginForm
     *  @returns {string} Password.
     *  @description
     *    User password input value.
     */
    this.password = '';

    /**
     *  @ngdoc property
     *  @name submitting
     *  @propertyOf Seed.Controllers:LoginForm
     *  @returns {boolean} Status.
     *  @description
     *    Current submission status.
     */
    this.submitting = false;

    /**
     *  @ngdoc property
     *  @name username
     *  @propertyOf Seed.Controllers:LoginForm
     *  @returns {string} Username.
     *  @description
     *    User username input value.
     */
    this.username = '';

    this.onError = this.onError || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:LoginForm#onLoginError
   *  @methodOf Seed.Controllers:LoginForm
   *  @param {array} errors Errors
   *  @description
   *    Called on failure of login request.
   */
  onLoginError(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:LoginForm#onLoginFinally
   *  @methodOf Seed.Controllers:LoginForm
   *  @description
   *    Called at the end of login request.
   */
  onLoginFinally() {

    this.submitting = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:LoginForm#onLoginSuccess
   *  @methodOf Seed.Controllers:LoginForm
   *  @param {array} user User
   *  @description
   *    Called on success of login request.
   */
  onLoginSuccess(response) {


    this.GoogleAnalyticsService.setUserID();

    this.$analytics.eventTrack('Login', {
      category: 'User'
    });

    this.onSuccess(response);

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:LoginForm#submit
   *  @methodOf Seed.Controllers:LoginForm
   *  @description
   *    Submits form data to login request.
   */
  submit() {

    this.submitting = true;

    this.LoginService.login(this.username, this.password)
      .then(response => this.onLoginSuccess(response))
      .catch(errors => this.onLoginError(errors))
      .finally(() => this.onLoginFinally());

  }
}

export default LoginFormController;
